import { messageAlert } from "../../../utils";
import getAxios from "../../../utils/baseApi";
import { handleError } from "../../../utils/errorHandler";
import { ROLES_SEARCH_FIELDS, COMPANY_FIELD_QUERY, PROJECT_FIELDS, PEOPLE_FIELDS, ENTITY_TYPE, MIN_PROJECTS_REQUIRED_FOR_DISPLAY, MAX_API_CALLS, PROJECT_SEARCH_FIELDS, PEOPLE_SEARCH_FIELDS, COMPANY_SEARCH_FIELDS, ROLES_FIELD_QUERY } from "../../../utils/settings";
import { fetchSearchListForAllObjectType, updateQueryResult, updateProcessId, updatelistOfSelectedObjectIds, updateProjectsForCompanyId, updateCompanyDetail, updatePeopleProjectsList, updatePeopleDetail, updateIfMoreResultAvailable, updateSelectedSelectorControlValue, updateSearchListSelection, fetchShowMoreList, updateIfMoreCompanyProjectsAvailable, updateHierarchyBack, resetIncludeExcludeIds, fetchShowMoreListOfSelectedObjectIds, updateFrameworkOptions, resetRelationalEntititySelectionCount, updateMaxApiCalls, updateSearchCount, updateSearchLoader, updateNoResult, updateArchiveSearchList, updateShowMoreProjectsForCompanyId, rolesForProject, updateInvalidRequest, updateApiCall, updateObjectData, updateProjectsFavForCompanyId, updatelistOfSelectedObjectIdsFav, updateGroupCompanyProjectsFav, updateObjectDataJA, updateCompanyDetailJA, updateSearchListFav } from "./redux/reducer";
import { MAPBOX_URL } from '../../../utils/settings'
import { rolesPostProcessing, setDataForCompanyDetails, setDataForContactDetails, setDataForProjectDetails } from "../../details/postprocessing";
import { getRecordCount, getUniqueSelectedList, removeArchivedProjects, updateSearchListForFavJA, updateSearchListForTagJA } from "../../../utils/commonFunctions";
import _, { cloneDeep } from "lodash";
import { showGroupCompaniesProject } from "../../details/apiclient";
import { updateJointProjects, updateProjectDataForRoleId, updateShowMoreOffsetLimit } from "../../details/redux/reducer";
import { updateOffsetLimit } from "./filters/redux/reducer";
import { preProcessorQuery } from "./filters/preProcessorQuery";
import { getNoOfDays } from "./filters/utils";
var axios = getAxios()

const resultList = (count, query, responseData, search, settings, type, dispatch) => {
    if (count > (query.offset + query.records)) {
        // This condition is filtering archived projects and updating limit for records based on PSP-359
        if (type === ENTITY_TYPE.PROJECT && settings && settings.preferences && settings.preferences.archiving_enabled) {
            responseData = removeArchivedProjects(query, responseData)
            query = getRecordCount(query, responseData)
        }
        if (responseData.leadsFlags.length < MIN_PROJECTS_REQUIRED_FOR_DISPLAY && search.maxApiCalls <= MAX_API_CALLS) {
            dispatch(updateMaxApiCalls(search.maxApiCalls + 1))
            updateResponseData(dispatch, query, responseData);
        } else {
            if (query.offset > 0) {
                dispatch(fetchShowMoreList(responseData));
            } else {
                dispatch(fetchSearchListForAllObjectType(responseData));
            }
            dispatch(updateIfMoreResultAvailable(true))
        }
    } else {
        // This condition is filtering archived projects and updating limit for records based on PSP-359
        if (type === ENTITY_TYPE.PROJECT && settings && settings.preferences && settings.preferences.archiving_enabled) {
            responseData = removeArchivedProjects(query, responseData)
            query = getRecordCount(query, responseData)
        }
        updateResponseData(dispatch, query, responseData);
        dispatch(updateIfMoreResultAvailable(false))
    }
}

function setObjectData(leadsFlags, type) {
    _.map(leadsFlags, (payload) => {
        if (type === 'project') {
            setDataForProjectDetails(payload);
        } else if (type === 'company') {
            setDataForCompanyDetails(payload);
        } else if (type === 'people') {
            setDataForContactDetails(payload)
        }
    });
}

function updateResponseData(dispatch, query, responseData) {
    dispatch(updateOffsetLimit({ offset: query.offset, records: query.records }));
    if (query.offset > 0) {
        dispatch(fetchShowMoreList(responseData));
    } else {
        dispatch(fetchSearchListForAllObjectType(responseData));
    }
}

export const updateSelectorControlAction = (payload, search, searchPersist, dispatch, relatedEntity) => {
    let objectType = payload.object_type === 'project' ? 'projects' : payload.object_type === "company" ? "companies" : "people";
    if (payload.sort === "R" || payload.sort === "") {
        delete payload.sort
    }
    var jsonData = JSON.stringify(payload);
    try {
        axios.put(`${objectType}`, jsonData)
            .then((resp) => {
                if (resp.status === 200 || resp.status === 201) {
                    dispatch(updateProcessId(resp.data.process_id))
                    let message = payload.favourite ? `Selected ${objectType} have been favourited` : `Selected ${objectType} have been unfavourited`
                    messageAlert(dispatch, message, 'success')
                    updateSearchListForFavJA(payload, search, dispatch)
                    dispatch(resetRelationalEntititySelectionCount())
                    if (search.selectedSelectorControlValue !== 0 && !relatedEntity) {
                        dispatch(updateSelectedSelectorControlValue(0))
                        dispatch(updateSearchListSelection(0))
                    }
                    if (search.countOfSelectedObjectIds !== 0) {
                        dispatch(updateSearchListSelection({ count: 0, isSelectionRelatedEntities: true }))
                    }
                    dispatch(resetIncludeExcludeIds([]))
                }
            })
    } catch (error) {
        handleError(error, dispatch)
    }
}

export const updateSearchSelectorControlTags = (payload, search, searchPersist, dispatch, relatedEntity) => {
    let objectType = payload.object_type === 'project' ? 'projects' : payload.object_type === "company" ? "companies" : "people";
    try {
        axios.post(`${objectType}/tags`, payload)
            .then((resp) => {
                if (resp.status === 200 || resp.status === 201) {
                    dispatch(updateProcessId(resp.data.process_id))
                    messageAlert(dispatch, `Selected ${objectType} have been tagged.`, 'success')
                    updateSearchListForTagJA(payload, search, dispatch)
                    dispatch(resetRelationalEntititySelectionCount())
                    if (search.selectedSelectorControlValue !== 0 && !relatedEntity) {
                        dispatch(updateSelectedSelectorControlValue(0))
                        dispatch(updateSearchListSelection(0))
                    }
                    if (search.countOfSelectedObjectIds !== 0) {
                        dispatch(updateSearchListSelection({ count: 0, isSelectionRelatedEntities: true }))
                    }
                    dispatch(resetIncludeExcludeIds([]))
                }
            })
    } catch (error) {
        handleError(error, dispatch)
    }
}

export const selectorControllerArchive = (payload, search, searchPersist, dispatch, settings, details, filter, relatedEntity) => {
    try {
        if (payload.sort === "R" || payload.sort === "") {
            delete payload.sort
        }
        axios.put('projects', payload)
            .then((resp) => {
                if (resp.status === 200 || resp.status === 201) {
                    dispatch(updateProcessId(resp.data.process_id))
                    let message = payload.archive ? 'Projects archived successfully' : 'Projects unarchived successfully'
                    messageAlert(dispatch, message, 'success')
                    dispatch(resetRelationalEntititySelectionCount())
                    if (search.selectedSelectorControlValue !== 0 && !relatedEntity) {
                        dispatch(updateSelectedSelectorControlValue(0))
                        dispatch(updateSearchListSelection(0))
                    }
                    if (search.countOfSelectedObjectIds !== 0) {
                        dispatch(updateSearchListSelection({ count: 0, isSelectionRelatedEntities: true }))
                    }
                    dispatch(resetIncludeExcludeIds([]))
                    !details?.isProjectSelected ? dispatch(updateOffsetLimit({ offset: 0, records: 50, type: payload.object_type })) :
                        dispatch(updateShowMoreOffsetLimit({ offset: 0, records: 50 }));

                    if (payload['identifier'] === 'joint_projects') {
                        let projects = [];
                        _.map(cloneDeep(settings.jointProjects["projects"]), (record) => {
                            record.archive = record.is_selected ? payload.archive : record.archive
                            record.is_selected = false
                            projects.push(record)
                        })
                        dispatch(updateJointProjects({ projects: projects, aggregation: settings.jointProjects.aggregation }))

                    } else if (payload['identifier'] === 'company_projects_role') {
                        let projects = [];
                        _.map(cloneDeep(settings.projectDataForRoleId), (record) => {
                            record.archive = record.is_selected ? payload.archive : record.archive
                            record.is_selected = false
                            projects.push(record)
                        })
                        dispatch(updateProjectDataForRoleId({ "aggregation": { "project_count": settings?.projectDataForRoleIdCount }, "projects": projects }))
                    } else {
                        dispatch(updateHierarchyBack(false))
                        if (!details?.isProjectSelected) {
                            let newFilterPayload = _.cloneDeep(filter.filtersState)
                            newFilterPayload[payload.object_type].offset = 0
                            newFilterPayload[payload.object_type].records = 50
                            var formValues = preProcessorQuery('', newFilterPayload, payload.object_type, search, searchPersist)
                            fetchSearchListCount(formValues, dispatch, search, settings, searchPersist.query);
                        } else {
                            showGroupCompaniesProject(getUniqueSelectedList(details?.groupCompanies), dispatch, details)
                        }


                    }
                }
            })
    } catch (error) {
        handleError(error, dispatch)
    }
};

export function fetchLocation(country, queryValue, limit, mapboxToken, dispatch) {
    let path = MAPBOX_URL + queryValue + '.json?access_token=' + mapboxToken
    if (country) {
        path += '&country=' + country + '&limit=' + limit
    }
    try {
        axios.get(path)
            .then(function (response) {
                var responseData = response.data ? { features: response.data.features } : { features: [] }
                dispatch(updateQueryResult(responseData))
            })
    }
    catch (error) {
        handleError(error, dispatch);
    }
}

export function fetchRolesForCompanyId(id, search, searchPersist, dispatch) {
    let query = {}
    let offset = search.offsetLimitValue.offset
    if (search.removeFilterToggle) {
        query.query = _.cloneDeep(JSON.parse(searchPersist?.query))
        query.query['company_id'] = { "operator": "=", "value": id }
    } else {
        query.query = {}
        query.query['company_id'] = { "operator": "=", "value": id }
    }
    let url = `projects?query=${JSON.stringify(query.query)}&fields=${ROLES_SEARCH_FIELDS}&offset=${offset}&limit=${search.offsetLimitValue.records}&event_type=search`
    if (query.sort && query.sort != "R") {
        url = url + "&sort=" + query.sort
    }
    if (query.brand_search) {
        url += `&brand_search=${query.brand_search}`
    }
    if (query.project_search) {
        url += `&project_search=${query.project_search}`
    }
    try {
        axios.get(url)
            .then(async (response) => {
                if (response.status === 200) {
                    if (response.data.projects && response.data.projects.length > 0) {
                        if (response.data && response.data.aggregation.project_count > (parseInt(offset) + parseInt(query.records))) {
                            dispatch(updateIfMoreCompanyProjectsAvailable(true))
                        } else {
                            dispatch(updateIfMoreCompanyProjectsAvailable(false))
                        }
                        _.map(response.data.projects, project => {
                            setDataForProjectDetails(project)
                        })
                        if (offset > 0) {
                            dispatch(updateShowMoreProjectsForCompanyId(response.data))
                        } else {
                            dispatch(updateProjectsForCompanyId(response.data))
                        }
                    } else {
                        dispatch(updateProjectsForCompanyId([]))
                    }
                }
            })
    } catch (error) {
        handleError(error, dispatch)
    }
}

export const fetchCompanyDetails = (id, dispatch) => {
    axios.get('/companies/' + id + '?fields=' + COMPANY_FIELD_QUERY).then((response) => {
        if (response.status === 200) {
            let company = _.clone(response.data.companies)
            company = setDataForCompanyDetails(company)
            dispatch(updateCompanyDetailJA(company))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchProjectsForPeople = (id, search, dispatch) => {
    axios.get(`projects?query={"person_id":{"operator": "=", "value": ${id}}}&fields=${PROJECT_FIELDS} &offset=${search.offsetLimitValue && search.offsetLimitValue.offset}&limit=${search.offsetLimitValue && search.offsetLimitValue.records}&sort=-project_last_published`).then(async (response) => {
        if (response.status === 200) {
            dispatch(updatePeopleProjectsList(response.data.projects))
        }

    }).catch((error) => {
        handleError(error, dispatch)
    })

}

export const fetchPeopleDetails = (id, dispatch) => {
    axios.get('/people/' + id + '?fields=' + PEOPLE_FIELDS).then((response) => {
        if (response.status === 200) {
            dispatch(updatePeopleDetail(response.data.people))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })

}

export const fetchFrameworkSuggetions = (payload, dispatch) => {
    let url = 'lookups?lookups=framework&description=' + payload
    axios.get(url).then((response) => {
        if (response.status === 200) {
            let newOptions = response.data && response.data.lookups && response.data.lookups.framework ? response.data.lookups.framework.filter(lookup => payload.options && payload.options.length > 0 ? payload.options.find(opt => opt.id == lookup.id) ? false : true : true) : []
            dispatch(updateFrameworkOptions(newOptions))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const fetchSearchCount = (filterData, dispatch, searchPersist) => {
    let objectType = filterData.display === 'project' ? 'projects' : filterData.display === 'company' ? 'companies' : 'people'
    let jsonData = JSON.stringify(filterData.query)
    let aggregate_function = ['project', 'company'].includes(filterData.display) ? 'projects_count,companies_count' : 'people_count'
    let aggregate_field = ['project', 'company'].includes(filterData.display) ? 'project_id,company_id' : 'person_id'
    let url = `${objectType}?query=${jsonData}&aggregate_function=${aggregate_function}&aggregate_field=${aggregate_field}&limit=${filterData?.records}&offset=${filterData?.offset}`
    if (filterData.brand_search) {
        url += `&brand_search=${filterData.brand_search}`
    }
    if (filterData.project_search) {
        url += `&project_search=${filterData.project_search}`
    }
    if (filterData?.query && Object.keys(filterData.query).length > 0) {
        axios.get(url).then((response) => {
            if (response.status === 200) {
                dispatch(updateInvalidRequest(false))
                dispatch(updateSearchCount(response?.data?.aggregation))
            } else {
                dispatch(updateInvalidRequest(false))
                if (searchPersist && searchPersist.appliedContext !== "people") {
                    dispatch(updateSearchCount({ projects_count: 0, companies_count: 0 }))
                } else {
                    dispatch(updateSearchCount({ people_count: 0 }))
                }
            }
        }).catch((err) => {
            if (err.response?.status === 422) {
                if (searchPersist && searchPersist.appliedContext !== "people") {
                    dispatch(updateSearchCount({ projects_count: 0, companies_count: 0 }))
                } else {
                    dispatch(updateSearchCount({ people_count: 0 }))
                }
                dispatch(updateInvalidRequest(true))
            } else {
                handleError(err)
            }
        })
    }
}

export const fetchSearchListCount = (query, dispatch, search, settings, jsonData) => {
    let objectType = query.display === 'project' ? 'projects' : query.display === 'company' ? 'companies' : 'people'
    let type = query.display
    let searchFileds = type === "project" ? PROJECT_SEARCH_FIELDS : type === "company" ? COMPANY_SEARCH_FIELDS : PEOPLE_SEARCH_FIELDS;
    let url = `${objectType}?query=${jsonData}&fields=${searchFileds}&offset=${query.offset}&limit=${query.records}&event_type=search`
    if (query.sort && query.sort !== '') {
        url += `&sort=${query.sort}`
    }
    if (query.brand_search) {
        url += `&brand_search=${query.brand_search}`
    }
    if (query.project_search) {
        url += `&project_search=${query.project_search}`
    }
    if (search.maxApiCalls <= MAX_API_CALLS && Object.keys(query.query).length > 0) {

        axios.get(url).then(async (resp) => {
            if (resp.status === 200) {
                await dispatch(updateArchiveSearchList(false))
                await dispatch(updateInvalidRequest(false))
                if (search.showSelectedCompanies || search.showSelectedProjects) {
                    var count = type === 'project' ? resp.data.aggregation.project_count : type === 'company' ? resp.data.aggregation.company_count : resp.data.aggregation.people_count
                    let leadsFlags = type === 'project' ? resp.data.projects : resp.data.companies
                    setObjectData(leadsFlags, type)
                    dispatch(updateApiCall(true))
                    if (query.offset > 0) {
                        dispatch(fetchShowMoreListOfSelectedObjectIds({ leadsFlags: leadsFlags, count: count, filterType: type }))
                    }
                    else {
                        dispatch(updatelistOfSelectedObjectIds({ leadsFlags: leadsFlags, count: count, filterType: type }));
                    }
                }
                else {
                    if (query.offset === 0)
                        await dispatch(fetchSearchListForAllObjectType({ leadsFlags: [], count: 0, filterType: type }))
                    await dispatch(updateHierarchyBack(false));
                    await dispatch(updateMaxApiCalls(0))
                    var leadsFlags = type === 'project' ? resp.data.projects : type === 'company' ? resp.data.companies : resp.data.people
                    setObjectData(leadsFlags, type);
                    var count = type === 'project' ? resp.data.aggregation.project_count : type === 'company' ? resp.data.aggregation.company_count : resp.data.aggregation.people_count
                    var responseData = resp.data ? { leadsFlags: leadsFlags, count: count, filterType: type }
                        : { leadsFlags: [], count: 0, filterType: type }
                    if (resp.data && leadsFlags?.length > 0) {
                        resultList(count, query, responseData, search, settings, type, dispatch)
                        dispatch(updateSearchLoader(false))
                        dispatch(updateNoResult(false))
                        dispatch(updateApiCall(true))
                    } else {
                        updateResponseData(dispatch, query, responseData);
                        dispatch(updateIfMoreResultAvailable(false))
                        dispatch(updateApiCall(true))
                    }
                }
            } else if (resp.status === 204) {
                dispatch(fetchSearchListForAllObjectType({ leadsFlags: [], count: 0, filterType: type }))
                dispatch(updateIfMoreResultAvailable(false))
                dispatch(updateSearchLoader(false))
                dispatch(updateNoResult(true))
                dispatch(updateInvalidRequest(false))
                dispatch(updateApiCall(true))
            }
        }).catch((err) => {
            if (err.response?.status === 422) {
                dispatch(fetchSearchListForAllObjectType({ leadsFlags: [], count: 0, filterType: type }))
                dispatch(updateIfMoreResultAvailable(false))
                dispatch(updateSearchLoader(false))
                dispatch(updateNoResult(true))
                dispatch(updateInvalidRequest(true))
                dispatch(updateApiCall(true))
            } else {
                handleError(err)
                dispatch(updateApiCall(true))
            }
        })
    } else {
        dispatch(fetchSearchListForAllObjectType({ leadsFlags: [], count: 0, filterType: type }))
        dispatch(updateSearchLoader(false))
        dispatch(updateNoResult(false))
        dispatch(updateMaxApiCalls(0))
        dispatch(updateArchiveSearchList(true))
        dispatch(updateApiCall(true))
    }
}

export const addDisabledFalse = (obj) => {
    if (typeof obj === 'object') {
        if (obj.hasOwnProperty('subscribed')) {
            obj.disabled = !obj.subscribed;
            obj.className = obj.subscribed ? "" : 'hide'
        }
        for (const key in obj) {
            addDisabledFalse(obj[key]);
        }
    }
}

export const addDisabledToValues = (values) => {
    return values.map((item) => {
        addDisabledFalse(item);
        if (item.children) {
            item.children = addDisabledToValues(item.children);
        }
        return item;
    });
}

export const updateStagesProfileData = (item) => {
    item.label = item.value
    if (item.value === "Planning Stages") {
        item.value = 100
    } else if (item.value === "Contract Stages") {
        item.value = 200
    } else if (item.value === "Stopped") {
        item.value = 300
    }
    return item;
}

export function sendIntercomRequest(params, dispatch) {
    axios.post('intercom/request', JSON.stringify(params)).then((response) => {
        if (response.status === 200) {
            let payload1 = {
                "filterName": params['filterType'],
                "fromIntercom": true,
                "intercomMessageId": response.data['id'],

            }
            logEventForRequestData(payload1, dispatch)
        }
    })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export function logEventForRequestData(params, dispatch) {
    let payload = params.fromIntercom ?
        {
            "filterName": params['filterName'],
            "intercomMessageId": params['intercomMessageId'],
        } : params
    axios.post('log/request', JSON.stringify(payload))
        .then((response) => {
            if (params.fromIntercom) {
                if (response.status === 200) {
                    let message;
                    message = "Request sent. Your Customer Success Manager will be in touch shortly"
                    messageAlert(dispatch, message, 'success')
                } else {
                    let errorMessage;
                    errorMessage = "Error sending request"
                    messageAlert(dispatch, errorMessage, 'error')
                }
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export const fetchRolesForProjectsJA = (id, dispatch) => {
    let url = `/projects/${id}/roles?fields=${ROLES_FIELD_QUERY}`
    axios.get(url).then((response) => {
        if (response.status === 200) {
            let roleGroups = { roleGroups: [] }
            roleGroups = rolesPostProcessing(roleGroups, response);
            dispatch(rolesForProject(roleGroups))
        } else if (response.status === 204) {
            dispatch(rolesForProject([]))
        }

    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const updateSearchListsForJA = (entity, state, dispatch) => {
    if (entity.objectType === 'project' && state.projectRowData) {
        let clonedObj = { ...state.projectRowData };
        clonedObj = { ...clonedObj, favourite: entity.favourite };
        dispatch(updateObjectDataJA(clonedObj));
        let clonedObjForList = {
            ...state, searchList: state.searchList.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };
        dispatch(updateSearchListFav(clonedObjForList.searchList));
    } else if (entity.objectType === 'company' && state.projectRowData) {
        fetchRolesForProjectsJA(state.projectRowData.project_id, dispatch);
    } else if (entity.objectType === 'company' && state.companyDetails) {
        let clonedObj = { ...state.companyDetails };
        clonedObj = { ...clonedObj, favourite: entity.favourite };
        dispatch(updateCompanyDetailJA(clonedObj));
        let clonedObjForList = {
            ...state, searchList: state.searchList.map(record => record.company_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };
        dispatch(updateSearchListFav(clonedObjForList.searchList));
    } else if (entity.objectType === 'project' && state.projectsForCompanyId && state.projectsForCompanyId.length) {
        let clonedObjForProject = {
            ...state, projectsForCompanyId: state.projectsForCompanyId.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };
        dispatch(updateProjectsFavForCompanyId(clonedObjForProject.projectsForCompanyId));
    } else if (entity.objectType === 'project' && state.peopleProjectsList && state.peopleProjectsList.length) {
        let clonedObj = {
            ...state, peopleProjectsList: state.peopleProjectsList.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };
        dispatch(updatePeopleProjectsList(clonedObj.peopleProjectsList));
    } else if (state.listOfSelectedObjectIds && state.listOfSelectedObjectIds.length) {
        let clonedObjForList = entity.objectType === 'project' ? {
            ...state, listOfSelectedObjectIds: state.listOfSelectedObjectIds.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        } : {
            ...state, listOfSelectedObjectIds: state.listOfSelectedObjectIds.map(record => record.company_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };
        dispatch(updatelistOfSelectedObjectIdsFav(clonedObjForList.listOfSelectedObjectIds));
    } else if (state.listOfSelectedObjectIds && state.listOfSelectedObjectIds.length) {
        let clonedObjForList = entity.objectType === 'project' ? {
            ...state, listOfSelectedObjectIds: state.listOfSelectedObjectIds.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        } : {
            ...state, listOfSelectedObjectIds: state.listOfSelectedObjectIds.map(record => record.company_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };
        dispatch(updatelistOfSelectedObjectIdsFav(clonedObjForList.listOfSelectedObjectIds));
    } else if (state?.groupCompanyProjects?.length) {
        let clonedObjForList = {
            ...state, groupCompanyProjects: state.groupCompanyProjects.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        }
        dispatch(updateGroupCompanyProjectsFav(clonedObjForList.groupCompanyProjects));
    } else {
        let clonedObjForList = entity.objectType === 'project' ? {
            ...state, searchList: state.searchList.map(record => record.project_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        } : {
            ...state, searchList: state.searchList.map(record => record.company_id === entity.objectId ?
                { ...record, favourite: entity.favourite } : record)
        };
        dispatch(updateSearchListFav(clonedObjForList.searchList));
    }
}