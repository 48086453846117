import { createSlice } from "@reduxjs/toolkit";
import { getIncludeExcludeIds } from "../../../utils/commonFunctions";
import { DEFAULT_LIMIT } from "../../../utils/settings";
import { project_stages } from "../utils";

const initialState = {
    searchList: [],
    appliedCriteria: {
        records: DEFAULT_LIMIT,
        display: 'project',
        offset: 0,
        sort: 'L',
        latitude: 51.50722,
        longitude: -0.1275,
        query: { project_stages: { operator: "!=", value: JSON.stringify(project_stages) } }
    },
    defaultCriteria: {
        records: DEFAULT_LIMIT,
        display: 'project',
        offset: 0,
        sort: 'L',
        query: { project_stages: { operator: "!=", value: JSON.stringify(project_stages) } }
    },
    savedSearch: {
        project: {},
        company: {},
        people: {}
    },
    selectedSelectorControlValue: 0,
    resetSavedSearch: false,
    count: -1,
    resetSavedSearch: false,
    searchLocationQueryResults: [],
    offsetLimitValue: {
        offset: 0,
        records: 50
    },
    listOfSelectedObjectIds: [],
    dropdownSelectedObjectType: false,
    showSelectedCompanies: false,
    searchTextInformation: {
        project: [],
        people: [],
        company: []
    },
    locationSearchObject: {
        project: {},
        company: {}
    },
    companyLocationSearchObject: {
        project: {},
        company: {}
    },
    showSelectedProjects: false,
    showDetailPeople: false,
    showProjectHierarchy: false,
    showCompanyHierarchy: false,
    hierarchyBack: false,
    isShowMoreActive: false,
    selectedLeadsFlag: [],
    removeFilterToggle: true,
    updateToggle: false,
    updatedCheckbox: false,
    newCheckbox: false,
    updateStartConstToggle: false,
    updateEndConstToggle: false,
    buildPhaseToggle: false,
    buildPhase: [],
    includeCompletedProjects: false,
    pdc_supplierCode: [],
    totalFloorAreaFrom: '',
    totalFloorAreaTo: '',
    groundFloorAreaFrom: '',
    groundFloorAreaTo: '',
    aboveGroundStoreysFrom: '',
    aboveGroundStoreysTo: '',
    siteAreaFrom: '',
    siteAreaTo: '',
    belowGroundStoreysFrom: '',
    belowGroundStoreysTo: '',
    noOfUnitsFrom: '',
    noOfUnitsTo: '',
    parkingSpacesFrom: '',
    parkingSpacesTo: '',
    countOfSelectedObjectIds: 0,
    maxApiCalls: 0,
    additionalQuery: {},
    isSearchLoader: true,
    includeLength: 0,
    excludeLength: 0,
    exactSearchTextInformation: {},
    noResult: false,
    brands: [],
    includeCompletedProj: false,
    projectHierarchicalRoles: [],
    clusterList: { isShowMoreActive: true, leadsFlags: [], temp: {} },
    backButtonCluster: { back: false, status: false, relationBack: false },
    backClusterList: [],
    clusterClickCount: 0,
    navigationBack: '',
    isShowMoreActiveCompanyProjects: false,
    idsForShowRelatedEntities: [],
    isCountResponseUpdated: false,
    includeIds: [],
    excludeIds: [],
    selectedCount: 0,
    isApiCallFinish:false,
    isShowProjectsOrCompanySelected:false
}

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        fetchSearchListForAllObjectType: (state, action) => {
            if (!Array.isArray(action.payload)) {
                if (action.payload.filterType === "project") {
                    state.searchList = action.payload.leadsFlags
                    state.searchListCount = action.payload && action.payload.count;
                    return state;
                }
                else if (action.payload.filterType === "company") {
                    state.searchList = action.payload.leadsFlags
                    state.searchListCount = action.payload && action.payload.count;
                    return state
                }
                else {
                    state.searchList = action.payload.leadsFlags
                    state.searchListCount = action.payload && action.payload.count;
                    return state
                }
            }
            else {
                state.searchList = []
                return state
            }
        },
        fetchShowMoreList: (state, action) => {
            if (!Array.isArray(action.payload)) {
                let leadsFlags = state.searchList.concat(action.payload.leadsFlags);
                if (state.selectedSelectorControlValue && state.searchList.length < state.selectedSelectorControlValue) {
                    leadsFlags = leadsFlags.map((item, index) => index < state.selectedSelectorControlValue ? { ...item, is_selected: true } : item)
                }
                if (action.payload.filterType === "project") {
                    state.searchList = leadsFlags
                    state.searchListCount = action.payload && action.payload.count;
                    return state;
                }
                else if (action.payload.filterType === "company") {
                    state.searchList = leadsFlags
                    state.searchListCount = action.payload && action.payload.count;
                    return state
                }
                else {
                    state.searchList = leadsFlags
                    state.searchListCount = action.payload && action.payload.count;
                    return state
                }
            }
            else {
                state.searchList = []
                return state
            }
        },
        updateSelectedSelectorControlValue: (state, action) => {
            let currentCountSelectorControlValue = state.selectedSelectorControlValue;
            let currentCountSelectedObjectIds = state.countOfSelectedObjectIds
            if ((action.payload).toString() == "") {
                action.payload = state.searchList.length
            }

            if (action.payload.isSelectionRelatedEntities) {
                state.countOfSelectedObjectIds = action.payload.count
                state.selectedSelectorControlValue = currentCountSelectorControlValue
            }
            else {
                state.countOfSelectedObjectIds = currentCountSelectedObjectIds
                state.selectedSelectorControlValue = action.payload.toString() != "" ? action.payload.count : state.count
            }
            state.selectedSelectorControlValue = state.selectedSelectorControlValue != undefined ? state.selectedSelectorControlValue : 0
            return state
        },

        updateProcessId: (state, action) => {
            state.processId = action.payload
            return state
        },
        updateQueryResult: (state, action) => {
            state.searchLocationQueryResults = action.payload;
            return state;
        },
        resetQueryResult: (state, action) => {
            state.searchLocationQueryResults = action.payload
            return state;
        },
        updateOffsetLimitValue: (state, action) => {
            state.offsetLimitValue = action.payload
            return state
        },
        updateSearchListSelection: (state, action) => {
            let selectionCount = action.payload.isSelectionRelatedEntities ? action.payload.count : action.payload;
            let updatedLeadsFlags = []
            let leadsFlags = action.payload.isSelectionRelatedEntities ? (state.listOfSelectedObjectIds ?
                state.listOfSelectedObjectIds : state.groupCompanyProjects) : state.searchList
            if (selectionCount > 0) {
                leadsFlags.map(function (list, index) {
                    if (index <= (selectionCount - 1)) {
                        list['is_selected'] = true
                        updatedLeadsFlags.push(list)
                        state.selectedLeadsFlag.push(list)
                    } else {
                        list['is_selected'] = false
                        updatedLeadsFlags.push(list)
                        state.selectedLeadsFlag.push(list)
                    }
                })
            }
            else {
                leadsFlags.map(function (list) {
                    list['is_selected'] = false
                    updatedLeadsFlags.push(list)
                    state.selectedLeadsFlag.push(list)
                })
            }
            if (action.payload.isSelectionRelatedEntities) {
                state.countOfSelectedObjectIds = selectionCount
                state.selectedCount = selectionCount
            } else {
                state.selectedSelectorControlValue = selectionCount
                state.selectedCount = selectionCount
            }
            return state
        },
        updatelistOfSelectedObjectIds: (state, action) => {
            if (action.payload.filterType === "project") {
                state.listOfSelectedObjectIds = action.payload.leadsFlags;
                state.listOfSelectedObjectIdsCount = action.payload.count;
                return state;
            }
            else if (action.payload.filterType === "company") {
                state.listOfSelectedObjectIds = action.payload.leadsFlags;
                state.listOfSelectedObjectIdsCount = action.payload.count
                return state
            }
            else {
                state.listOfSelectedObjectIds = action.payload.leadsFlags;
                state.listOfSelectedObjectIdsCount = action.payload.count
                return state
            }
        },
        fetchShowMoreListOfSelectedObjectIds: (state, action) => {
            if (action.payload.filterType === "project") {
                let updatedLeadsFlags = []
                let selectionCount = state.countOfSelectedObjectIds
                let leadsFlags = state.listOfSelectedObjectIds && state.listOfSelectedObjectIds.concat(action.payload.leadsFlags)
                if (selectionCount > 0) {
                    leadsFlags.map(function (list, index) {
                        if (index <= (selectionCount - 1)) {
                            list['is_selected'] = true
                            updatedLeadsFlags.push(list)
                            state.selectedLeadsFlag.push(list)
                        } else {
                            list['is_selected'] = false
                            updatedLeadsFlags.push(list)
                            state.selectedLeadsFlag.push(list)
                        }
                    })
                }
                state.listOfSelectedObjectIds = updatedLeadsFlags;
                state.listOfSelectedObjectIdsCount = action.payload.count;
                return state;
            }
            else if (action.payload.filterType === "company") {
                let updatedLeadsFlags = []
                let selectionCount = state.countOfSelectedObjectIds
                let leadsFlags = state.listOfSelectedObjectIds && state.listOfSelectedObjectIds.concat(action.payload.leadsFlags)
                if (selectionCount > 0) {
                    leadsFlags.map(function (list, index) {
                        if (index <= (selectionCount - 1)) {
                            list['is_selected'] = true
                            updatedLeadsFlags.push(list)
                            state.selectedLeadsFlag.push(list)
                        } else {
                            list['is_selected'] = false
                            updatedLeadsFlags.push(list)
                            state.selectedLeadsFlag.push(list)
                        }
                    })
                }
                state.listOfSelectedObjectIds = updatedLeadsFlags
                state.listOfSelectedObjectIdsCount = action.payload.count;
                return state
            }
            else {
                let updatedLeadsFlags = []
                let selectionCount = state.countOfSelectedObjectIds
                let leadsFlags = state.listOfSelectedObjectIds && state.listOfSelectedObjectIds.concat(action.payload.leadsFlags)
                if (selectionCount > 0) {
                    leadsFlags.map(function (list, index) {
                        if (index <= (selectionCount - 1)) {
                            list['is_selected'] = true
                            updatedLeadsFlags.push(list)
                            state.selectedLeadsFlag.push(list)
                        } else {
                            list['is_selected'] = false
                            updatedLeadsFlags.push(list)
                            state.selectedLeadsFlag.push(list)
                        }
                    })
                }
                state.listOfSelectedObjectIds = updatedLeadsFlags;
                state.listOfSelectedObjectIdsCount = action.payload.count;
                return state
            }
        },
        updateArchiveSearchList: (state, action) => {
            state.isShowArchiveEmptyMessage = action.payload
            return state
        },
        updatePeopleProjectsList: (state, action) => {
            state.peopleProjectsList = action.payload
        },
        updateDropDownSelectedObjectType: (state, action) => {
            state.dropdownSelectedObjectType = action.payload
        },
        updateObjectId: (state, action) => {
            state.objectId = action.payload
        },
        updateObjectData: (state, action) => {
            state.projectRowData = action.payload
        },
        updateShowSelectedCompanies: (state, action) => {
            state.showSelectedCompanies = action.payload
        },
        updateProjectsForCompanyId: (state, action) => {
            state.projectsForCompanyId = action.payload.projects
            state.projectsForCompanyIdCount = action.payload.aggregation && action.payload.aggregation.project_count
            return state;
        },
        updateShowMoreProjectsForCompanyId: (state, action) => {
            state.projectsForCompanyId = state.projectsForCompanyId?.concat(action.payload.projects)
            return state;
        },
        updateSearchTextObject: (state, action) => {
            state.searchTextInformation = action.payload
            return state
        },
        updateLocationSearchObject: (state, action) => {
            state.locationSearchObject = action.payload
            return state;
        },
        updateCompanyLocationSearchObject: (state, action) => {
            state.companyLocationSearchObject = action.payload
            return state;
        },
        updateShowSelectedProjects: (state, action) => {
            state.showSelectedProjects = action.payload
            return state
        },
        updateCompanyDetail: (state, action) => {
            state.companyDetails = action.payload
            return state
        },
        updateObjectType: (state, action) => {
            state.objectType = action.payload
            return state
        },
        updatePeopleDetail: (state, action) => {
            state.peopleDetails = action.payload
            return state
        },
        updateMaxApiCalls: (state, action) => {
            state.maxApiCalls = action.payload
            return state
        },
        updateIfMoreResultAvailable: (state, action) => {
            state.isShowMoreActive = action.payload
            return state
        },
        updateShowDetailPeople: (state, action) => {
            state.showDetailPeople = action.payload
            return state
        },
        updateIsSelectedToSpecificRow: (state, action) => {
            if (action.payload?.entityType === 'searchList' || action.payload?.entityType === 'selectedSearchList') {
                let currentList = action.payload && action.payload.isShowRelatedEntitiesMode ? state.listOfSelectedObjectIds : state.searchList
                let leadsFlags = Object.assign({}, currentList);
                let updatedLeadsFlags = []
                let objectType = action.payload.filterType === 'project' ? 'project_id' : action.payload.filterType === 'company' ? 'company_id' : 'person_id';

                let currnetIndexes = action.payload && action.payload.isShowRelatedEntitiesMode ? parseInt(state.countOfSelectedObjectIds) : parseInt(state.selectedSelectorControlValue)
                Object.values(leadsFlags).map((record) => {
                    if (record[objectType] === action.payload.id) {
                        if (action.payload.isSelected === true) {
                            record['is_selected'] = true
                            currnetIndexes++
                        }
                        else {
                            record['is_selected'] = false
                            currnetIndexes--
                        }
                    }
                    updatedLeadsFlags.push(record)
                })
                if (action.payload.isShowRelatedEntitiesMode) {
                    state.listOfSelectedObjectIds = updatedLeadsFlags
                    state.countOfSelectedObjectIds = currnetIndexes
                }
                else {
                    state.searchList = updatedLeadsFlags
                    state.selectedSelectorControlValue = currnetIndexes
                }

                const { includeIds, excludeIds } = getIncludeExcludeIds(action.payload, state && state.includeIds, state && state.excludeIds)
                state.excludeIds = excludeIds
                state.includeIds = includeIds
                return state
            }
            else if (action.payload && action.payload.entityType === 'groupedCompanyProjects') {
                let currentList = state.groupCompanyProjects
                let leadsFlags = Object.assign({}, currentList);
                let updatedLeadsFlags = []
                let objectType = action.payload.filterType === 'project' ? 'project_id' : action.payload.filterType === 'company' ? 'company_id' : 'person_id';

                let currnetIndexes = parseInt(state.countOfSelectedObjectIds)
                Object.values(leadsFlags).map((record) => {
                    if (record[objectType] === action.payload.id) {
                        if (action.payload.isSelected === true) {
                            record['is_selected'] = true
                            currnetIndexes++
                        }
                        else {
                            record['is_selected'] = false
                            currnetIndexes--
                        }
                    }
                    updatedLeadsFlags.push(record)
                })
                if (action.payload.isShowRelatedEntitiesMode) {
                    state.groupCompanyProjects.projects = updatedLeadsFlags
                    state.countOfSelectedObjectIds = currnetIndexes
                }
                else {
                    state.groupCompanyProjects.projects = updatedLeadsFlags
                    state.selectedSelectorControlValue = currnetIndexes
                }

                const { includeIds, excludeIds } = getIncludeExcludeIds(action.payload, state && state.includeIds, state && state.excludeIds)
                state.excludeIds = excludeIds
                state.includeIds = includeIds
                return state
            }
        },
        updateShowHierarchy: (state, action) => {
            state.showProjectHierarchy = action.payload.showProjectHierarchy
            state.showCompanyHierarchy = action.payload.showCompanyHierarchy
            return state
        },
        updateHierarchyBack: (state, action) => {
            state.hierarchyBack = action.payload
            return state
        },
        updateIfMoreCompanyProjectsAvailable: (state, action) => {
            state.isShowMoreActiveCompanyProjects = action.payload
            return state
        },
        updateRemoveFilterToggle: (state, action) => {
            state.removeFilterToggle = action.payload
            return state
        },
        updateSearchListFav: (state, action) => {
            state.searchList = action.payload
            return state
        },
        updateProjectsFavForCompanyId: (state, action) => {
            state.projectsForCompanyId = action.payload
            return state;
        },
        updatelistOfSelectedObjectIdsFav: (state, action) => {
            state.listOfSelectedObjectIds = action.payload
            return state;
        },
        searchFilterInput: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
        resetIncludeExcludeIds: (state, action) => {
            state.excludeIds = action.payload
            state.includeIds = action.payload
            return state
        },
        updateFrameworkOptions: (state, action) => {
            state.frameworkOptions = action.payload;
            return state
        },
        setIncludeCheckboxLength: (state, action) => {
            state.includeLength = action.payload
        },
        setExcludeCheckboxLength: (state, action) => {
            state.excludeLength = action.payload
        },
        resetSearchFilter: () => {
            return initialState
        },
        resetRelationalEntititySelectionCount: (state) => {
            state.countOfSelectedObjectIds = 0
            return state
        },
        updateSearchCount: (state, action) => {
            state.searchCount = action.payload
            state.isCountResponseUpdated = true
            return state
        },
        updateAdditionalQuery: (state, action) => {
            state.additionalQuery = action.payload
            return state
        },
        updateSearchLoader: (state, action) => {
            state.isSearchLoader = action.payload;
            return state
        },
        updateExactSearchTextObject: (state, action) => {
            state.exactSearchTextInformation = action.payload
            return state
        },
        updateBuildPhase: (state, action) => {
            state.buildPhase = action.payload
            return state
        },
        updateBrands: (state, action) => {
            state.brands = action.payload
            return state
        },
        updateNoResult: (state, action) => {
            state.noResult = action.payload;
            return state
        },
        updateGroupCompanyProjects: (state, action) => {
            state.groupCompanyProjects = action.payload.projects
            state.groupCompanyProjectCount = action.payload.aggregation.project_count
            return state
        },
        updateGroupCompanyProjectsShowMore: (state, action) => {
            state.groupCompanyProjects = state.groupCompanyProjects.concat(action.payload)
            return state
        },
        updateGroupCompanyProjectsFav: (state, action) => {
            state.groupCompanyProjects = action.payload
        },
        updateFrameworkTags: (state, action) => {
            state.frameworkTagsData = action.payload
        },
        updateIncludeCompletedProj: (state, action) => {
            state.includeCompletedProj = action.payload
        },
        updateBuildPhaseSwitch: (state, action) => {
            state.buildPhaseSwitch = action.payload
        },
        resetAllSearch: (state) => {
            state.additionalQuery = initialState.additionalQuery
            state.searchTextInformation = initialState.searchTextInformation
            state.locationSearchObject = initialState.locationSearchObject
            return state
        },
        updateBackObjectId: (state, action) => {
            state.backObjectId = action.payload
        },
        updateProjectHierarchicalRoles: (state, action) => {
            state.projectHierarchicalRoles = action.payload
        },
        updateClusterList: (state, action) => {
            state.clusterList.leadsFlags = action.payload.leadsFlags
        },
        updateBackButtonCluster: (state, action) => {
            state.backButtonCluster.back = action.payload.back
        },
        updateBackClusterList: (state, action) => {
            state.backClusterList = action.payload
        },
        updateClusterClickCount: (state, action) => {
            state.clusterClickCount = action.payload
        },
        updateNavigationBack: (state, action) => {
            state.navigationBack = action.payload
        },
        updateIdsForShowRelatedEntities: (state, action) => {
            state.idsForShowRelatedEntities = action.payload
            return state
        },
        rolesForProject: (state, action) => {
            state.fetchRolesForProjects = action.payload;
            return state
        },
        updateInvalidRequest: (state, action) => {
            state.invalidRequest = action.payload
        },
        updateApiCall: (state, action) => {
            state.isApiCallFinish = action.payload
        },
        updateIsShowProjectsOrCompanySelected:(state,action)=>{
            state.isShowProjectsOrCompanySelected = action.payload
            return state
        }
    }
})

export const { fetchSearchListForAllObjectType, updateSelectedSelectorControlValue,
    updateProcessId, updateSelectedRecords, updateQueryResult, resetQueryResult,
    updateOffsetLimitValue, updateSearchListSelection, updatelistOfSelectedObjectIds, updateArchiveSearchList,
    updatePeopleProjectsList, updateDropDownSelectedObjectType, updateObjectId, updateShowSelectedCompanies,
    updateProjectsForCompanyId, updateSearchTextObject, updateLocationSearchObject, updateShowSelectedProjects,
    updateCompanyDetail, updateObjectType, updatePeopleDetail, updateMaxApiCalls, updateIfMoreResultAvailable,
    updateShowDetailPeople, updateObjectData, updateIsSelectedToSpecificRow, updateShowHierarchy, fetchShowMoreList,
    updateHierarchyBack, updateIfMoreCompanyProjectsAvailable, updateRemoveFilterToggle, updateSearchListFav,
    updateProjectsFavForCompanyId, updatelistOfSelectedObjectIdsFav, resetIncludeExcludeIds, fetchShowMoreListOfSelectedObjectIds,
    resetRelationalEntititySelectionCount, resetSearchFilter, searchFilterInput, updateCompanyLocationSearchObject,
    updateFrameworkOptions, updateSearchCount, updateAdditionalQuery, setIncludeCheckboxLength, setExcludeCheckboxLength, updateSearchLoader,
    updateExactSearchTextObject, updateBuildPhase, updateBrands, updateNoResult, updateGroupCompanyProjects, updateGroupCompanyProjectsShowMore,
    updateGroupCompanyProjectsFav, updateFrameworkTags, updateIncludeCompletedProj, updateBuildPhaseSwitch, resetAllSearch,
    updateBackObjectId, updateProjectHierarchicalRoles, updateShowMoreProjectsForCompanyId, updateClusterList,
    updateBackButtonCluster, updateBackClusterList, updateClusterClickCount, updateNavigationBack, updateIdsForShowRelatedEntities,
    rolesForProject, updateInvalidRequest ,updateApiCall,updateIsShowProjectsOrCompanySelected } = searchSlice.actions

export const searchReducer = searchSlice.reducer