import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  NavbarBrand,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Form,
  TabPane,
  Modal,
  ModalBody,
  Button,
  ModalHeader,
  ModalFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import "./topHeader.scss";
import Icon from "../common/js/icon";
import ColoredScrollbars from "../common/js/coloredScrollbars";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { useDispatch, useSelector } from "react-redux";
import { numberWithCommas } from "../../utils/commonFunctions";
import { signOutUser } from "../../components/login/apiclient";
import {
  sortNotications,
  capitalizeFirstLetter,
} from "../../utils/commonFunctions";
import {
  resetAll,
  updateFilterQuery,
  updateLeagueOffsetLimitValue,
  updatedSelectedSavedSearch,
} from "../search/redux/searchPeristReducer";
import {
  isPrint,
  summaryActivityDetail,
  updateActivityIsClicked,
} from "../../components/details/redux/reducer";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import {
  fetchSearchListForAllObjectType,
  resetAllSearch,
  resetRelationalEntititySelectionCount,
  updateBackObjectId,
  updateHierarchyBack,
  updateIdsForShowRelatedEntities,
  updateOffsetLimitValue,
  updatePeopleProjectsList,
  updateProjectsForCompanyId,
  updateRemoveFilterToggle,
  updateSelectedSelectorControlValue,
  updateShowDetailPeople,
  updateShowHierarchy,
  updateShowSelectedCompanies,
  updateShowSelectedProjects,
  updatelistOfSelectedObjectIds,
  updateNavigationBack,
  updateObjectId,
  rolesForProject,
  resetIncludeExcludeIds,
  updateNoResult,
  updateIsShowProjectsOrCompanySelected,
} from "../search/redux/reducer";
import {
  resetFilterState,
  updateContextType,
  updateFilterState,
  updateNewSearch,
  updateOffsetLimit,
  updateSelectedSearchType,
} from "../search/filters/redux/reducer";
import { TOURSAVEDSEARCH } from "../../utils/tour";
import { initializeFilterState } from "../search/filters/utils";
import { initializeSavedSearchFilterState } from "../savedsearch/tabs/utils";
import { fetchSingleSavedSearch } from "../savedsearch/apiclient";
import { getIntercomId } from "../../utils";
import { preProcessorQuery } from "../search/filters/preProcessorQuery";
import { fetchSearchListCount } from "../search/apiclient";



let sortedNotifications, calenderNotification;
var todaysDate = moment(new Date()).format("Do MMMM YYYY");

const TopHeader = (props) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);
  const user = useSelector((state) => state.user);
  const [modal, setModal] = useState(false);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const location = useLocation();
  const notification = useSelector((state) => state.notifications);
  sortedNotifications = sortNotications(notification.notificationList);
  calenderNotification = notification.calenderList;

  const settings = useSelector((state) => state.settings);
  const filter = useSelector((state) => state.filter);
  const searchPersist = useSelector((state) => state.searchPersist);
  const search = useSelector((state) => state.search);
  const project = useSelector((state) => state.project);
  const navigate = useNavigate();

  const handleLogoutOld = () => {
    setModal(!user.isRedirectedUser ? !modal : modal);
  };
  const handleCancel = () => {
    setModal(!modal);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    signOutUser(dispatch);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (userData) {
      let intercomId = getIntercomId(window.location);
      window.intercomSettings = {
        app_id: intercomId,
        hide_default_launcher: true,
        alignment: "left",
        horizontal_padding: 80,
        vertical_padding: 0,
      };
      window.Intercom("boot", {
        app_id: intercomId,
        user_id: userData?.user_id,
        user_hash: userData?.intercom?.secret_key,
      });
    }
  });

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isNotificationMenuOpen &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setIsNotificationMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      if (project?.isPrint) {
        const delayTimeout = setTimeout(() => {
          let page = document.getElementsByTagName('body')[0];
          page.classList.add('noscroll');
          document.body.classList.remove("print");
          dispatch(isPrint(false));
        }, 1000);
        return () => clearTimeout(delayTimeout);
      }
    };
  }, [isNotificationMenuOpen]);

  const handleSummary = () => {
    dispatch(summaryActivityDetail("calendar_events"));
    dispatch(updateActivityIsClicked(true));
    setIsNotificationMenuOpen(false);
  };

  const resetSearch = () => {
    if (project?.isPrint) {
      let page = document.getElementsByTagName('body')[0];
      page.classList.add('noscroll');
      document.body.classList.remove("print");
      dispatch(isPrint(false));
    }

    dispatch(resetIncludeExcludeIds([]))
    dispatch(updateSelectedSearchType(searchPersist.appliedContext))
    dispatch(updateOffsetLimit({ offset: 0, records: 50 }));
    dispatch(updateLeagueOffsetLimitValue({ offset: 0, records: searchPersist?.leagueOffsetLimitValue?.records }))
    dispatch(
      fetchSearchListForAllObjectType({
        leadsFlags: [],
        count: 0,
        filterType: searchPersist.appliedContext,
      })
    );
    dispatch(resetAll());
    dispatch(resetAllSearch());
    if (search.showSelectedCompanies || search.showSelectedProjects) {
      searchPersist.appliedContext === "project"
        ? dispatch(updateShowSelectedCompanies(false))
        : dispatch(updateShowSelectedProjects(false));
      dispatch(updateIsShowProjectsOrCompanySelected(false))
      dispatch(
        updateSelectedSelectorControlValue(search?.selectedLeadsFlag?.length)
      );
    } else {
      dispatch(updateSelectedSelectorControlValue(0));
    }
    dispatch(updateShowDetailPeople(false));
    dispatch(updateHierarchyBack(true));
    dispatch(updateNoResult(false))
    dispatch(updateIdsForShowRelatedEntities([]));
    dispatch(rolesForProject([]));
    dispatch(updatePeopleProjectsList([]));
    dispatch(updateRemoveFilterToggle(true));
    dispatch(updateBackObjectId(undefined));
    dispatch(
      updateShowHierarchy({
        showProjectHierarchy: false,
        showCompanyHierarchy: false,
      })
    );
    dispatch(updateShowSelectedCompanies(false));
    dispatch(updateShowSelectedProjects(false));
    dispatch(updatelistOfSelectedObjectIds({ leadsFlags: [], count: 0 }));
    dispatch(updateObjectId(undefined));
    dispatch(updateNavigationBack(""));
    dispatch(updateProjectsForCompanyId([]));
    if (searchPersist.customerProfile) {
      let defaultFilter = initializeFilterState(
        search?.frameworkOptions,
        searchPersist.customerProfile,
        {}
      );
      dispatch(updateFilterState(defaultFilter));
      dispatch(updateNewSearch(true));
    }
    dispatch(
      updateContextType({
        context: searchPersist.appliedContext,
        searchMode: "basic",
      })
    );
    dispatch(updatedSelectedSavedSearch({}));
    dispatch(updateFilterQuery({}));
    navigate("/app/list");
  };

  const handleFeedSaveSearchClick = (savedSearchId) => {
    setIsNotificationMenuOpen(false);
    let defaultFilter = initializeFilterState(
      search?.frameworkOptions,
      searchPersist.customerProfile,
      {}
    );
    dispatch(updateFilterState(defaultFilter));
    fetchSingleSavedSearch(
      savedSearchId,
      searchPersist,
      filter,
      dispatch,
      navigate
    );
  };

  const loadNotificationList = () => {
    return (
      <Row className="pt-3 ms-0 me-0 mb-3 notification-tab-panel">
        <Col className="p-0 notification-tab-wrapper">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => toggle("1")}
              >
                ABI Updates (
                {notification && notification.notificationBadgeCount})
              </NavLink>
            </NavItem>
            {userData?.licenceFlag?.isCalendarLicence === true ? (
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => toggle("2")}
                >
                  Calendar (
                  {notification &&
                    notification.calenderList &&
                    notification.calenderList.length > 0
                    ? notification.calenderList.calenderBadge
                    : 0}
                  )
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className="p-1">
              {NotificationList()}
            </TabPane>
            {userData?.licenceFlag?.isCalendarLicence === true ? (
              <TabPane tabId="2" className="p-1">
                {CalendarNotificationList()}
              </TabPane>
            ) : (
              ""
            )}
          </TabContent>
          <div className="d-flex justify-content-between pt-2 ps-2 pe-2 pb-0">
            <Link
              className="see-all"
              to={"/app/myleads#updates"}
              onClick={() => setIsNotificationMenuOpen((oldState) => !oldState)}
            >
              See all of your updates
            </Link>
            {userData?.licenceFlag?.isCalendarLicence === true ? (
              <Link
                className="view-calendar"
                to={"/app/calendar"}
                onClick={() =>
                  setIsNotificationMenuOpen((oldState) => !oldState)
                }
              >
                View Calendar
              </Link>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    );
  };

  const masqueradeSignOut = () => {
    signOutUser(dispatch);
  };

  const NotificationList = () => {
    return (
      <div className="panel-scroll-wrapper">
        <ColoredScrollbars className="panel-scrollbar">
          {Object.keys(sortedNotifications).length > 0 ? (
            Object.keys(sortedNotifications).map(function (key, index) {
              let routingState = {
                prevNextList: "NotificationList",
                showMoreResult: false,
                index: 0,
                list: [],
              };

              return (
                <div className="daily-notification" key={index}>
                  <span className="day">{key}</span>
                  <span className="date">
                    {sortedNotifications[key][0].date}
                  </span>
                  <ListGroup>
                    {sortedNotifications[key].map(function (
                      notification,
                      index
                    ) {
                      let notificationLink;
                      if (
                        notification.object_type == "saved_search" &&
                        notification.notificationtypeno === 3
                      )
                        notificationLink = (
                          <a
                            href="javascript:void(0)"
                            className="noti-content"
                            onClick={() =>
                              handleFeedSaveSearchClick(notification.object_id)
                            }
                          >
                            {notification.subject}
                            <br />
                            {notification.body}
                          </a>
                        );
                      else if (notification.object_type == "company")
                        notificationLink = (
                          <Link
                            className="noti-content"
                            to={`/app/company/${0}/${notification.object_id}`}
                            state={routingState}
                            onClick={() =>
                              setIsNotificationMenuOpen((oldState) => !oldState)
                            }
                          >
                            {notification.subject}
                          </Link>
                        );
                      else if (notification.object_type == "project")
                        notificationLink = (
                          <Link
                            className="noti-content"
                            to={`/app/project/${notification.object_id}`}
                            onClick={() =>
                              setIsNotificationMenuOpen((oldState) => !oldState)
                            }
                            state={routingState}
                          >
                            {notification.subject}
                          </Link>
                        );
                      else if (notification.object_type == "tag")
                        notificationLink = (
                          <Link
                            className="noti-content"
                            to={`/app/myLeads#tags`}
                            onClick={() =>
                              setIsNotificationMenuOpen((oldState) => !oldState)
                            }
                            state={routingState}
                          >
                            {notification.subject}
                          </Link>
                        );
                      else if (notification.notificationtypeno === 1) {
                        notificationLink = (
                          <Link
                            className="noti-content"
                            to={`/app/myLeads#updates`}
                            onClick={() =>
                              setIsNotificationMenuOpen((oldState) => !oldState)
                            }
                            state={routingState}
                          >
                            {notification.subject}
                          </Link>
                        );
                      } else
                        notificationLink = (
                          <Link
                            className="noti-content"
                            to={`/app/savedsearch`}
                            state={routingState}
                            onClick={() =>
                              setIsNotificationMenuOpen((oldState) => !oldState)
                            }
                          >
                            {notification.subject}
                          </Link>
                        );
                      return (
                        <ListGroupItem
                          className="notification-block"
                          key={index}
                        >
                          <div key={index}>
                            {/* <span className="noti-content">{notification.subject}</span>
                                    <span className="noti-content">{notification.body}</span> */}
                            {notificationLink}
                            <span className="noti-hour">
                              {notification.time}
                            </span>
                          </div>
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                </div>
              );
            })
          ) : (
            <span className="nothing-found">No notifications</span>
          )}
        </ColoredScrollbars>
      </div>
    );
  };

  const CalendarNotificationList = () => (
    <div
      className={`${calenderNotification &&
        calenderNotification &&
        Object.keys(calenderNotification).length > 0
        ? ""
        : "no-notifications"
        }`}
    >
      <div className="panel-scroll-wrapper">
        <ColoredScrollbars className="panel-scrollbar">
          {calenderNotification &&
            Object.keys(calenderNotification).length > 0 ? (
            <div className="daily-notification pb-1">
              <span className="day d-inline me-2">Today</span>
              <span className="date d-inline">{todaysDate}</span>
            </div>
          ) : (
            ""
          )}
          {calenderNotification &&
            Object.keys(calenderNotification).length > 0 ? (
            calenderNotification.map(function (event, index) {
              let detailLink =
                event.object_type == "project"
                  ? `/app/project/${event.object_id}`
                  : `/app/company/0/${event.object_id}`;
              let routingState = {
                prevNextList: "calenderNotificationList",
                showMoreResult: false,
                index: 0,
                list: [],
              };
              return (
                <div className="daily-notification" key={index}>
                  <ListGroup>
                    <ListGroupItem className="notification-block" key={index}>
                      <div className="event-listing" key={event.event_id}>
                        <Link
                          className="noti-content"
                          to={detailLink}
                          state={routingState}
                          onClick={() => {
                            handleSummary(event);
                          }}
                        >
                          <div className="d-flex justify-content-start align-items-center ">
                            <div className="event-subject">
                              {event.subject} - ({event.name})
                            </div>
                          </div>
                          <div className="event-details d-flex align-items-center">
                            <span className={event && event.type}>
                              {capitalizeFirstLetter(event && event.priority)}
                            </span>
                            <ul className="d-inline-flex pl-2">
                              <li>
                                {capitalizeFirstLetter(event && event.type)}
                              </li>
                              <li>
                                {capitalizeFirstLetter(event && event.status)}
                              </li>
                            </ul>
                          </div>
                          {event.start_on || event.end_on ? (
                            <div class="event-scheduled-time">
                              From
                              <span className="pr-3">
                                {" "}
                                {event.start_on
                                  ? moment
                                    .utc(event.start_on)
                                    .format("DD-MMM-YYYY HH:mm")
                                  : ""}{" "}
                                {event.start_on && event.end_on ? "to" : ""}{" "}
                                {event.end_on
                                  ? moment
                                    .utc(event.end_on)
                                    .format("DD-MMM-YYYY HH:mm")
                                  : ""}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </Link>
                      </div>{" "}
                    </ListGroupItem>
                  </ListGroup>
                </div>
              );
            })
          ) : (
            <span className="nothing-found">No notifications</span>
          )}
        </ColoredScrollbars>
      </div>
    </div>
  );

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex top-left">
          <div className="navbar-brand-box">
            <NavbarBrand
              href={user?.onlyDeveloperLicenses ? null : user.user.licenceFlag?.onlyHasJustAskLicense ? "/app/justask" : "/app/list"}
              className="logo-placeholder mx-2 my-0 d-block"
              onClick={user?.onlyDeveloperLicenses ? (e) => e.preventDefault() : null}
            ></NavbarBrand>
          </div>
          <div className="total-project-universe d-flex">
            <div className="m-0 universe-caption px-3">
              <p className="title mb-0"> In last 7 days</p>
              <span className="universe-count">
                <b>
                  {" "}
                  {numberWithCommas(
                    user &&
                    user.universeCount &&
                    user.universeCount.lastWeekUniverseCount
                  )}{" "}
                </b>
              </span>
            </div>
            <div className="m-0 universe-caption px-3">
              <p className="title mb-0"> Total projects universe</p>
              <span className="universe-count">
                <b>
                  {" "}
                  {numberWithCommas(
                    user &&
                    user.universeCount &&
                    user.universeCount.totalUniverseCount
                  )}
                </b>
              </span>
            </div>
          </div>
          {user.isMasqueradeUser ? (
            <div className="masquerades-batch">
              <span className="masquerade-user">
                Masquerading {user.user.first_name} {user.user.last_name}
              </span>
              <button
                onClick={masqueradeSignOut}
                className="masquerade-btn outline-btn"
              >
                Stop masquerade
              </button>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="d-flex top-right">
          <div
            className="search-button-div"
            data-intro={
              location.pathname == "/app/savedsearch"
                ? TOURSAVEDSEARCH.newsearch.intro
                : null
            }
            data-position={TOURSAVEDSEARCH.newsearch.position}
            data-class={TOURSAVEDSEARCH.newsearch.class}
          >
            <button
              type="button"
              className="btn btn-primary"
              onClick={user?.onlyDeveloperLicenses ? null : resetSearch}
              disabled={user.isRedirectedUser ? true : false || user?.onlyDeveloperLicenses}
            >
              <Icon
                color="#FFF"
                size={25}
                icon="icon-sb--search"
                className="me-1 search-icon"
              />
              <span>New Search</span>
            </button>
          </div>

          <div className="profile-links">
            <div className="wrapper d-flex" ref={ref}>
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                data-tooltip-id="notification-bell"
                data-tooltip-hidden={!settings.preferences.hint_text}
                onClick={() =>
                  setIsNotificationMenuOpen((oldState) => !oldState)
                }
                disabled={user.isRedirectedUser ? true : false}
              >
                <Icon
                  color="#053874"
                  size={20}
                  icon="icon-gen-bell"
                  className="me-1 bell-icon"
                />
                <span
                  className={`${notification && notification.badgeCount > 0
                    ? "count"
                    : "hide"
                    }`}
                >
                  {notification.badgeCount > 99
                    ? 99
                    : notification && notification.badgeCount}
                  <span
                    className={`${notification && notification.badgeCount > 99
                      ? "plus-icon"
                      : "hide"
                      }`}
                  >
                    +
                  </span>
                </span>
              </button>
              <ReactTooltip
                id="notification-bell"
                place="left"
                type="dark"
                effect="solid"
              >
                <p className="tool-tip-sidebar">View notification(s)</p>
              </ReactTooltip>

              <div
                className={`notification-box collapse px-2 py-0 ${isNotificationMenuOpen ? "show" : ""
                  }`}
              >
                {isNotificationMenuOpen && loadNotificationList()}
              </div>
            </div>

            <div
              className="dropdown profile-name"
              data-intro={
                location.pathname == "/app/savedsearch"
                  ? TOURSAVEDSEARCH.notification.intro
                  : null
              }
              data-position={TOURSAVEDSEARCH.notification.position}
              data-class={TOURSAVEDSEARCH.notification.class}
            >
              <button
                type="button"
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="user-icon">
                  {userData
                    ? userData["first_name"][0] + userData["last_name"][0]
                    : ""}
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end px-2 py-0">
                <h6 class="px-0 py-2 dropdown-header">
                  <span class="user-icon">
                    {userData
                      ? userData["first_name"][0] + userData["last_name"][0]
                      : ""}
                  </span>
                  <span className="greet-user">
                    {" "}
                    {userData ? userData.first_name : ""}{" "}
                    {userData ? userData.last_name : ""}{" "}
                    <i>{userData ? userData.username : ""}</i>
                  </span>
                </h6>
                <div className="shortcut-group mb-2">
                  {userData?.licenceFlag?.isInsideSalesLicence === true || userData?.licenceFlag?.isLandmarkLicence ? (
                    <div className="license-based">
                      <a
                        className={`dropdown-item py-2 ${user.isRedirectedUser ? "disabled" : ""
                          }`}
                        href="/app/settings#notifications"
                      >
                        <span key="t-profile">Settings</span>
                      </a>
                      {userData?.licenceFlag?.isConstructionHubLicence ===
                        true ? (
                        <a
                          className={`dropdown-item py-2 ${user.isRedirectedUser ? "disabled" : ""
                            }`}
                          href="/app/integrations#constructionHub"
                        >
                          <span key="t-my-wallet">Integrations</span>
                        </a>
                      ) : (
                        ""
                      )}
                      <a
                        className={`dropdown-item py-2 ${user.isRedirectedUser ? "disabled" : ""
                          }`}
                        href="/app/settings#favouriteRoles"
                      >
                        <span key="t-settings">Favourite roles</span>
                      </a>
                      <a
                        className={`dropdown-item py-2 ${user.isRedirectedUser ? "disabled" : ""
                          }`}
                        href="/app/settings#updatePassword"
                      >
                        <span key="t-lock-screen">Change password</span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  <a
                    className={`dropdown-item py-2 ${user.isRedirectedUser ? "disabled" : ""
                      }`}
                    href="https://www.barbour-abi.com/privacy-policy/"
                    target="_blank"
                  >
                    <span key="t-lock-screen">Privacy policy</span>
                  </a>
                  <a
                    className={`dropdown-item py-2 ${user.isRedirectedUser ? "disabled" : ""
                      }`}
                    onClick={handleLogoutOld}
                  >
                    <span key="t-logout">Sign out</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Form>
        <Modal isOpen={modal}>
          <ModalHeader>
            {" "}
            Sign out{" "}
            <Button
              type="primary"
              className="btn-cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </ModalHeader>
          <ModalBody className="save-model-body">
            Are you sure you want to sign out?
          </ModalBody>
          <ModalFooter>
            <Button
              className="outline-btn btn-save-cancel"
              onClick={(e) => {
                handleLogout(e);
              }}
            >
              Yes
            </Button>
            <Button
              className="primary-btn btn-save-delete"
              onClick={handleCancel}
            >
              Cancel
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </Form>
    </header>
  );
};

export default TopHeader;
