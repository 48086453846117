import { createSlice } from "@reduxjs/toolkit";
import { fetchSortValue } from '../../../utils/commonFunctions'

const initialState = {
    searchTextInfo: {
        project: [],
        people: [],
        company: []
    },
    appliedContext: "project",
    locationSearchObj: {
        project: {},
        company: {}
    },
    filterSearchText: "Radial search",
    defaultSort: { project: { "label": "Latest", "value": fetchSortValue("L", "project") }, company: { "label": "Alphabetical", value: fetchSortValue("companyAlphabetical", "company") }, people: { "label": "Alphabetical", value: fetchSortValue("alphabetical", "people") } },
    heirarchialSort: { project: { "label": "Latest", "value": fetchSortValue("L", "project") }, company: { "label": "Latest", value: fetchSortValue("L", "company") } },

    // leadsDefaultSort: {project:{"label":"Latest","value":fetchSortValue("L","project")},company:{"label":"Latest",value:fetchSortValue("L","company")}},
    
    leadsSort: {
        "history": {
            "project": { "label": "Last viewed", "value": fetchSortValue("last viewed", "project") },
            "company": { "label": "Last viewed", "value": fetchSortValue("last viewed", "company") }
        },
        "favourite": {
            "project": { "label": "Latest", "value": fetchSortValue("L", "project") },
            "company": { "label": "Latest", "value": fetchSortValue("L", "company") }
        },
        "shares": {
            "project": { "label": "Latest", "value": fetchSortValue("L", "project") },
            "company": { "label": "Latest", "value": fetchSortValue("L", "company") }
        },
        "tags": {
            "project": { "label": "Latest", "value": fetchSortValue("L", "project") },
            "company": { "label": "Latest", "value": fetchSortValue("L", "company") }
        },
        "archive": {
            "project": { "label": "Latest", "value": fetchSortValue("L", "project") },

        }
    },
    additionalQuery: {},
    summaryTags: [],
    leadsAppliedContext: {
        updates: "project",
        favourite: "project",
        history: "project",
        tags: "project",
        notes: "project",
        shares: "project",
        archive: "project",
    },
    leagueTable: {
        selectedRolesValue: { "label": "Roles", value: null },
        selectedCategoriesValue: { "label": "Categories", value: null },
        selectedRegionsValue: { "label": "Regions", value: null },
        selectedTimeValue: { label: 'Time period', value: null },
        selectedSavedSearch: { "saved_search_name": "Saved Search", query: null },
        selectedTopSearch: { label: 'Top 50', value: 50 },
        selectedSortValue: "Value",
        isShowMoreActive: false
    },
    leagueOffsetLimitValue: {
        offset: 0,
        records: 50
    },
    saveSeachRedirectionApplied: false,
    selectedSaveSearch: {},
    filterQuery: {},
    currentPage: 0,
    loginRedirectDefaultSaveSearch: false,
    login: false,
    notificationRedirectSaveSearch: false,
    sortClick: false,
    isListView: false
}

const searchPersistSlice = createSlice({
    name: 'searchPersist',
    initialState,
    reducers: {
        updateSearchTextObj: (state, action) => {
            state.searchTextInfo = action.payload
            return state
        },
        updateAppliedContext: (state, action) => {
            state.appliedContext = action.payload
            return state;
        },
        updateLocationSearchObj: (state, action) => {
            state.locationSearchObj = action.payload
            return state;
        },
        updateFilterSearchText: (state, action) => {
            state.filterSearchText = action.payload
            return state;
        },
        updateSortSearch: (state, action) => {
            state.defaultSort = action.payload
            return state;
        },
        updateAdditionalQuery: (state, action) => {
            state.additionalQuery = action.payload
            return state
        },
        resetAll: (state) => {
            state.locationSearchObj = initialState.locationSearchObj
            state.defaultSort = initialState.defaultSort
            state.additionalQuery = initialState.additionalQuery
            state.filterSearchText = initialState.filterSearchText
            state.searchTextInfo = initialState.searchTextInfo
            state.summaryTags = initialState.summaryTags
            state.heirarchialSort=initialState.heirarchialSort
            return state
        },
        updateSummaryTags: (state, action) => {
            state.summaryTags = action.payload
            return state
        },
        updateLeadsAppliedContext: (state, action) => {
            const { activeTab, context } = action.payload;
            state.leadsAppliedContext[activeTab] = context
            return state;
        },
        updateLeadsSortSearch: (state, action) => {
            state.leadsSort = action.payload
            return state;
        },
        updateFrameworkList: (state, action) => {
            state.frameworkList = action.payload;
            return state
        },
        updateCustomerProfile: (state, action) => {
            state.customerProfile = action.payload
            return state
        },
        updateLeagueTable: (state, action) => {
            const { dropdown, value } = action.payload;
            state.leagueTable[dropdown] = value
            return state;
        },
        updateLeagueOffsetLimitValue: (state, action) => {
            state.leagueOffsetLimitValue = action.payload
            return state
        },
        updateSaveSeachRedirectionApplied: (state, action) => {
            state.saveSeachRedirectionApplied = action.payload
            return state;
        },
        updatedSelectedSavedSearch: (state, action) => {
            state.selectedSaveSearch = action.payload
            return state;
        },
        updateFilterQuery: (state, action) => {
            state.filterQuery = action.payload
            return state;
        },
        updateCurrentPage: (state, action) => {
            state.currentPage = action.payload
            return state
        },
        updateIsListView: (state, action) => {
            state.isListView = action.payload
            return state
        },
        updateLoginRedirectDefaultSaveSearch: (state, action) => {
            state.loginRedirectDefaultSaveSearch = action.payload
            return state;
        },
        updateLogin: (state, action) => {
            state.login = action.payload
            return state;
        },
        updateNotificationRedirectSaveSearch: (state, action) => {
            state.notificationRedirectSaveSearch = action.payload
        },
        updateSortClick: (state, action) => {
            state.sortClick = action.payload
        },
        updateHierachialSort: (state, action) => {
            state.heirarchialSort = action.payload
            return state;
        },
    }
})

export const { updateSearchTextObj, updateAppliedContext, updateLocationSearchObj, updateFilterSearchText,
    updateAdditionalQuery, updateSortSearch, updateSummaryTags, resetAll, updateLeadsAppliedContext,
    updateLeadsSortSearch, updateFrameworkList, updateCustomerProfile, updateLeagueTable, updateLeagueOffsetLimitValue,
    updateCurrentPage, updateIsListView, updateSaveSeachRedirectionApplied, updatedSelectedSavedSearch,
    updateFilterQuery, updateLoginRedirectDefaultSaveSearch, updateLogin, updateNotificationRedirectSaveSearch,
    updateSortClick ,updateHierachialSort} = searchPersistSlice.actions

export const searchPersistReducer = searchPersistSlice.reducer