import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel';
import "./justask.scss"
import { updateFilterState, updateProfileStore } from './search/filters/redux/reducer';
import { initializeFilterState } from './search/filters/utils';
import { updateFilterQuery, updateSortClickJA } from './search/redux/searchPeristReducer';
import { preProcessorQuery } from './search/filters/preProcessorQuery';
import { fetchSearchListCount } from './search/apiclient';
import { updateApiCall } from './search/redux/reducer';

const JuskAskMain = () => {

    const jAskPersist = useSelector((state) => state.justAskPersist)
    const justAsk = useSelector((state) => state.justAsk)
    const filter = useSelector((state) => state.justAskFilter)
    const settings = useSelector((state) => state.settings)
    const dispatch = useDispatch()
    const [justAskContent, setjustAskContent] = useState(true)

    useEffect(() => {
        if (justAsk?.displayContent) {
            setjustAskContent(true)
        } else {
            setjustAskContent(false)
        }
    }, [justAsk?.displayContent])

    useEffect(() => {
        if (!filter.profileStore && jAskPersist.customerProfile) {
            let defaultFilter = initializeFilterState(justAsk?.frameworkOptions, jAskPersist.customerProfile, {})
            dispatch(updateFilterState(defaultFilter))
            dispatch(updateProfileStore(true))
        }
    }, [jAskPersist?.customerProfile])

    useEffect(() => {
        if (!justAsk.backObjectId && jAskPersist.sortClick) {
            fetchSearchList()
            dispatch(updateApiCall(false))
            dispatch(updateSortClickJA(false))
        }
    }, [jAskPersist.sortClick])

    const fetchSearchList = async () => {
        var formValues;

        if (justAsk?.showSelectedCompanies || justAsk?.showSelectedProjects) {
            let type = jAskPersist.appliedContext === "project" ? 'company' : 'project';
            formValues = preProcessorQuery('', filter.filtersState, type, justAsk, jAskPersist);
            if (jAskPersist?.appliedContext === 'project' && justAsk?.idsForShowRelatedEntities?.length > 0) {
                formValues.query.project_id = {
                    operator: '=',
                    value: justAsk?.idsForShowRelatedEntities
                };
            }

            if (jAskPersist?.appliedContext === 'company' && justAsk?.idsForShowRelatedEntities?.length > 0) {
                formValues.query.company_id = {
                    operator: '=',
                    value: justAsk?.idsForShowRelatedEntities
                };
            }
        } else {
            formValues = preProcessorQuery('', filter.filtersState, jAskPersist.appliedContext);
        }
        fetchSearchListCount(formValues, dispatch, justAsk, settings, jAskPersist.query);
        dispatch(updateFilterQuery(formValues));

    }

    return (
        <><div id="justAskContainer" className="w-100">
            <Row className={`w-100 ${justAskContent ? 'allowOveflowY' : ''}`}>
                <Col md="3" className={`leftPanel ${justAskContent ? '' : 'h-100'}`}>
                    <LeftPanel />
                </Col>
                <Col md="9" className="rightPanel pe-0">
                    <RightPanel />
                </Col>
            </Row>
        </div>
        </>
    );
}

export default JuskAskMain;