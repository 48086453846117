import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { checkAvailabilityOfLimit, checkAvailabilityOfOption, selectorControlValue } from '../../../utils/commonFunctions'
import Icon from '../../common/js/icon'
import _ from 'lodash';
import { fetchLabelDataForAllDetails } from '../../details/apiclient'
import ArchiveModal from '../../details/list/archiveModal'
import TagsModal from '../../details/list/tagsModal'
import { updateIsProjectSelected, updateSelectedTagDetails } from '../../details/redux/reducer'
import { checkIfTrialCustomerUserExportedDataForToday, formatNumber } from '../../../utils'
import { useNavigate } from 'react-router-dom'
import { rolesForProject, updateCompanyDetail, updateHierarchyBack, updatelistOfSelectedObjectIds, updateObjectData, updateObjectId, updatePeopleProjectsList, updateProjectsForCompanyId, updateRemoveFilterToggle, updateShowHierarchy } from '../../search/redux/reducer';
import { resetRelationalEntititySelectionCount, updateSelectedSelectorControlValue, updateSearchListSelection } from '../reducer';
import { updateMyLeadsSelectorControlTags, selectorControllerArchive, updateSelectorControlAction } from '../apiclient'
import { createDownloadPdfPayload } from '../../export/utils'
import { MAX_PDF_DOWNLOAD_LIMIT } from '../../../utils/settings'
import { createNewExport } from '../../export/apiclient'

const MyLeadsSearchSelectorDropdown = (props) => {
    const exports = useSelector((state) => state.export)
    const user = useSelector((state) => state.user.user);
    const search = useSelector((state) => state.search)
    const project = useSelector((state) => state.project)
    const myleads = useSelector((state) => state.myleads)
    const [myLeadsList, setMyleadsList] = useState(props.prevNextList === "myLeadsUpdates" ? myleads.updatesSearchList : myleads?.searchList)
    const searchPersist = useSelector((state) => state.searchPersist)
    const settings = useSelector((state) => state.settings)
    const [activeSort, setActiveSort] = useState('Latest')
    const [booleanData, setBooleanData] = useState({
        dropdownOpen: false,
        actionsDropDownOpen: false,
        isModalOpen: false,
        modal: false,
        archive: false
    })
    const dispatch = useDispatch()
    const [listCount, setListCount] = useState(props.prevNextList === "myLeadsUpdates" ? myleads?.updatesSearchListCount : myleads?.leadSearchListCount)
    const [searchControlLimit, setSearchControlLimit] = useState(selectorControlValue(props.prevNextList === "myLeadsUpdates" ? myleads?.updatesSearchListCount : myleads?.leadSearchListCount))
    const [objectType, setObjectType] = useState('')
    const [isPdfActive] = useState(user.licenceFlag?.isExportLicence)
    const [isExportEnabled] = useState(checkIfTrialCustomerUserExportedDataForToday(exports?.exportList?.exports, user))
    let statusList = (searchPersist.isListView ? 'active' : '')
    const navigate = useNavigate();

    useEffect(() => {
        setSearchControlLimit(selectorControlValue(props.prevNextList === "myLeadsUpdates" ? myleads?.updatesSearchListCount : myleads?.leadSearchListCount))
        setListCount(props.prevNextList === "myLeadsUpdates" ? myleads?.updatesSearchListCount : myleads?.leadSearchListCount)
    }, [myleads.leadSearchListCount, myleads.updatesSearchListCount])

    const handleDropDownItemClick = (value) => {
        if (statusList && value === '') {
            value = props.prevNextList === "myLeadsUpdates" ? myleads?.updatesSearchListCount : myleads.leadSearchListCount
        }
        let payload = { count: value, list: props.prevNextList }
        dispatch(resetRelationalEntititySelectionCount())
        dispatch(updateSelectedSelectorControlValue(payload))
        dispatch(updateSearchListSelection(payload))
    }

    const handleItemSelect = (count) => {
        handleDropDownItemClick(count)
        setBooleanData(prev => ({ ...prev, dropdownOpen: false }))
    }

    useEffect(() => {
        if (props.prevNextList !== 'myLeadsUpdates') {
            setMyleadsList(myleads?.searchList)
        }
    }, [myleads?.searchList])

    useEffect(() => {
        if (props.prevNextList === 'myLeadsUpdates') {
            setMyleadsList(myleads?.updatesSearchList)
        }
    }, [myleads?.updatesSearchList])

    const handleSelectorControlActionsClick = (action) => {

        let objectIds = getUniqueSelectedList()
        let id = searchPersist.leadsAppliedContext[props?.activeTab] === 'project' ? 'project_id' : 'company_id'
        if (objectIds.length > 0) {
            const payload = {
                object_type: searchPersist.leadsAppliedContext[props?.activeTab],
                favourite: action === "Favourite" ? true : false,
                offset: 0,
                limit: 50,
                listName: props.prevNextList
            }
            payload[id] = objectIds;
            if (props.prevNextList === 'myLeadsTags') {
                payload['tag'] = myleads.tagId;
            }
            if (myleads?.selectorControlSelectionCount) {
                payload['selected_records'] = myleads?.selectorControlSelectionCount
            }
            if (myleads.includeIds && myleads.includeIds.length > 0) {
                payload['selected_records'] = payload['selected_records'] - myleads?.includeIds.length
                payload[id] = myleads.includeIds
            }
            if (myleads.excludeIds && myleads.excludeIds.length > 0) {
                payload['selected_records'] = payload['selected_records'] + myleads?.excludeIds.length
                payload['exclude_ids'] = myleads.excludeIds
            }
            payload['identifier'] = 'favourite'
            if (activeSort) {
                payload["sort"] = searchPersist?.leadsSort?.[props?.activeTab]?.[searchPersist.leadsAppliedContext[props?.activeTab]]?.value

            }
            if (('selected_records' in payload && payload['selected_records'] === 0) || isNaN(payload['selected_records'])) {
                delete payload['selected_records']
            }
            else {
                payload.selected_records = parseInt(payload.selected_records)
            }
            updateSelectorControlAction(payload, myleads, searchPersist, dispatch)
        }

        setBooleanData(prev => ({ ...prev, actionsDropDownOpen: !booleanData.actionsDropDownOpen }))
    }
    const getUniqueSelectedList = () => {
        let id = searchPersist.leadsAppliedContext[props?.activeTab] === 'project' ? 'project_id' : 'company_id'
        let objectIds = []
        const newArray = myLeadsList.filter(element => element.is_selected).map(element => element[id])
        objectIds = [...new Set(newArray)]
        return objectIds
    }

    const handleSelectorControlTagsClick = (action) => {
        fetchLabelDataForAllDetails(dispatch)
        setBooleanData(prev => ({ ...prev, modal: !booleanData.modal }))
    }

    const handleArchive = () => {
        const payload = {
            offset: 0,
            limit: 50,
            listName: props.prevNextList,
            archive: true,
            object_type: 'project'
        }
        if (props.prevNextList !== 'myLeadsHistory') {
            payload['selected_records'] = parseInt(myleads?.selectedSelectorControlValue)
            if (myleads?.includeIds?.length > 0) {
                payload['selected_records'] = payload['selected_records'] - myleads.includeIds.length
                payload['project_id'] = myleads.includeIds
            }
            if (myleads?.excludeIds?.length > 0) {
                payload['selected_records'] = payload['selected_records'] - myleads.excludeIds.length
                payload['exclude_ids'] = myleads.excludeIds
            }
        } else {
            payload["project_id"] = getUniqueSelectedList()
        }

        if (payload['selected_records'] > 0) {
            if (props.prevNextList === "myLeadsUpdates" || props.prevNextList === "myLeadsFavourites" || props.prevNextList === "myLeadsArchive" || props.prevNextList === "myLeadsShares" || props.prevNextList === "myLeadsTags") {
                payload['identifier'] = props.prevNextList === "myLeadsFavourites" ? 'favourite' : props.prevNextList === 'myLeadsUpdates' ? 'updates'
                    : props.prevNextList === 'myLeadsShares' ? 'shares' : props.prevNextList === 'myLeadsArchive' ? 'archive' : 'tags'
            }

            if (props.prevNextList === "myLeadsFavourites" || props.prevNextList === "myLeadsTags" || props.prevNextList === "myLeadsShares" || props.prevNextList === "myLeadsUpdates") {
                payload["sort"] = searchPersist?.leadsSort?.[props?.activeTab]?.[searchPersist.leadsAppliedContext[props?.activeTab]]?.value
            }

            if (props.prevNextList === "myLeadsTags") {
                payload["tag"] = myleads.tagId
            }
        }
        selectorControllerArchive(payload, myleads, searchPersist, dispatch, settings)
        handleCancelArchive()
    }

    const handleCancelArchive = () => {
        setBooleanData(prev => ({
            ...prev,
            isModalOpen: false
        }))
    }

    const handleUnArchive = () => {
        const payload = {
            offset: 0,
            limit: 50,
            listName: props.prevNextList,
            archive: false,
            object_type: 'project'
        }
        if (props.prevNextList !== 'myLeadsHistory') {
            payload['selected_records'] = parseInt(myleads?.selectedSelectorControlValue)
            if (myleads?.includeIds?.length > 0) {
                payload['project_id'] = myleads.includeIds
                delete payload['offset']
                delete payload['limit']
                delete payload['selected_records']
            }
            if (myleads?.excludeIds?.length > 0) {
                payload['exclude_ids'] = myleads.excludeIds
            }
        } else {
            payload["project_id"] = getUniqueSelectedList()
        }

        if (payload['selected_records'] > 0) {
            if (props.prevNextList === "myLeadsUpdates" || props.prevNextList === "myLeadsFavourites" || props.prevNextList === "myLeadsArchive" || props.prevNextList === "myLeadsShares" || props.prevNextList === "myLeadsTags") {
                payload['identifier'] = props.prevNextList === "myLeadsFavourites" ? 'favourite' : props.prevNextList === 'myLeadsUpdates' ? 'updates'
                    : props.prevNextList === 'myLeadsShares' ? 'shares' : props.prevNextList === 'myLeadsArchive' ? 'archive' : 'tags'
            }

            if (props.prevNextList === "myLeadsFavourites" || props.prevNextList === "myLeadsTags" || props.prevNextList === "myLeadsShares" || props.prevNextList === "myLeadsUpdates") {
                payload["sort"] = searchPersist?.leadsSort?.[props?.activeTab]?.[searchPersist.leadsAppliedContext[props?.activeTab]]?.value
            }

            if (props.prevNextList === "myLeadsTags") {
                payload["tag"] = myleads.tagId
            }
        }
        selectorControllerArchive(payload, myleads, searchPersist, dispatch, settings)
        handleCancelArchive()
    }

    const handleArchiveClick = () => {
        setBooleanData(prev => ({
            ...prev,
            isModalOpen: !booleanData.isModalOpen,
            archive: true
        }))
    }

    const handleUnArchiveClick = () => {
        setBooleanData(prev => ({
            ...prev,
            isModalOpen: !booleanData.isModalOpen,
            archive: false
        }))
    }

    const handleTagsCancel = () => {
        dispatch(updateSelectedTagDetails({}))
        setBooleanData(prev => ({ ...prev, modal: !booleanData.modal }))
    }

    const handleTagsSubmit = () => {
        let payload = {}
        payload["selected_records"] =  parseInt(myleads?.selectedSelectorControlValue)
        payload["object_type"] = searchPersist.leadsAppliedContext[props?.activeTab]
        payload["offset"] = 0
        payload['limit'] = 50
        payload['listName'] = props.prevNextList

        if (props.prevNextList === "myLeadsUpdates" || props.prevNextList === "myLeadsFavourites" || props.prevNextList === "myLeadsShares" || props.prevNextList === "myLeadsTags") {
            payload['identifier'] = props.prevNextList === "myLeadsFavourites" ? 'favourite' : props.prevNextList === 'myLeadsUpdates' ? 'updates'
                : props.prevNextList === 'myLeadsShares' ? 'shares' : 'tags'
        }

        if (props.prevNextList === "myLeadsTags") {
            payload["tag"] = myleads.tagId
        }
        if (myleads?.includeIds?.length > 0) {
            payload['object_id'] = myleads.includeIds
            payload['selected_records'] = payload['selected_records'] - myleads?.includeIds.length
        }
        if (myleads?.excludeIds?.length > 0) {
            payload['exclude_ids'] = myleads.excludeIds
            payload["selected_records"] =payload["selected_records"]+ myleads.excludeIds.length
        }
        if (payload.object_id === undefined) {
            if (props.prevNextList === "myLeadsFavourites" || props.prevNextList === "myLeadsTags" || props.prevNextList === "myLeadsShares" || props.prevNextList === "myLeadsUpdates" || props.prevNextList === 'myLeadsHistory') {
                payload["sort"] = searchPersist?.leadsSort?.[props?.activeTab]?.[searchPersist.leadsAppliedContext[props?.activeTab]]?.value
            }
        }
           payload ["criteria"]={
            "records": 50,
            "offset": 0,
            "activeTab":  props.prevNextList === "myLeadsFavourites" ? 'favourite' : props.prevNextList === 'myLeadsUpdates' ? 'updates'
            : props.prevNextList === 'myLeadsShares' ? 'shares' : 'tags'
        }
        payload["myLeadsTab"]= props.prevNextList 
        if(payload["selected_records"] === 0){
            if( searchPersist.leadsAppliedContext[props?.activeTab]==="project"){
                payload['project_id']=payload['object_id']
            }
            else if (searchPersist.leadsAppliedContext[props?.activeTab]==="company"){
                payload['company_id']=payload['object_id']
            }
          
          
        }
 
        if (project?.selectedTagsDetails?.tagIds?.length > 1 && project?.selectedTagsDetails?.tagIds?.includes(0)) {
            let tag_name = []
            let tag_id = []
            _.map(project?.selectedTagsDetails?.tagIds, (tagId, index) => {
                if (tagId === 0) {
                    tag_name.push(project.selectedTagsDetails.tagDescriptions[index])
                } else {
                    tag_id.push(project.selectedTagsDetails.tagIds[index])
                }
            })
            if (tag_name.length) {
                payload['tag_name'] = tag_name
            }
            if (tag_id.length) {
                payload['tag_id'] = tag_id
            }
        } else if (project?.selectedTagsDetails?.tagIds?.includes(0)) {
            payload['tag_name'] = project.selectedTagsDetails.tagDescriptions
        } else {
            payload['tag_id'] = project.selectedTagsDetails.tagIds
        }

        updateMyLeadsSelectorControlTags(payload, myleads, searchPersist, dispatch)
        handleTagsCancel()
    }

    const goBack = (event) => {
        setListCount(props.prevNextList === "myLeadsUpdates" ? myleads?.updatesSearchListCount : myleads?.leadSearchListCount)
        if (search.showSelectedCompanies || search.showSelectedProjects) {
            dispatch(updateSelectedSelectorControlValue({ count: search.selectedLeadsFlag?.length, list: props.prevNextList }))
        }
        dispatch(updateShowHierarchy({ showProjectHierarchy: false, showCompanyHierarchy: false }))
        dispatch(updateHierarchyBack(true))
        dispatch(updateProjectsForCompanyId([]))
        dispatch(rolesForProject([]))
        dispatch(updatePeopleProjectsList([]))
        dispatch(updatelistOfSelectedObjectIds([]))
        dispatch(updateRemoveFilterToggle(true))
        dispatch(resetRelationalEntititySelectionCount())
        dispatch(updateObjectData(undefined))
        dispatch(updateCompanyDetail(undefined))
        dispatch(updateObjectId(undefined))
        if (project.isProjectSelected) {
            navigate(`/app/company/0/${project.objectId}`)
            dispatch(updateIsProjectSelected({ isProjectSelected: false }))
        }
    }

    let buttonClass = "",
        buttonClass2 = "",
        searchListHeaderCSS = "";

    const pagedDataRows = [1, 2, 3, 4, 5] // array to loop 5 times
    let pagedOptions = [];
    pagedDataRows.map(row => {
        pagedOptions.push((''))
    })

    const handleSelectorControlBatchPrintClick = () => {
        let objectIds = getUniqueSelectedList()
        let type = searchPersist.leadsAppliedContext[props?.activeTab]
        let feedname = type.toLowerCase() === "project" ? "Projects PDF" : "Companies PDF"
        let payload = createDownloadPdfPayload(feedname, objectIds, type, user)
        handleDropDownItemClick(0)
        createNewExport(payload, dispatch, navigate)
    }


    return (
        <>
            <div className="d-flex justify-content-start">
                {
                    (searchPersist.leadsAppliedContext[props?.activeTab] === 'project' && search.showProjectHierarchy) || (searchPersist.leadsAppliedContext[props?.activeTab] === 'company' && search.showCompanyHierarchy) ?
                        (
                            <div className={`searchList-header ${searchListHeaderCSS}`}>
                                <a onClick={goBack} color="primary" className={`show map-back`}>
                                    back
                                </a>
                            </div>
                        ) : <>
                            {(searchPersist.leadsAppliedContext[props?.activeTab] === 'company' || searchPersist.leadsAppliedContext[props?.activeTab] === 'project') ?
                                <ButtonDropdown
                                    className={`select-drop-count me-2 ${buttonClass} ${search.clusterList?.leadsFlags?.length !== 0 || myLeadsList.length === 0 ? 'invisible' : ''}`}
                                    isOpen={booleanData.dropdownOpen} toggle={() => setBooleanData(prev => ({ ...prev, dropdownOpen: !booleanData.dropdownOpen }))}>

                                    <DropdownToggle className={`${(myleads.selectedSelectorControlValue > 0) || (myleads.countOfSelectedObjectIds > 0) ? 'active' : ''}`} caret>
                                        {
                                            parseInt(myleads.selectedSelectorControlValue) > 0 ? (
                                                <p><Icon color="#292B2C" size={22} icon="icon-gen-tick-filled-skyblue" className="me-1" /> {myleads.selectedSelectorControlValue} selected</p>
                                            ) : <><Icon color="#292B2C" size={22} icon="icon-tick-filled-grey" className="me-1" /></>
                                        }
                                    </DropdownToggle>
                                    {
                                        <DropdownMenu className="dropdown-menu-right select-drop-list">
                                            <DropdownItem className={`${myleads.selectedSelectorControlValue.toString() == "0" ? 'active' : ''}`} onClick={() => handleItemSelect(0)}><Icon size={22} icon={`${myleads.selectedSelectorControlValue.toString() == "0" ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" /> None </DropdownItem>
                                            {listCount ? searchControlLimit && searchControlLimit.map((keyr, val) => (
                                                Object.entries(keyr).map(([key, value]) => {
                                                    return checkAvailabilityOfLimit(listCount, key) ?
                                                        (<DropdownItem className={`${myleads.selectedSelectorControlValue === key ? 'active' : ''}`}
                                                            onClick={() => handleDropDownItemClick(key)}
                                                        ><Icon size={22} icon={`${myleads.selectedSelectorControlValue === key ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" /> {value} </DropdownItem>) : ('')
                                                }))) : pagedOptions && pagedOptions.map(row => row)}
                                            {
                                                (<DropdownItem className={`${myleads.selectedSelectorControlValue === listCount ? 'active' : ''}`} onClick={() => handleDropDownItemClick(listCount)}><Icon size={22} icon={`${myleads.selectedSelectorControlValue === listCount ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" />  All </DropdownItem>)
                                            }
                                        </DropdownMenu>
                                    }
                                    <ArchiveModal
                                        isOpen={booleanData.isModalOpen}
                                        isArchive={booleanData.archive}
                                        multiple={myleads.selectedSelectorControlValue !== 1}
                                        handleArchive={handleArchive}
                                        handleCancelArchive={handleCancelArchive}
                                        handleUnArchive={handleUnArchive}
                                        isSelectorController={true}
                                    />
                                </ButtonDropdown>
                                : ("")
                            }
                        </>
                }
                {
                    (myleads.selectedSelectorControlValue.toString() != "0") ? (
                        <ButtonDropdown className={`select-actions ${buttonClass2}`} isOpen={booleanData.actionsDropDownOpen} toggle={() => setBooleanData(prev => ({ ...prev, actionsDropDownOpen: !booleanData.actionsDropDownOpen }))}>
                            <DropdownToggle caret className="active no-bg">
                                <Icon color="#292B2C" size={25} icon="icon-gen-threedot-ver" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-right action-list">

                                {((props && props.prevNextList !== 'myLeadsFavourites') && (props && props.prevNextList !== 'myLeadsArchive')) &&
                                    (<DropdownItem className="no-bg" onClick={() => handleSelectorControlActionsClick('Favourite')}>Favourite</DropdownItem>)}
                                {(props && props.prevNextList !== 'myLeadsArchive') &&
                                    (<DropdownItem  className="no-bg" onClick={() => handleSelectorControlActionsClick('Unfavourite')}>Unfavourite</DropdownItem>)}
                                {props && props.prevNextList !== 'myLeadsArchive' && (<DropdownItem className="no-bg" onClick={(event) => handleSelectorControlTagsClick(event)}>Tags</DropdownItem>)}
                                {
                                    props && props.prevNextList !== 'myLeadsArchive' && isPdfActive ? (
                                        parseInt(myleads?.selectedSelectorControlValue) <= 50 ?
                                            (
                                                <DropdownItem className="no-bg" disabled={!isExportEnabled} onClick={() => handleSelectorControlBatchPrintClick()}>Download PDF</DropdownItem>
                                            ) : (
                                                <DropdownItem disabled={!isExportEnabled} className="disabled no-bg">Download PDF{(myleads?.selectedSelectorControlValue <= MAX_PDF_DOWNLOAD_LIMIT) ? "" : " (max " + MAX_PDF_DOWNLOAD_LIMIT + ")"}</DropdownItem>
                                            )
                                    ) : ('')
                                }

                                {
                                    (settings?.preferences?.archiving_enabled && (((props?.prevNextList !== 'myLeadsArchive') && (searchPersist.leadsAppliedContext[props?.activeTab] === 'project')) || ((props.prevNextList === 'myLeadsFavourites' && searchPersist.leadsAppliedContext[props?.activeTab] === "project") || (props.prevNextList === 'myLeadsUpdates' && searchPersist.leadsAppliedContext[props?.activeTab] === "project") || (props.prevNextList === 'myLeadsHistory' && searchPersist.leadsAppliedContext[props?.activeTab] === "project") || (props.prevNextList === 'myLeadsShares' && searchPersist.leadsAppliedContext[props?.activeTab] === "project")))) ?
                                        <DropdownItem className="no-bg" onClick={handleArchiveClick}>Archive</DropdownItem>
                                        : ''
                                }
                                {
                                    props && props.prevNextList === 'myLeadsArchive' ?
                                        <DropdownItem className="no-bg" onClick={handleUnArchiveClick}>Unarchive</DropdownItem>
                                        : ''
                                }
                            </DropdownMenu>
                        </ButtonDropdown>
                    ) : ('')
                }
                {
                    <div className="button-blocks">
                        <TagsModal
                            handleTagsCancel={handleTagsCancel}
                            handleTagsSubmit={handleTagsSubmit}
                            selectedTags={project && project.selectedTagsDetails}
                            showModal={booleanData.modal}>
                        </TagsModal>
                    </div>
                }
                <div className={`searchList-header ${searchListHeaderCSS}`}>
                    {
                        (searchPersist.leadsAppliedContext[props?.activeTab] === 'project' && search.showProjectHierarchy) || (searchPersist.leadsAppliedContext[props?.activeTab] === 'company' && search.showCompanyHierarchy) ? (
                            <h1 className={`show h5 m-0 ps-3`}>Search results</h1>
                        ) : project.isProjectSelected ? (
                            <>
                                <h1 className={`show h5 m-0 ps-3`}>Projects of selected companies</h1>
                                <p className={`result-count mb-0`}>
                                    50 of All Projects
                                </p>
                            </>
                        ) : ((props && props.prevNextList !== 'myLeadsUpdates') && (props && props.prevNextList !== 'myLeadsFavourites') && (props && props.prevNextList !== 'myLeadsHistory') && (props && props.prevNextList !== 'myLeadsTags') && (props && props.prevNextList !== 'myLeadsShares') && (props && props.prevNextList !== 'myLeadsArchive')) ? (
                            <>
                                <h1 className={`show h5 m-0 ps-3`}>Search results</h1>
                                <p className={`result-count mb-0`}>
                                    {
                                        searchPersist.searchTextInfo[searchPersist.leadsAppliedContext[props?.activeTab]].length > 0 ||
                                            searchPersist && searchPersist.locationSearchObj && searchPersist.locationSearchObj[searchPersist.leadsAppliedContext[props?.activeTab]].location && searchPersist.locationSearchObj[searchPersist.leadsAppliedContext[props?.activeTab]].location.length > 0 ? (
                                            (<>{search.searchList.length} of {formatNumber(search.leadSearchListCount)}
                                                {searchPersist.leadsAppliedContext[props?.activeTab] === 'project' ? 'projects' : 'companies'}
                                                <span className="applied-miles">{searchPersist && searchPersist.locationSearchObj && searchPersist.locationSearchObj[searchPersist.leadsAppliedContext[props?.activeTab]].distance
                                                    ? `within ${searchPersist && searchPersist.locationSearchObj && searchPersist.locationSearchObj[searchPersist.leadsAppliedContext[props?.activeTab]].distance} 
                                            ${searchPersist && searchPersist.locationSearchObj && searchPersist.locationSearchObj[searchPersist.leadsAppliedContext[props?.activeTab]].distance === 1 ? "mile" : "miles"}` : ""}</span></>)
                                        ) : ((searchPersist.leadsAppliedContext[props?.activeTab] === 'company' || searchPersist.leadsAppliedContext[props?.activeTab] === 'project') &&
                                            searchPersist.searchTextInfo[searchPersist.leadsAppliedContext[props?.activeTab]].length > 0) ?
                                            (<>{search.searchList.length} of {search.leadSearchListCount} {' '} {searchPersist.leadsAppliedContext[props?.activeTab] === 'project' ? 'projects' : 'companies'}</>)
                                            : (
                                                (<>{search.searchList.length} of All {searchPersist.leadsAppliedContext[props?.activeTab] === 'project' ? 'projects' : 'companies'}</>)
                                            )
                                    }
                                </p>
                            </>
                        ) : ('')
                    }
                </div>
            </div>
        </>
    )
}

export default MyLeadsSearchSelectorDropdown