import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup } from 'reactstrap'
import ColoredScrollbars from '../../common/js/coloredScrollbars'

import CompanyList from '../../list/companyList/companylist'
import ProjectList from '../../list/projectList/projectlist'
import { fetchSearchListCount } from './apiclient'
import { preProcessorQuery } from './filters/preProcessorQuery'
import { updateOffsetLimit } from './filters/redux/reducer'
import { renderListHeader } from './utils'
import { fetchSortValue } from '../../../utils/commonFunctions'

const SearchList = (props) => {
    const search = useSelector((state) => state.justAsk)
    const project = useSelector((state) => state.project)
    const searchPersist = useSelector((state) => state.justAskPersist)
    const dispatch = useDispatch()
    const [buttonClicked, setButtonClicked] = useState(false)
    const settings = useSelector((state) => state.settings)
    const filter = useSelector((state) => state.justAskFilter)

    useEffect(() => {
        if (buttonClicked) {
            var formValues
            let type = searchPersist.appliedContext === "project" ? 'company' : 'project'
            let newSortOrder = fetchSortValue(searchPersist?.defaultSort[searchPersist?.appliedContext]?.label, type)

            formValues = preProcessorQuery('', filter.filtersState, type, search, searchPersist)
            formValues['sort'] = newSortOrder

            if (searchPersist?.appliedContext === 'project' && search?.idsForShowRelatedEntities?.length > 0) {
                formValues.query.project_id = {
                    operator: '=',
                    value: search?.idsForShowRelatedEntities
                };
            }

            if (searchPersist?.appliedContext === 'company' && search?.idsForShowRelatedEntities?.length > 0) {
                formValues.query.company_id = {
                    operator: '=',
                    value: search?.idsForShowRelatedEntities
                };
            }
            fetchSearchListCount(formValues, dispatch, search, settings, searchPersist.query);
            setButtonClicked(false)
        }
    }, [buttonClicked]);

    const renderListItems = () => {
        return (
            <>
                {
                    !project.isProjectSelected ?
                        (searchPersist.appliedContext === "project" ?
                            <li className="list-group-item">
                                <CompanyList
                                    entityFlag={props.entityFlag} prevNextList={props?.prevNextList} myHoverList={props.myHoverList}
                                />
                            </li>
                            :
                            <li className="list-group-item">
                                <ProjectList entityFlag={props.entityFlag} prevNextList={props?.prevNextList} myHoverList={props.myHoverList} />
                            </li>) : (
                            <li className="list-group-item">
                                <ProjectList entityFlag={props.entityFlag} prevNextList={props?.prevNextList} myHoverList={props.myHoverList} />
                            </li>
                        )
                }
            </>
        )
    }

    const handleShowMore = () => {
        setButtonClicked(true)
        let type = searchPersist.appliedContext === "project" ? 'company' : 'project'
        let newOffset = filter.filtersState[type].offset + filter.filtersState[type].records;
        // dispatch(updateOffsetLimitValue({ offset: newOffset, records: search.offsetLimitValue.records }));
        dispatch(updateOffsetLimit({ offset: newOffset, records: filter.filtersState[type].records, type: type }));
    }

    const renderShowMore = () => {
        return (
            <div className='d-flex justify-content-center pt-3 pb-3 showmore-result'>
                <button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</button>
            </div>
        )
    }

    return (<>
        <div className={`result-wrapper ${!searchPersist.isListView ? 'gridSearchView' : ''}`}>
            <div className="result-list-container content-panel">
                <div className="result-list-wrapper">
                    <div className="simpleListSearch">
                        <div className={`listviewtable-block bggray leftright-bothspace mb- ${searchPersist.isListView ? "" : "d-none"}`}>
                            <div className="list-table">
                                <div className="list-header d-flex">
                                    {renderListHeader(searchPersist.appliedContext, searchPersist, true, search)}
                                </div>
                            </div>
                        </div>
                        <div className={`searchlist-wrapper ${searchPersist.appliedContext === 'company' && searchPersist.isListView || searchPersist.appliedContext === 'project' && searchPersist.isListView && search.showSelectedCompanies ? 'JScompany' : '' } ${(searchPersist.appliedContext === 'project' && filter.filtersState[searchPersist.appliedContext]?.projectInformation?.active
                            || searchPersist.appliedContext === 'company' && filter.filtersState[searchPersist.appliedContext]?.companyInformation?.active) && filter.searchMode === 'basic' ? 'with-project-info' : ''}`}>
                            <ColoredScrollbars>
                                <ListGroup>
                                    {renderListItems()}
                                    {search.listOfSelectedObjectIds && search.listOfSelectedObjectIds.length !== search.listOfSelectedObjectIdsCount ? renderShowMore() : ''}
                                </ListGroup>
                            </ColoredScrollbars>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default SearchList