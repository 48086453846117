import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import ProjectRow from './projectRow';
import { groupNotesbyUpdatedDate } from '../../../utils';

const ProjectList = (props) => {
    const search = useSelector((state) => state.search)
    const justask = useSelector((state) => state.justAsk)
    const myleads = useSelector((state) => state.myleads)
    const searchPersist = useSelector((state) => state.searchPersist)
    const [myLeadsListUpdatedDate, setMyLeadsListUpdatedDate] = useState([])

    useEffect(() => {
        if (props?.prevNextList === "myLeadsUpdates" && !myleads?.isListView) {
            setMyLeadsListUpdatedDate(groupNotesbyUpdatedDate(myleads?.updatesSearchList?.length > 0 ? myleads?.updatesSearchList : [], props?.prevNextList))
        } else if (props?.prevNextList === 'myLeadsHistory' && searchPersist?.leadsSort?.history?.[searchPersist?.leadsAppliedContext?.history]?.label.toLowerCase() === "last viewed" && !myleads?.isListView) {
            setMyLeadsListUpdatedDate(groupNotesbyUpdatedDate(myleads?.searchList?.length > 0 ? myleads?.searchList : [], props?.prevNextList))
        } else {
            setMyLeadsListUpdatedDate([])
        }
    }, [myleads?.searchList, myleads?.isListView, myleads?.updatesSearchList])

    function renderProjectRow(project, index, isShowRelatedEntitiesMode, isHierarchy = false) {
        return (
            <li className="list-group-item" id={project.project_id} key={index}>
                <ProjectRow
                    mode={props.mode}
                    project={project}
                    prevNextList={props.prevNextList}
                    isShowRelatedEntitiesMode={isShowRelatedEntitiesMode}
                    index={index}
                    myHoverList={props.myHoverList}
                    isHierarchy={isHierarchy}
                    activeTab={props.activeTab}
                />
            </li>
        );
    }

    function renderList(projects, isShowRelatedEntitiesMode, isHierarchy = false) {
        return projects.map((project, index) =>
            renderProjectRow(project, index, isShowRelatedEntitiesMode, isHierarchy)
        );
    }

    function renderSectionBasedOnList(list, isShowRelatedEntitiesMode, isHierarchy = false) {
        return list && list.length !== 0 ? renderList(list, isShowRelatedEntitiesMode, isHierarchy) : '';
    }

    return (
        <>
            {props?.prevNextList === 'searchList' || props?.prevNextList === 'selectedSearchList' ? (
                props.entityFlag ? (
                    renderSectionBasedOnList(search?.listOfSelectedObjectIds, true)
                ) : (
                    renderSectionBasedOnList(search?.clusterList?.leadsFlags, false, true) ||
                    renderSectionBasedOnList(search?.searchList, false)
                )
            ) : props?.prevNextList === "jAskSearchList" || props?.prevNextList === "selectedjAskSearchList" ? (
                props.entityFlag ? (
                    renderSectionBasedOnList(justask?.listOfSelectedObjectIds, true)
                ) : (
                    renderSectionBasedOnList(justask?.clusterList?.leadsFlags, false, true) ||
                    renderSectionBasedOnList(justask?.searchList, false)
                )
            ) : props?.prevNextList === 'groupedCompanyProjects' ? (
                renderSectionBasedOnList(search?.groupCompanyProjects, true)
            ) : props?.prevNextList === 'JAskGroupedCompanyProjects' ? (
                renderSectionBasedOnList(justask?.groupCompanyProjects, true)
            ) : (myLeadsListUpdatedDate.length !== 0 && props?.prevNextList === 'myLeadsUpdates' && !myleads?.isListView) ? (
                    Object.entries(myLeadsListUpdatedDate).map(([key, array]) => (
                        <div className="note-listing pb-2" key={key}>
                            <span className="update-date">{key}</span>
                            {renderList(array, props.entityFlag)}
                        </div>
                    ))
                ) : (myLeadsListUpdatedDate.length !== 0 && props?.prevNextList === 'myLeadsHistory' && searchPersist?.leadsSort?.history?.[searchPersist?.leadsAppliedContext?.history]?.label.toLowerCase() === "last viewed") ? (
                        Object.entries(myLeadsListUpdatedDate).map(([key, array]) => (
                            <div className="note-listing pb-2" key={key}>
                                <span className="update-date">{key}</span>
                                {renderList(array, props.entityFlag)}
                            </div>
                        ))
                    ) : renderSectionBasedOnList(search?.clusterList?.leadsFlags, props.entityFlag) ||
                    renderSectionBasedOnList(myleads?.searchList, props.entityFlag) ||
                    renderSectionBasedOnList(myleads?.updatesSearchList, props.entityFlag)
            }
        </>
    );
}

export default ProjectList