import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Input } from 'reactstrap';

import TagShareNoteCount from '../../details/list/tagShareNoteCount';
import { updateObjectData, updateIsSelectedToSpecificRow, updateShowHierarchy, updateBackObjectId, updateNavigationBack } from '../../search/redux/reducer';
import { checkIfProjectIsNew, displayCurrency, getFormatedDate, getFormatedStartOrEndDate, getOnsiteBadge, isValidText } from '../../../utils';
import { favourite } from '../../details/apiclient';
import { convertMilesToYards } from '../../search/utils';
import Icon from '../../common/js/icon';
import { getRoles } from '../../../utils/commonFunctions';
import { updateIsSelectedToSpecificRowMyLeads, updateMyLeadsShowHierarchy } from '../../myleads/reducer';
import { fetchUpdatesResultsbyID } from '../../myleads/apiclient';
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import { summaryActivityDetail, updateActivityIsClicked } from '../../details/redux/reducer';
import { MYLEADS_PREVNEXT_HIERARCHY_LIST, MYLEADS_PREVNEXT_LIST } from '../../../utils/settings';
import { TOURPROJECTLISTVIEW } from '../../../utils/tour';
import { fetchRolesForProjects } from '../../search/apiclient';
import { updateIsSelectedToSpecificRowForJustAsk, updateNavigationBackJA, updateObjectDataJA, updateShowHierarchyForJustAsk, updateBackObjectId as jAskupdateBackObjectId } from '../../justask/search/redux/reducer';
import { fetchRolesForProjectsJA } from '../../justask/search/apiclient';

const ProjectRow = (props) => {
    let ifMyleadsExist = MYLEADS_PREVNEXT_LIST.includes(props.prevNextList) ? true : false
    let ifMyleadsHierarchyExist = MYLEADS_PREVNEXT_HIERARCHY_LIST.includes(props.prevNextList) ? true : false
    const search = useSelector((state) => state.search)
    const justask = useSelector((state) => state.justAsk)
    const searchPersist = useSelector((state) => state.searchPersist)
    const justAskPersist = useSelector((state) => state.justAskPersist)
    const settings = useSelector((state) => state.settings)
    const myleads = useSelector((state) => state.myleads)
    const [showMore, setShowMore] = useState();
    const userData = useSelector((state) => state.user)
    const filter = useSelector((state) => state.filter)
    const [searchListView, setSearchListView] = useState(searchPersist?.isListView)
    const [justAskListView, setJustAskListView] = useState(justAskPersist?.isListView)
    const [myLeadsListView, setMyLeadsListView] = useState(myleads?.isListView)
    const [allUpdateCount, setAllUpdateCount] = useState(props?.project?.update_count)
    const [allUpdateList, setAllUpdateList] = useState([])
    const [currentID, setCurrentId] = useState(null)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    let location = useLocation()

    const activeFilterValue = (props.prevNextList === 'myLeadsTags' || props.prevNextList === 'myLeadsUpdates' || props.prevNextList === 'myLeadsFavourites' || props.prevNextList === 'myLeadsHistory' || props.prevNextList === 'myLeadsShares' || props.prevNextList === 'myLeadsArchive') ? searchPersist?.leadsAppliedContext[props?.activeTab] : props?.prevNextList === "jAskSearchList" || props?.prevNextList === "selectedjAskSearchList" ? justAskPersist.appliedContext : searchPersist?.appliedContext
    let isLeagueTable = props.prevNextList === 'companyHierarchicalLeagueTableList'

    useEffect(() => {
        let page = document.getElementsByTagName('body')[0];
        if (search.backObjectId && search.backObjectId !== undefined && search.backObjectId !== 0) {
            let elmnt = document.getElementById(search.backObjectId);
            if (elmnt && elmnt != undefined) {
                elmnt.scrollIntoView()
                page.classList.add('noscroll');
                dispatch(updateBackObjectId(undefined))
            }
            else {
                page.classList.remove('noscroll');
            }
        }
    }, [search.backObjectId])

    useEffect(() => {
        let page = document.getElementsByTagName('body')[0];
        if (justask.backObjectId && justask.backObjectId !== undefined && justask.backObjectId !== 0) {
            let elmnt = document.getElementById(justask.backObjectId);
            if (elmnt && elmnt != undefined) {
                elmnt.scrollIntoView()
                page.classList.add('noscroll');
                dispatch(jAskupdateBackObjectId(undefined))
            }
            else {
                page.classList.remove('noscroll');
            }
        }
    }, [justask.backObjectId])

    const handleRolesForProjectId = (id) => {
        if (props?.prevNextList === "jAskSearchList") {
            fetchRolesForProjectsJA(id, dispatch)
            dispatch(updateObjectDataJA(props.project))
            dispatch(updateNavigationBackJA('relationClick'))
        }
        else {
            fetchRolesForProjects(id, dispatch)
            dispatch(updateObjectData(props.project))
            dispatch(updateNavigationBack('relationClick'))
        }

        if (ifMyleadsExist) {
            dispatch(updateMyLeadsShowHierarchy({ showProjectHierarchy: true }))
        }
        else if (props?.prevNextList === 'jAskSearchList') {
            dispatch(updateShowHierarchyForJustAsk({ showProjectHierarchy: true, showCompanyHierarchy: false }))

        }
        else {
            dispatch(updateShowHierarchy({ showProjectHierarchy: true, showCompanyHierarchy: false }))
        }
    }

    const handleFavourite = (event, project) => {
        event.preventDefault()
        event.stopPropagation()
        let ifMyleadsFavExist = (props.prevNextList === 'myLeadsTags' || props.prevNextList === 'myLeadsUpdates' || props.prevNextList === 'myLeadsFavourites' || props.prevNextList === 'myLeadsHistory' || props.prevNextList === 'myLeadsShares' || props.prevNextList === 'myLeadsArchive') ? true : false
        let searchListFav = ifMyleadsFavExist ? 'myLeadsFav' : props?.prevNextList === "jAskSearchList" || props?.prevNextList === "selectedjAskSearchList" || props.prevNextList === 'projectHierarchicalSearchListJA' || props.prevNextList === 'companyHierarchicalSearchListJA' ? "jaskListFav" : 'searchListFav'
        let payload = {
            favourite: project && project.favourite ? false : true,
            objectId: project && project.project_id,
            objectType: 'project',
        }
        favourite(payload, dispatch, ifMyleadsFavExist ? myleads : props?.prevNextList === "jAskSearchList" || props?.prevNextList === "selectedjAskSearchList" || props.prevNextList === 'projectHierarchicalSearchListJA' || props.prevNextList === 'companyHierarchicalSearchListJA' ? justask : search, userData, searchListFav, settings, null, null, null, props.prevNextList)
    }

    const handleDetails = (event, projectId) => {
        event.preventDefault()
        dispatch(updateActivityIsClicked(false))
        dispatch(summaryActivityDetail("tags"))
        let routingState = {
            prevNextList: props.prevNextList,
            showMoreResult: true,
            index: props.index,
            lastRelease: getFormatedDate(props.project.project_last_published, 'DD-MMM-YYYY'),
            dist: convertMilesToYards(props.project.project_distance),
            list: props.prevNextList === 'searchList' ? search?.searchList : props?.prevNextList === "jAskSearchList" ? justask?.searchList
                : props.prevNextList === 'selectedSearchList' ? search?.listOfSelectedObjectIds : props?.prevNextList === "selectedjAskSearchList" ? justask.listOfSelectedObjectIds
                    : props.prevNextList === 'projectHierarchicalSearchList' || ifMyleadsHierarchyExist ? [search.projectRowData] : props.prevNextList === 'projectHierarchicalSearchListJA' ? [justask.projectRowData]
                        : ['companyHierarchicalSearchList', 'companyHierarchicalLeagueTableList'].includes(props.prevNextList) ? search.projectsForCompanyId
                            : props.prevNextList === 'companyHierarchicalSearchListJA' ? justask.projectsForCompanyId
                                : props.prevNextList === 'contactHierarchicalSearchList' ? search.peopleProjectsList
                                    : props.prevNextList === 'groupedCompanyProjects' ? search.groupCompanyProjects
                                        : props.prevNextList === 'jAskGroupedCompanyProjects' ? justask.groupCompanyProjects
                                            : ifMyleadsExist ? props?.prevNextList === "myLeadsUpdates" ? myleads.updatesSearchList
                                                : myleads.searchList : [],
            activeTab: props.prevNextList === 'myLeadsUpdates' ? '3' : '1',
            previousState: location.state
        }
        navigate(`/app/project/${projectId}`, { state: routingState })
    }

    const handleCheckboxChange = (value) => {
        let payload = {
            id: value,
            isSelected: !props.project.is_selected,
            filterType: 'project',
            isShowRelatedEntitiesMode: props.isShowRelatedEntitiesMode,
            entityType: props.prevNextList
        }
        if (props.prevNextList === 'searchList' || props.prevNextList === 'selectedSearchList') {
            dispatch(updateIsSelectedToSpecificRow(payload))
        }
        else if (props.prevNextList === "jAskSearchList" || props?.prevNextList === "selectedjAskSearchList") {
            dispatch(updateIsSelectedToSpecificRowForJustAsk(payload))
        }
        else {
            dispatch(updateIsSelectedToSpecificRowMyLeads(payload))
        }
    }

    const toggleShowMore = (id) => {
        setShowMore(!showMore);
        setCurrentId(id)
        fetchUpdatesResultsbyID(id, searchPersist, dispatch);
    }

    function getFullName() {
        let fullName = '';
        if (props.project.person_first_name) {
            fullName = `${props.project.person_first_name}`
        }
        if (props.project.person_last_name) {
            fullName = `${fullName} ${props.project.person_last_name}`
        }

        return fullName
    }

    const mouseEvent = (data) => {
        if (props.myHoverList != undefined) {
            props.myHoverList(data);
        }
    }

    useEffect(() => {
        if (ifMyleadsExist) {
            if (myleads.isListView || props?.prevNextList === 'myLeadsArchive') {
                setSearchListView(false)
                setMyLeadsListView(true)
            } else {
                setMyLeadsListView(false)
                setSearchListView(false)
            }
        } else {
            if (searchPersist.isListView) {
                setMyLeadsListView(false)
                setSearchListView(true)
            }
            else if (justAskPersist.isListView) {
                setJustAskListView(true)
                setSearchListView(false)
            }
            else {
                setMyLeadsListView(false)
                setSearchListView(false)
            }
        }

    }, [searchPersist.isListView, myleads.isListView, justAskPersist.isListView])

    useEffect(() => {
        let newarray = [];
        if (currentID !== null && newarray.length === 0) {
            if (myleads.myLeadsAllUpdates.length > 1) {
                newarray = myleads?.myLeadsAllUpdates && myleads?.myLeadsAllUpdates.filter(elem => elem.objectId === currentID);
            }
            else {
                newarray = myleads.myLeadsAllUpdates
            }
        }
        setAllUpdateList(newarray)

    }, [myleads.myLeadsAllUpdates, currentID])

    return (
        <div className={`${props?.isHierarchy ? 'project-show-roles' : ""} ${props?.project?.read ? '' : 'read-block'} position-relative`}>
            {props?.isHierarchy || (search.showSelectedProjects && activeFilterValue === 'people') || justask?.showSelectedProjects && activeFilterValue === "people" ? '' :
                <label className='container'>
                    <Input
                        value={props.project.project_id}
                        id={props.project.project_id}
                        type="checkbox" onChange={() => handleCheckboxChange(props.project.project_id)}
                        checked={props.project.is_selected}
                    />
                    <span className="checkmark" for={props.project.project_id}></span>
                </label>
            }
            <div id={props.project.project_id} className={`project-container ${props.isHierarchy ? 'ps-0' : ""} ${searchPersist.isListView || props?.prevNextList === 'myLeadsArchive' ? '' : 'mapViewRow'} ${props.isHierarchy && !searchPersist.isListView ? 'ms-0' : ''}`}>
                {
                    search.showSelectedProjects && activeFilterValue === 'company' || justask.showSelectedProjects && activeFilterValue === 'company' || (props && props.isHierarchy) || (props && props.prevNextList === 'myLeadsUpdates') || (props && props.mode === 'myLeadsUpdatesMapView') || (search.showSelectedProjects && activeFilterValue === 'people') || (justask.showSelectedProjects && activeFilterValue === "people") ? '' :
                        <Button className="related-Company-btn" onClick={() => handleRolesForProjectId(props.project.project_id)}></Button>
                }
                {

                    props.project.update_count >= 2 ? (
                        <Button className={`toggleButtonAllUpdate ${showMore ? 'updateDetailsEnable' : ''}`} onClick={() => toggleShowMore(props.project.project_id)}></Button>
                    ) : ""
                }
                {props.index == 0 && location.pathname == "/app/list" ?
                    (<div className={`hori-placeholder-tour ${!searchPersist.isListView ? "left-right-zero" : ""}`} data-intro={!filter.isFilterActive ? TOURPROJECTLISTVIEW.listview.intro : null} data-position={TOURPROJECTLISTVIEW.listview.position} data-class={TOURPROJECTLISTVIEW.listview.class}>&nbsp;</div>) :
                    ('')}
                <div className="linkWrapper">
                    <Link onMouseOver={() => mouseEvent(props.project)} onMouseOut={() => mouseEvent({})} className={`result-block ${props.isHierarchy && props.isHierarchy === true ? 'nestedRoles' : ''}`} data-toggle="collapse" data-parent="#searchlistAccordion" aria-expanded="false" aria-controls={props.project.project_id} onClick={(event) => { handleDetails(event, props.project.project_id) }} to={`/app/project/${props.project.project_id}`}>
                        <div>
                            {
                                searchListView && location.pathname == "/app/list" || myLeadsListView && location.pathname == "/app/myleads" || isLeagueTable && location.pathname == "/app/leaguetable" || justAskListView && location.pathname == "/app/justask" ?
                                    <div className="listviewtable-block">
                                        <div className="list-table">
                                            <div className="list-tablerow d-flex">
                                                <div className="list-col list-name w-25">
                                                    <div className="listNameIcon">
                                                        <div className="result-row-icon-placeholder">
                                                            <span className="mb-0 icon-project"><Icon size={35} icon='icon-gen-project-list' /></span>
                                                            <div className="result-row-button-block ml-2">
                                                                <div className="right-block">
                                                                    <div className="">
                                                                        <button onClick={(e) => handleFavourite(e, props.project)} className='user-ctrl-btn label-favourite-icon'>
                                                                            {
                                                                                (<>
                                                                                    {
                                                                                        props.project && props.project.favourite ?
                                                                                            <Icon color="#053874" size={20} icon='icon-gen-star-active' /> :
                                                                                            <Icon color="#053874" size={20} icon='icon-gen-star-inactive' />
                                                                                    }
                                                                                </>
                                                                                )
                                                                            }
                                                                            {
                                                                            }
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h5 className="result-title-name mb-0 text-wrap">
                                                            <strong className="me-2">{props.project.project_title}</strong>
                                                            {
                                                                props.project.archive ?
                                                                    <div className="archive-badge-flag mt-3 ">
                                                                        Archived
                                                                    </div> : ('')
                                                            }
                                                        </h5>
                                                        {
                                                            props.project.tags_count && props.project.tags_count != 0 ||
                                                                props.project.shares_count && props.project.shares_count != 0 ||
                                                                props.project && props.project.mail && props.project.mailed_on ||
                                                                props.project.notes_count && props.project.notes_count != 0 ?
                                                                (<TagShareNoteCount entity={props.project} />) : ("")
                                                        }
                                                    </div>
                                                </div>
                                                <div className="list-col list-client list-flag w-10 pt-2 text-left">
                                                    {
                                                        checkIfProjectIsNew(props.project.project_last_published, props.project.project_first_published) ?
                                                            <div className="new-pro-flag mr-1">
                                                                New
                                                            </div> : ('')
                                                    }
                                                    {
                                                        props?.project?.project_stopped_display ?
                                                            <div className="project-build-phase-pro-flag on-hold-flag ms-2"> {props.project.project_stopped_display}</div>
                                                            :
                                                            'project_site_progression' in props.project &&
                                                                props.project.project_build_phase &&
                                                                (props.project.project_build_phase == "active_on_site" ||
                                                                    props.project.project_build_phase == "on_site") ?
                                                                <div className="project-build-phase-pro-flag ms-2">
                                                                    {getOnsiteBadge(props.project.project_site_progression, props.project.project_build_phase)}
                                                                </div> : ('')
                                                    }
                                                </div>
                                                <div className="list-col list-client w-15">
                                                    <span className="rs-client-name">
                                                        {props.project.role_11_company_names ? getRoles(props.project.role_11_company_names) : '-'}
                                                    </span>
                                                </div>
                                                {props.project.person_first_name && (search.showSelectedProjects && activeFilterValue === 'people') || props.project.person_last_name && (search.showSelectedProjects && activeFilterValue === 'people') ?
                                                    <div className={`list-col list-contact-name w-15`}>
                                                        <h5 className="result-title-name pl-0 mb-1">{getFullName()}</h5>
                                                    </div> : ''
                                                }
                                                <div className="list-col list-value w-10">
                                                    {isValidText(props.project.project_value) ?
                                                        (props.project.project_value && props.project.project_value == '-') ? ('') : (<span
                                                            className="rs-value ">{displayCurrency(props.project.project_value, props.project.project_value_estimated)}</span>) : ('')
                                                    }
                                                </div>
                                                <div className="list-col list-startDate w-10">
                                                    <span className="src-date">{getFormatedStartOrEndDate(props.project.project_start_display)}</span>
                                                </div>
                                                <div className="list-col list-endDate w-10">
                                                    <span className="src-date">{getFormatedStartOrEndDate(props.project.project_finish_display)}</span>
                                                </div>
                                                {props.prevNextList !== 'myLeadsUpdates' ?
                                                    <>
                                                        <div className="list-col list-value w-10">
                                                            {isValidText(props?.project?.project_planning_stage_display) && isValidText(props?.project?.project_contract_stage_display) ?
                                                                (<span>{props?.project?.project_planning_stage_display == "-" ? null : props?.project?.project_planning_stage_display + ", "}</span>) :
                                                                (<span>{props?.project?.project_planning_stage_display == "-" ? null : props?.project?.project_planning_stage_display}</span>)}
                                                            {isValidText(props?.project?.project_contract_stage_display) ? (<span>{props?.project?.project_contract_stage_display}</span>) : ('')}
                                                        </div>
                                                        <div className="list-col list-location w-10">{isValidText(props.project.project_site3) ? (
                                                            <span>{props.project.project_site3}</span>) : ('')}
                                                        </div>
                                                    </> :
                                                    <>
                                                        <div className="list-col list-location w-10">{isValidText(props.project.project_site3) ? (
                                                            <span>{props.project.project_site3}</span>) : ('')}
                                                        </div>
                                                        <div class="list-col list-contract w-15">
                                                            <span>{props.project.description} </span>
                                                            <span className="updatesTxt">{props.project.update_count ? props.project.update_count - 1 == 0 ? '' : '+' + (props.project.update_count - 1) + ' more' : ''} </span>

                                                        </div>
                                                    </>
                                                }

                                                {
                                                    activeFilterValue === 'project' ?
                                                        (searchPersist.defaultSort[activeFilterValue].label === 'nearest' && !search.showSelectedProjects && props?.project?.project_distance) || (justAskPersist.defaultSort[activeFilterValue].label === 'nearest' && !justask.showSelectedProjects && props?.project?.project_distance) ? (
                                                            <div className={`list-col list-contract w-10`}>
                                                                {props && props.project && props.project.project_distance ?
                                                                    (<span className={`src-date`}><span className="src-latest">{convertMilesToYards(props && props.project && props.project.project_distance)}</span></span>) :
                                                                    ""}
                                                            </div>
                                                        ) : (
                                                            <div className="list-col list-updated w-10">
                                                                <span className="src-date">
                                                                    {
                                                                        props.project.updated_on && props.prevNextList === 'myLeadsUpdates' ?
                                                                            (<span>{getFormatedDate(props.project.updated_on, 'DD-MMM-YYYY')}</span>) :
                                                                            props && props.project.activity_date && props.prevNextList === 'myLeadsHistory' && searchPersist?.leadsSort[props?.activeTab]?.[searchPersist?.leadsAppliedContext?.[props?.activeTab]].label === 'last viewed' ?
                                                                                (<span className="src-latest">{getFormatedDate(props.project.activity_date, 'DD-MMM-YYYY')}</span>) :
                                                                                props.project.project_last_published ?
                                                                                    (<span>{getFormatedDate(props.project.project_last_published, 'DD-MMM-YYYY')}</span>) : ""

                                                                    }
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div className="list-col list-updated w-10">
                                                                <span className="src-date">
                                                                    {
                                                                        props.project.project_last_published ?
                                                                            (<span>{getFormatedDate(props.project.project_last_published, 'DD-MMM-YYYY')}</span>) : ("")
                                                                    }
                                                                </span>
                                                            </div>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    (
                                        <div>
                                            <div className="result-row-icon-placeholder">
                                                <span className="mb-0 icon-project"><Icon size={35} icon='icon-gen-project-list' /></span>
                                                <button onClick={(e) => handleFavourite(e, props.project)} className='user-ctrl-btn label-favourite-icon'>
                                                    {
                                                        (<>
                                                            {
                                                                props.project && props.project.favourite ?
                                                                    <Icon color="#053874" size={20} icon='icon-gen-star-active' /> :
                                                                    <Icon color="#053874" size={20} icon='icon-gen-star-inactive' />
                                                            }
                                                        </>
                                                        )
                                                    }
                                                    {
                                                    }
                                                </button>

                                            </div>
                                            <div className="result-row-overview">
                                                <h5 className="result-title-name mb-1">
                                                    {props.project.project_title}
                                                    {
                                                        props.project.archive ?
                                                            <div className="archive-badge-flag mt-3 ">
                                                                Archived
                                                            </div> : ('')
                                                    }
                                                </h5>
                                                {props.prevNextList !== 'myLeadsUpdates' ?
                                                    <>
                                                        <div className="sub-resultdetails-content">
                                                            <div className="result-client-name-value">
                                                                <span className="rs-client-name">
                                                                    {props.project.role_11_company_names ? getRoles(props.project.role_11_company_names) : '-'}
                                                                </span>
                                                                {isValidText(props.project.project_value) ?
                                                                    (props.project.project_value && props.project.project_value == '-') ? ('') : (<span
                                                                        className="rs-value ">{displayCurrency(props.project.project_value, props.project.project_value_estimated)}</span>) : ('')
                                                                }

                                                                {isValidText(props?.project?.project_planning_stage_display) && isValidText(props?.project?.project_contract_stage_display) ?
                                                                    (<span>{props?.project?.project_planning_stage_display == "-" ? null : props?.project?.project_planning_stage_display + ", "}</span>) :
                                                                    (<span>{props?.project?.project_planning_stage_display == "-" ? null : props?.project?.project_planning_stage_display}</span>)}
                                                                {isValidText(props?.project?.project_contract_stage_display) ? (<span>{props?.project?.project_contract_stage_display}</span>) : ('')}
                                                            </div>

                                                        </div>
                                                        {isValidText(props.project.project_site3) ?
                                                            <span className="src-location-placeholder">
                                                                {props.project.project_site3}
                                                            </span> : ("")
                                                        } </>
                                                    : <div className="sub-resultdetails-content">
                                                        {isValidText(props.project.description) ?
                                                            <span>
                                                                {props.project.description}
                                                            </span> : ("")
                                                        }
                                                        <span className="updatesTxt">{props.project.update_count ? props.project.update_count - 1 == 0 ? '' : '+' + (props.project.update_count - 1) + ' more' : ''} </span>


                                                    </div>
                                                }

                                                {
                                                    (searchPersist?.defaultSort[activeFilterValue]?.label === 'nearest' && !search.showSelectedProjects && props.project?.project_distance) || (justAskPersist?.defaultSort[activeFilterValue]?.label === 'nearest' && !justask.showSelectedProjects && props.project?.project_distance) ? (
                                                        props.project?.project_distance ?
                                                            (<span className='src-date'><span className="src-miles"><Icon color="#292B2C" size={20} icon='icon-gen-arrow-cross' className="me-1" />{convertMilesToYards(props.project?.project_distance)}</span></span>) :
                                                            ""
                                                    ) :
                                                        search?.showSelectedProjects && searchPersist?.heirarchialSort["project"]?.label === 'start date' && props?.prevNextList !== 'myLeadsFavourites' && props?.prevNextList !== 'myLeadsHistory' && props?.prevNextList !== 'myLeadsShares' || (justask?.showSelectedProjects && justAskPersist?.heirarchialSort["project"]?.label === 'start date' && props?.prevNextList !== 'myLeadsFavourites' && props?.prevNextList !== 'myLeadsHistory' && props?.prevNextList !== 'myLeadsShares') ? (<span className='src-date'><span className={`rs-start-date`}>{getFormatedStartOrEndDate(props.project?.project_start_display)}</span></span>)
                                                            : searchPersist?.defaultSort[activeFilterValue]?.label === 'start date' && props?.prevNextList !== 'myLeadsFavourites' && props?.prevNextList !== 'myLeadsHistory' && props?.prevNextList !== 'myLeadsShares' || justAskPersist?.defaultSort[activeFilterValue]?.label === 'start date' && props?.prevNextList !== 'myLeadsFavourites' && props?.prevNextList !== 'myLeadsHistory' && props?.prevNextList !== 'myLeadsShares' ? (<span className='src-date'><span className={`rs-start-date`}>{getFormatedStartOrEndDate(props.project?.project_start_display)}</span></span>)
                                                                :
                                                                searchPersist?.leadsSort[props?.activeTab]?.[searchPersist?.leadsAppliedContext?.[props?.activeTab]].label === 'start date' && props?.prevNextList === 'myLeadsFavourites' || searchPersist?.leadsSort[props?.activeTab]?.[searchPersist?.leadsAppliedContext?.[props?.activeTab]].label === 'start date' && props?.prevNextList === 'myLeadsHistory' || searchPersist?.leadsSort[props?.activeTab]?.[searchPersist?.leadsAppliedContext?.[props?.activeTab]].label === 'start date' && props?.prevNextList === 'myLeadsShares' ? (<span className='src-date'><span className={`rs-start-date`}>{getFormatedStartOrEndDate(props.project?.project_start_display)}</span></span>)

                                                                    :
                                                                    search?.showSelectedProjects && searchPersist?.heirarchialSort["project"]?.label === 'end date' && props?.prevNextList !== 'myLeadsFavourites' && props?.prevNextList !== 'myLeadsHistory' && props?.prevNextList !== 'myLeadsShares' || justask?.showSelectedProjects && justAskPersist?.heirarchialSort["project"]?.label === 'end date' && props?.prevNextList !== 'myLeadsFavourites' && props?.prevNextList !== 'myLeadsHistory' && props?.prevNextList !== 'myLeadsShares' ? (<span className='src-date'><span className={`rs-end-date`}>{getFormatedStartOrEndDate(props.project?.project_finish_display)}</span></span>)
                                                                        : searchPersist?.defaultSort[activeFilterValue]?.label === 'end date' && props?.prevNextList !== 'myLeadsFavourites' && props?.prevNextList !== 'myLeadsHistory' && props?.prevNextList !== 'myLeadsShares' ||
                                                                            justAskPersist?.defaultSort[activeFilterValue]?.label === 'end date' && props?.prevNextList !== 'myLeadsFavourites' && props?.prevNextList !== 'myLeadsHistory' && props?.prevNextList !== 'myLeadsShares' ? (<span className='src-date'><span className={`rs-end-date`}>{getFormatedStartOrEndDate(props.project?.project_finish_display)}</span></span>)
                                                                            :
                                                                            searchPersist?.leadsSort[props?.activeTab]?.[searchPersist?.leadsAppliedContext?.[props?.activeTab]].label === 'end date' && props?.prevNextList === 'myLeadsFavourites' || searchPersist?.leadsSort[props?.activeTab]?.[searchPersist?.leadsAppliedContext?.[props?.activeTab]].label === 'end date' && props?.prevNextList === 'myLeadsHistory' || searchPersist?.leadsSort[props?.activeTab]?.[searchPersist?.leadsAppliedContext?.[props?.activeTab]].label === 'end date' && props?.prevNextList === 'myLeadsShares' ? (<span className='src-date'><span className={`rs-end-date`}>{getFormatedStartOrEndDate(props.project?.project_finish_display)}</span></span>)

                                                                                : (
                                                                                    <span className={`src-date ${props.prevNextList === 'myLeadsUpdates' ? 'ps-0' : ''}`}>
                                                                                        <span className="src-date">
                                                                                            {
                                                                                                props.project.updated_on && props.prevNextList === 'myLeadsUpdates' ?
                                                                                                    (<span className='src-latest update-time'>{getFormatedDate(props.project.updated_on, 'DD-MMM-YYYY')}</span>) :
                                                                                                    props && props.project.activity_date && props.prevNextList === 'myLeadsHistory' && searchPersist?.leadsSort[props?.activeTab]?.[searchPersist?.leadsAppliedContext?.[props?.activeTab]].label === 'last viewed' ?
                                                                                                        (<span className="src-latest update-time">{getFormatedDate(props.project.activity_date, 'DD-MMM-YYYY')}</span>) :
                                                                                                        props.project.project_last_published ?
                                                                                                            (<span className='src-latest update-time'>{getFormatedDate(props.project.project_last_published, 'DD-MMM-YYYY')}</span>) : ""

                                                                                            }
                                                                                        </span>
                                                                                    </span>
                                                                                )
                                                }

                                                {props.prevNextList !== 'myLeadsUpdates' ?
                                                    <>
                                                        {
                                                            props?.project?.project_stopped_display ?
                                                                <div className="project-build-phase-pro-flag on-hold-flag ms-2"> {props.project.project_stopped_display}</div>
                                                                :
                                                                'project_site_progression' in props.project &&
                                                                    props.project.project_build_phase &&
                                                                    (props.project.project_build_phase == "active_on_site" ||
                                                                        props.project.project_build_phase == "on_site") ?
                                                                    <div className="project-build-phase-pro-flag ms-2">
                                                                        {getOnsiteBadge(props.project.project_site_progression, props.project.project_build_phase)}
                                                                    </div> : ('')
                                                        }

                                                        {
                                                            checkIfProjectIsNew(props.project.project_last_published, props.project.project_first_published) ?
                                                                <div className="new-pro-flag d-inline-block ms-1">
                                                                    New
                                                                </div> : ('')
                                                        }
                                                    </> : ("")
                                                }

                                                {
                                                    props.project && props.project.tags_count != 0 ||
                                                        props.project && props.project.shares_count != 0 ||
                                                        props.project && props.project.mail ||
                                                        props.project && props.project.notes_count != 0 ?
                                                        (<TagShareNoteCount entity={props.project} />) : ("")
                                                }
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    </Link>
                    {

                        showMore && (
                            <>
                                {props.project.update_count >= 2 && myleads.myLeadsAllUpdates.length !== 0 ?
                                    props.project.update_count < 6 ?
                                        <div className="updates-details">
                                            {allUpdateList[0]?.updates.map(function (update, index) {
                                                if (index <= allUpdateCount - 1 && index > 0) {
                                                    return (
                                                        <div className="update-list" key={index}>
                                                            <span className="update-desc">{update.description}</span>
                                                            <span className="time">{getFormatedDate(update.updated_on, 'DD-MMM-YYYY')}</span>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div> :
                                        <div className="updates-details scrollbar">
                                            <ColoredScrollbars>
                                                {allUpdateList[0]?.updates.map(function (update, index) {
                                                    if (index <= allUpdateCount - 1 && index > 0) {
                                                        return (
                                                            <div className="update-list" key={index}>
                                                                <span className="update-desc">{update.description}</span>
                                                                <span className="time">{getFormatedDate(update.updated_on, 'DD-MMM-YYYY')}</span>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </ColoredScrollbars>
                                        </div> : ("")
                                }
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ProjectRow