import _, { cloneDeep } from 'lodash';
import moment from 'moment';
import { updateCompanyDetail, updateGroupCompanyProjectsFav, updatelistOfSelectedObjectIdsFav, updateObjectData, updatePeopleProjectsList, updateProjectsFavForCompanyId, updateSearchListFav } from '../components/search/redux/reducer';
import { ABI_LOGO, COMPANY_ABI_URL, DEFAULT_LIMIT, ENTITY_TYPE, MAPBOX_TOKEN, MAP_STYLE_URL, MAX_LIMIT_FOR_API_CALL, MIN_PROJECTS_REQUIRED_FOR_DISPLAY, PERCENTAGE_EXTRA_FOR_SUBSEQUENT_CALLS, PROJECT_ABI_URL, STATIC_IMAGE } from './settings';
import { updatelistOfSelectedObjectIdsTags, updateMyLeadsTag, updateSearchList, updateSearchListForUpdates } from '../components/myleads/reducer';
import { updateSearchlist } from '../components/leaguetable/reducer';
import { fetchRolesForProjects } from '../components/search/apiclient';
import { updateSearchListFav as jAskUpdateSearchListFav, updatelistOfSelectedObjectIdsFav as jAskUpdatelistOfSelectedObjectIdsFav, updateObjectDataJA, updateCompanyDetailJA, updateProjectsFavForCompanyId as jAskUpdateProjectsFavForCompanyId, updatePeopleProjectsList as jAskUpdatePeopleProjectsList, updateGroupCompanyProjectsFav as JaskupdateGroupCompanyProjectsFav } from '../components/justask/search/redux/reducer';
import { fetchRolesForProjectsJA } from '../components/justask/search/apiclient';
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isDST(date) {
    return moment(date).isDST();
}

export function generateCalendarLinks(event) {
    let listItems = []
    let outlookUrl = createOutlookLink(event)
    let googleUrl = createGoogleLink(event)
    listItems.push({ title: 'Outlook', value: outlookUrl })
    listItems.push({ title: 'Apple Calendar', value: outlookUrl })
    listItems.push({ title: 'Google', value: googleUrl })
    return listItems
}

function createOutlookLink(event) {
    var calendarUrl = "";
    calendarUrl = ["BEGIN:VCALENDAR", "VERSION:2.0", "BEGIN:VEVENT", "URL:" + document.URL, "DTSTART:" + formatTime(event.startTime), "DTEND:" + formatTime(event.endTime), "SUMMARY:" + event.title, "DESCRIPTION:" + event.description.replaceAll('\n', '\\n'), "LOCATION:" + event.location, "END:VEVENT", "END:VCALENDAR"].join("\n");
    return calendarUrl
}

function createGoogleLink(event) {
    var calendarUrl = "";
    calendarUrl = "https://calendar.google.com/calendar/render";
    calendarUrl += "?action=TEMPLATE";
    calendarUrl += "&dates=" + formatTime(event.startTime);
    calendarUrl += "/" + formatTime(event.endTime);
    calendarUrl += "&location=" + encodeURIComponent(event.location);
    calendarUrl += "&text=" + encodeURIComponent(event.title);
    calendarUrl += "&details=" + encodeURIComponent(event.description);
    return calendarUrl
}

function formatTime(date) {
    var formattedDate = moment.utc(date).format("YYYYMMDDTHHmmssZ");
    return formattedDate.replace("+00:00", "Z");
}

export function initializeCollaborationFilters(objectType) {
    let filters = {}
    filters.tags = {
        active: true,
        value: [20, 21]
    }
    filters.shares = {
        active: true,
        value: [30, 31]
    }
    filters.notes = {
        active: true,
        value: [70, 71, 72]
    }
    filters.favourites = {
        active: true,
        value: [1, 2]
    }
    filters.mailed = {
        active: true,
        value: [100]
    }

    if (objectType === "project") {
        filters.archived = {
            active: true,
            value: [110, 111]
        }
    }
    return filters
}

export function getEventTypeFilter(filter) {
    let eventType = []
    filter && Object.keys(filter).map(item => {
        if (filter[item].active) {
            eventType = [...eventType, ...filter[item].value]
        }
    })
    return eventType
}

export function selectorControlValue(count) {
    const configurable_record = 2000
    const selection_array_of_objects = [{ 50: 'Top 50' }, { 100: 'Top 100' }, { 250: 'Top 250' }, { 500: 'Top 500' }, { 1000: 'Top 1000' }, { 2000: 'Top 2000' }]
    if (count > configurable_record) {
        return selection_array_of_objects
    } else if (count <= configurable_record && count > 1000) {
        const array_of_objects = selection_array_of_objects.slice(0, 6)
        return array_of_objects
    } else if (count < configurable_record && count > 500 && count <= 1000) {
        const array_of_objects = selection_array_of_objects.slice(0, 5)
        return array_of_objects
    } else if (count <= 500 && count > 250) {
        const array_of_objects = selection_array_of_objects.slice(0, 4)
        return array_of_objects
    } else if (count <= 250 && count > 100) {
        const array_of_objects = selection_array_of_objects.slice(0, 3)
        return array_of_objects
    } else if (count <= 100 && count > 50) {
        const array_of_objects = selection_array_of_objects.slice(0, 2)
        return array_of_objects
    } else if (count <= 50) {
        return [selection_array_of_objects[0]]
    }
}

export function checkAvailabilityOfLimit(count, limit) {
    let response = false
    let value = count / parseInt(limit)
    if (value >= 1) {
        response = true
    }
    else {
        response = false
    }
    return response
}

export function checkAvailabilityOfOption(count) {
    let response = false
    let limits = [50, 100, 250, 500, 1000, 2000]

    limits.map(function (limit, index) {
        let value = count / limit
        if ((value > 0.5 && value < 1) || count < DEFAULT_LIMIT || (count < 125 && count > 100 && value > 0.4)) {
            response = true
        }
    })
    return response
}

export function checkIfAllMyleads(count) {
    let val = count % 50
    if (val === 0) {
        return false
    } else {
        return true
    }
}

export function checkIfShowProjectsCompanies(selectedCount, totalRecords) {
    return selectedCount <= 50
}

export function fetchSortValue(sortOrder, objectType) {
    let sortValue = 'L'
    switch (sortOrder) {
        case 'relevance':
            sortValue = ''
            break;

        case 'nearest':
            sortValue = objectType === "project" ? 'project_distance' : "company_distance"
            break;

        case 'alphabetical':
            sortValue = "person_first_name,person_last_name"
            break;

        case 'value':
            sortValue = objectType === "project" ? '-project_value' : "-sum_project_value"
            break;

        case 'projectCount':
            sortValue = '-count_project_id'
            break;

        case 'projects':
            sortValue = '-count_project_id'
            break;

        case 'projectValue':
            sortValue = objectType === "project" ? '-project_value' : "-sum_project_value"
            break;

        case 'last viewed':
            sortValue = '-activity_date'
            break;
        case 'start date':
            sortValue = 'project_start_min,project_id'
            break;
        case 'end date':
            sortValue = 'project_finish_max,project_id'
            break;
        case 'companyAlphabetical':
            sortValue = 'company_name'
            break;

        default:
            sortValue = objectType === "project" ? '-project_last_published' : "-max_project_last_published"
    }
    return sortValue
}

export function fetchSortLabel(sortOrder) {
    let sortLabel = 'Latest'
    switch (sortOrder) {
        case '':
            sortLabel = 'relevance'
            break;
        case 'project_distance':
            sortLabel = 'nearest'
            break;
        case 'company_distance':
            sortLabel = 'nearest'
            break;
        case 'person_first_name,person_last_name':
            sortLabel = 'alphabetical'
            break;
        case '-project_value':
            sortLabel = 'value'
            break;
        case '-sum_project_value':
            sortLabel = 'value'
            break;
        case '-count_project_id':
            sortLabel = 'projects'
            break;
        case 'project_start_min,project_id':
            sortLabel = 'start date'
            break;
        case 'project_finish_max,project_id':
            sortLabel = 'end date'
            break;
        case 'company_name':
            sortLabel = 'alphabetical'
            break;
        default:
            sortLabel = 'Latest'
    }
    return sortLabel
}

export function renderMapboxImage(projectDetails) {
    let logo,
        projectURL,
        token = MAPBOX_TOKEN,
        mapStyleUrl = MAP_STYLE_URL,
        static_url

    let image = projectDetails?.objectType === ENTITY_TYPE.PROJECT ? PROJECT_ABI_URL : COMPANY_ABI_URL
    projectURL = "https%3A%2F%2F" + window.location.hostname + image

    logo = "https://" + window.location.hostname + ABI_LOGO
    static_url = "https://" + window.location.hostname + STATIC_IMAGE
    let alt = "Mapbox Map of " + projectDetails.longitude + "," + projectDetails.latitude
    let src, printSrc;
    if ((projectDetails.longitude !== undefined && projectDetails.longitude !== 0) || (projectDetails.latitude !== undefined && projectDetails.latitude !== 0)) {
        src = mapStyleUrl + projectURL + "(" +
            projectDetails.longitude + "," + projectDetails.latitude + ")/" +
            projectDetails.longitude + "," + projectDetails.latitude +
            ",16,0/587x200@2x?access_token=" + token
        printSrc = mapStyleUrl + projectURL + "(" +
            projectDetails.longitude + "," + projectDetails.latitude + ")/" +
            projectDetails.longitude + "," + projectDetails.latitude +
            ",16,0/587x200@2x?access_token=" + token
    } else {
        src = printSrc = static_url
    }
    src = { url: src }
    var imagesArray = [src];
    if (projectDetails?.objectType === ENTITY_TYPE.PROJECT) {
        if (Array.isArray(projectDetails.project_images) && projectDetails.project_images.length) {
            imagesArray = [...projectDetails.project_images, src]
        }
    }
    else {
        imagesArray = [src];
    }
    var images = { carouselImages: imagesArray, staticImage: static_url }
    return images;
}

export function getIncludeExcludeIds(selectedItem, includesIds, excludeIds) {
    let modfiedIncludeIds = includesIds ? includesIds : []
    let modifiedExcludeIds = excludeIds ? excludeIds : []
    if (selectedItem.isSelected) {
        if (modifiedExcludeIds.length > 0 && modifiedExcludeIds.includes(selectedItem.id)) {
            modifiedExcludeIds.splice(excludeIds.indexOf(selectedItem.id), 1)
        } else {
            modfiedIncludeIds.push(selectedItem.id)
        }
    } else {
        if (modfiedIncludeIds.length > 0 && modfiedIncludeIds.includes(selectedItem.id)) {
            modfiedIncludeIds.splice(modfiedIncludeIds.indexOf(selectedItem.id), 1)
        } else {
            modifiedExcludeIds.push(selectedItem.id)
        }
    }
    return {
        includeIds: modfiedIncludeIds,
        excludeIds: modifiedExcludeIds
    }
}

export function updateNotificationSettingResponse(notificationData) {
    let notificationSetting = []
    let projectUpdate = []
    let buildPhase = []
    let companyUpdate = []
    notificationData.map(notification => {
        if (notification.name == "Project stages" || notification.name == "Project stage changes") {
            projectUpdate.push({
                name: "All project stage changes",
                value: "",
                children: notification.children
            })
        }
        if (notification.name == "Project roles" || notification.name == "Project roles changes") {
            projectUpdate.push({
                name: "Project roles added",
                value: notification.value
            })
        }
        if (notification.name == "Project timing" || notification.name == "Project timing changes") {
            projectUpdate.push({
                name: "Date changes",
                value: notification.value
            })
        }
        if (notification.name == "Build Phase") {
            buildPhase = notification.children
        }
        if (notification.name == "Company updates" || notification.name == "Company roles changes") {
            companyUpdate.push({
                name: "All roles",
                value: notification.value
            })
        }
    })
    notificationSetting.push({
        "header": "Project updates - select the updates you wish to receive",
        "options": projectUpdate
    })
    if (buildPhase.length > 0) {
        notificationSetting.push({
            "header": "Build phase",
            "options": buildPhase
        })
    }
    notificationSetting.push({
        "header": "Company updates - select to see when a company has been added to a project",
        "options": companyUpdate
    })
    return notificationSetting
}

export function setActiveIfKeyIsPresent(updatedSettings, alertTypes, parent) {
    if (!updatedSettings) {
        return;
    }
    if (updatedSettings.hasOwnProperty('value')) {
        if (updatedSettings.value && alertTypes.filter(x => x === updatedSettings.value).length > 0) {
            updatedSettings.active = true;
        } else if (updatedSettings.value && alertTypes.filter(x => x.indexOf(updatedSettings.value) != -1).length > 0) {
            updatedSettings.active = true;
        } else {
            updatedSettings.active = false;
        }
    }
    if (updatedSettings.hasOwnProperty('options')) {
        setActiveIfKeyIsPresent(updatedSettings.options, alertTypes)
    }

    if (updatedSettings.hasOwnProperty('children')) {
        setActiveIfKeyIsPresent(updatedSettings.children, alertTypes, updatedSettings)
    }

    if (Array.isArray(updatedSettings)) {
        updatedSettings.map(setting => {
            setActiveIfKeyIsPresent(setting, alertTypes)
        })
        if (parent && updatedSettings.every(x => x.active === true)) {
            parent.active = true
        }
    }
}
export function numberWithCommas(x) {
    return (x) ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
}

export function calculateHumanizeTime(updatedOn) {
    if (updatedOn) {
        var relativeTime = moment(updatedOn)
        var relativeDate = relativeTime.calendar(null, {
            sameDay: '[Today]',
            sameElse: 'Do MMM'
        })
        var daysAgo = ""
        if (relativeDate === "Today") {
            daysAgo = relativeTime.fromNow()
        } else {
            daysAgo = relativeTime.format("Do MMM")
        }

        return daysAgo
    }

}

export function formatUpdatedOnDate(updatedOn) {
    if (updatedOn) {
        var relativeTime = moment(updatedOn)
        var formattedDate = relativeTime.format("Do MMMM")
        return formattedDate
    }
}
export function getHumanizeDateForUpdates(updatedOn) {
    if (updatedOn) {
        var relativeTime = moment(updatedOn)
        var lastDate = relativeTime.calendar(null, {
            sameDay: '[Today]',
            lastDay: '[Yesterday]',
            lastWeek: '[Last week]',
            sameElse: '[Earlier]'
        })
        var daysAgo = relativeTime.fromNow()
        var month = moment.duration(updatedOn)._data.months
        var currentMonth = parseInt(moment().format('MM'))
        var days = ["2 days ago", "3 days ago", "4 days ago", "5 days ago", "6 days ago"]
        if (days.includes(daysAgo) && moment(updatedOn).isSame(new Date(), 'week') == true) {
            lastDate = "Earlier this week"
        } else if (currentMonth === month && (!["Today", "Yesterday", "Last week"].includes(lastDate))) {
            lastDate = "Earlier this month"
        } else if ((currentMonth - 1) === month && (!["Today", "Yesterday", "Last week"].includes(lastDate))) {
            lastDate = "Last month"
        }
        return lastDate
    } else {
        return "";
    }
}

export function sortNotications(notifications) {
    let updateRecord = {};
    if (notifications && notifications.length > 0) {
        notifications.map(function (update, index) {
            let humanizeDate = getHumanizeDateForUpdates(update.raised_on)
            let updateRecordDetails = {}
            updateRecordDetails['body'] = update.body
            updateRecordDetails['subject'] = update.subject
            updateRecordDetails['time'] = calculateHumanizeTime(update.raised_on)
            updateRecordDetails['date'] = formatUpdatedOnDate(update.raised_on)
            updateRecordDetails['object_type'] = update.object_type
            updateRecordDetails['object_id'] = update.object_id
            updateRecordDetails['notificationtypeno'] = update.notificationtypeno
            if (updateRecord[humanizeDate] && updateRecord[humanizeDate].length > 0) {
                updateRecord[humanizeDate].push(updateRecordDetails)
            } else {
                updateRecord[humanizeDate] = []
                updateRecord[humanizeDate].push(updateRecordDetails)
            }

        })
    }

    return updateRecord
}

export function updateSearchListForFav(entity, state, dispatch, listFav) {
    if (state.listOfSelectedObjectIds && state.listOfSelectedObjectIds.length) {
        let clonedObjForList = []
        _.map(cloneDeep(state.listOfSelectedObjectIds), (record) => {
            record.favourite = record.is_selected ? entity.favourite : record.favourite
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(updatelistOfSelectedObjectIdsFav(clonedObjForList));
        }
    } else if (state?.groupCompanyProjects?.length) {
        let clonedObjForList = []
        _.map(cloneDeep(state.groupCompanyProjects), (record) => {
            record.favourite = record.is_selected ? entity.favourite : record.favourite
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(updateGroupCompanyProjectsFav(clonedObjForList));
        }
    } else if (listFav === 'leagueTableFav') {
        let clonedObjForList = []
        _.map(cloneDeep(state.searchList), (record) => {
            record.favourite = record.is_selected ? entity.favourite : record.favourite
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(updateSearchlist(clonedObjForList));
        }
    } else {
        let clonedObjForList = []
        _.map(cloneDeep(state.searchList), (record) => {
            record.favourite = record.is_selected ? entity.favourite : record.favourite
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(updateSearchListFav(clonedObjForList));
        }
    }
}

export function checkSearchByIdFilter(searchFilter) {
    let value = false
    if (searchFilter?.projectInformation?.active && searchFilter?.projectInformation?.value != "") {
        const projectText = searchFilter.projectInformation.value
        if (projectText.length == 1 && !isNaN(projectText[0]) && projectText[0].length == 8 && projectText[0].startsWith('1')) {
            value = true
        }
    } else if (searchFilter?.companyInformation?.active && searchFilter?.companyInformation?.value != "") {
        const companyText = searchFilter.companyInformation.value.toString()
        if (!isNaN(companyText) && companyText.length <= 7 && !companyText.startsWith('0')) {
            value = true
        }
    } else if (searchFilter?.personInformation?.active && searchFilter?.personInformation?.value != "") {
        const personText = searchFilter.personInformation.value.toString()
        if (!isNaN(personText) && personText.length <= 7 && !personText.startsWith('0')) {
            value = true
        }
    }
    return value
}

export function updateSearchListForTag(entity, state, dispatch, listFav) {
    var tag_count = entity.tag_id ? entity.tag_id.length : 0
    tag_count += entity.tag_name ? entity.tag_name.length : 0
    if (state.listOfSelectedObjectIds && state.listOfSelectedObjectIds.length) {
        let clonedObjForList = []
        _.map(cloneDeep(state.listOfSelectedObjectIds), (record) => {
            record.tags_count = record.is_selected ? record.tags_count + tag_count : record.tags_count
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(updatelistOfSelectedObjectIdsFav(clonedObjForList));
        }
    } else if (state?.groupCompanyProjects?.length) {
        let clonedObjForList = []
        _.map(cloneDeep(state.groupCompanyProjects), (record) => {
            record.tags_count = record.is_selected ? record.tags_count + tag_count : record.tags_count
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(updateGroupCompanyProjectsFav(clonedObjForList));
        }
    } else if (listFav === 'leagueTableFav') {
        let clonedObjForList = []
        _.map(cloneDeep(state.searchList), (record) => {
            record.tags_count = record.is_selected ? record.tags_count + tag_count : record.tags_count
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(updateSearchlist(clonedObjForList));
        }
    } else {
        let clonedObjForList = []
        _.map(cloneDeep(state.searchList), (record) => {
            record.tags_count = record.is_selected ? record.tags_count + tag_count : record.tags_count
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(updateSearchListFav(clonedObjForList));
        }
    }
}

export function updateMyLeadsForTag(entity, state, dispatch) {
    var tag_count = entity.tag_id ? entity.tag_id.length : entity.ids ? entity.ids.length : entity.tag_name.length
    if (state.listOfSelectedObjectIds && state.listOfSelectedObjectIds.length) {
        let clonedObjForList = []
        _.map(cloneDeep(state.listOfSelectedObjectIds), (record) => {
            record.tags_count = record.is_selected ? record.tags_count + tag_count : record.tags_count
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(updatelistOfSelectedObjectIdsTags(clonedObjForList));
        }
    } else {
        let clonedObjForList = []
        _.map(cloneDeep(state.searchList), (record) => {
            record.tags_count = record.is_selected ? record.tags_count + tag_count : record.tags_count
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(updateMyLeadsTag(clonedObjForList));
        }
    }
}

export function convertDateFormat(dateString) {
    if (dateString && dateString.includes('/')) {
        const [day, month, year] = dateString && dateString.split('/');
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const monthAbbreviation = months[parseInt(month) - 1];
        return `${day}-${monthAbbreviation}-${year}`;
    }
    else {
        return dateString
    }
}

function updateListBasedOnEntity(searchList, payload, objectType) {
    let type = objectType === 'project' ? 'project_id' : 'company_id'
    payload.map(item => {
        searchList.find((subItem, index) => {
            if (subItem[type] === item[type]) {
                searchList[index].tags_count = item.tags_count
                searchList[index].notes_count = item.notes_count
                searchList[index].shares_count = item.shares_count
                searchList[index].favourite = item.favourite
                searchList[index].read = item.read ? item.read : searchList[index].read
                if (subItem.objectType === "project") {
                    searchList[index].archive = item.archive ? item.archive : searchList[index].archive
                }
            }
        })
    })
    return searchList;
}

export function updateListFromDetailsBack(entity, state, dispatch, payload) {
    if (entity === 'project' && state.projectRowData) {
        let searchDetails = []
        searchDetails.push(_.cloneDeep(state.projectRowData))
        searchDetails = updateListBasedOnEntity(searchDetails, payload, entity)
        dispatch(updateObjectData(searchDetails[0]));
        let searchList = _.cloneDeep(state.searchList)
        searchList = updateListBasedOnEntity(searchList, payload, entity)
        dispatch(updateSearchListFav(searchList));
    } else if (entity === 'company' && state.projectRowData) {
        fetchRolesForProjects(state.projectRowData.project_id, dispatch);
    } else if (entity === 'company' && state.companyDetails) {
        let searchDetails = []
        searchDetails.push(_.cloneDeep(state.companyDetails))
        searchDetails = updateListBasedOnEntity(searchDetails, payload, entity)
        dispatch(updateCompanyDetail(searchDetails[0]));
        let searchList = _.cloneDeep(state.searchList)
        searchList = updateListBasedOnEntity(searchList, payload, entity)
        dispatch(updateSearchListFav(searchList));
    } else if (entity === 'project' && state.projectsForCompanyId && state.projectsForCompanyId.length) {
        let searchList = _.cloneDeep(state.projectsForCompanyId)
        searchList = updateListBasedOnEntity(searchList, payload, entity)
        dispatch(updateProjectsFavForCompanyId(searchList));
    } else if (entity === 'project' && state.peopleProjectsList && state.peopleProjectsList.length) {
        let searchList = _.cloneDeep(state.peopleProjectsList)
        searchList = updateListBasedOnEntity(searchList, payload, entity)
        dispatch(updatePeopleProjectsList(searchList));
    } else if (state.listOfSelectedObjectIds && state.listOfSelectedObjectIds.length) {
        let searchList = _.cloneDeep(state.listOfSelectedObjectIds)
        searchList = updateListBasedOnEntity(searchList, payload, entity)
        dispatch(updatelistOfSelectedObjectIdsFav(searchList));
    } else {
        let searchList = _.cloneDeep(state.searchList)
        searchList = updateListBasedOnEntity(searchList, payload, entity)
        dispatch(updateSearchListFav(searchList));
    }
}

export function updateListFromDetailsBackOfJustAsk(entity, state, dispatch, payload) {
    if (entity === 'project' && state.projectRowData) {
        let searchDetails = []
        searchDetails.push(_.cloneDeep(state.projectRowData))
        searchDetails = updateListBasedOnEntity(searchDetails, payload, entity)
        dispatch(updateObjectDataJA(searchDetails[0]));
        let searchList = _.cloneDeep(state.searchList)
        searchList = updateListBasedOnEntity(searchList, payload, entity)
        dispatch(jAskUpdateSearchListFav(searchList));
    } else if (entity === 'company' && state.projectRowData) {
        fetchRolesForProjectsJA(state.projectRowData.project_id, dispatch);
    } else if (entity === 'company' && state.companyDetails) {
        let searchDetails = []
        searchDetails.push(_.cloneDeep(state.companyDetails))
        searchDetails = updateListBasedOnEntity(searchDetails, payload, entity)
        dispatch(updateCompanyDetailJA(searchDetails[0]));
        let searchList = _.cloneDeep(state.searchList)
        searchList = updateListBasedOnEntity(searchList, payload, entity)
        dispatch(jAskUpdateSearchListFav(searchList));
    } else if (entity === 'project' && state.projectsForCompanyId && state.projectsForCompanyId.length) {
        let searchList = _.cloneDeep(state.projectsForCompanyId)
        searchList = updateListBasedOnEntity(searchList, payload, entity)
        dispatch(jAskUpdateProjectsFavForCompanyId(searchList));
    } else if (entity === 'project' && state.peopleProjectsList && state.peopleProjectsList.length) {
        let searchList = _.cloneDeep(state.peopleProjectsList)
        searchList = updateListBasedOnEntity(searchList, payload, entity)
        dispatch(jAskUpdatePeopleProjectsList(searchList));
    } else if (state.listOfSelectedObjectIds && state.listOfSelectedObjectIds.length) {
        let searchList = _.cloneDeep(state.listOfSelectedObjectIds)
        searchList = updateListBasedOnEntity(searchList, payload, entity)
        dispatch(jAskUpdatelistOfSelectedObjectIdsFav(searchList));
    } else {
        let searchList = _.cloneDeep(state.searchList)
        searchList = updateListBasedOnEntity(searchList, payload, entity)
        dispatch(jAskUpdateSearchListFav(searchList));
    }
}

export function updateMyleadsListFromDetailsBack(entity, state, myleads, dispatch, payload, prevNextList) {
    if (entity === 'project' && state.projectRowData) {
        let searchDetails = []
        searchDetails.push(_.cloneDeep(state.projectRowData))
        searchDetails = updateListBasedOnEntity(searchDetails, payload, entity)
        dispatch(updateObjectData(searchDetails[0]));
        let searchList = _.cloneDeep(prevNextList === 'myLeadsUpdates' ? myleads.updatesSearchList : myleads.searchList)
        searchList = updateListBasedOnEntity(searchList, payload, entity)
        prevNextList === 'myLeadsUpdates' ? dispatch(updateSearchListForUpdates(searchList)) : dispatch(updateSearchList(searchList));
    } else if (entity === 'company' && state.projectRowData) {
        fetchRolesForProjects(state.projectRowData.project_id, dispatch);
    } else {
        let searchList = _.cloneDeep(prevNextList === 'myLeadsUpdates' ? myleads.updatesSearchList : myleads.searchList)
        searchList = updateListBasedOnEntity(searchList, payload, entity)
        prevNextList === 'myLeadsUpdates' ? dispatch(updateSearchListForUpdates(searchList)) : dispatch(updateSearchList(searchList));
    }
}

export function removeArchivedProjects(query, response) {
    if (typeof (query.query) === "string") {
        query.query = JSON.parse(query.query)
    }
    response.leadsFlags = "query" in query && "project_text" in query.query &&
        "value" in query.query.project_text &&
        Array.isArray(query.query.project_text.value) &&
        query.query.project_text.value.some(x => x.length >= 8 &&
            x.length <= 11 && !isNaN(x)) ? response.leadsFlags :
        response.leadsFlags.filter(item => !item.archive)
    return response
}

export function getRecordCount(query, response) {
    let clonedQuery = { ...query }
    clonedQuery = { ...clonedQuery, offset: clonedQuery.offset + clonedQuery.records }
    if (response.leadsFlags.length < MIN_PROJECTS_REQUIRED_FOR_DISPLAY) {
        clonedQuery = { ...clonedQuery, records: clonedQuery.records + ((clonedQuery.records * PERCENTAGE_EXTRA_FOR_SUBSEQUENT_CALLS) / 100) }
    }
    if (clonedQuery.records >= MAX_LIMIT_FOR_API_CALL) {
        clonedQuery = { ...clonedQuery, records: MAX_LIMIT_FOR_API_CALL }
    }
    return clonedQuery
}

export function getRecordCountForMyleads(query, response) {
    query.newOffset = query.offsetLimitValue.offset + query.offsetLimitValue.records
    if (response.length < MIN_PROJECTS_REQUIRED_FOR_DISPLAY) {
        query.newRecords = query.offsetLimitValue.records + ((query.offsetLimitValue.records * PERCENTAGE_EXTRA_FOR_SUBSEQUENT_CALLS) / 100)
    }
    if (query.newRecords >= MAX_LIMIT_FOR_API_CALL) {
        query.newRecords = MAX_LIMIT_FOR_API_CALL
    }
    return query
}

export function getRoles(data) {
    var roles = []
    if (data != undefined) {
        let rolesToDisplay = data.slice(0, 3)
        for (let i = 0; i < rolesToDisplay.length; i++) {
            roles.push(<span key={`roles${i}`}> {data[i]}</span>)
        }
        if (data.length - 3 > 0) {
            roles.push((<span className="roles-count"><span className="plusIcon"> +</span>{data.length - 3 + " more"}</span>))
        }
        return roles
    }
}

export function createCommonQuery(query) {
    let jsonQuery = {}
    jsonQuery[query['key']] = { "operator": query['operator'], "value": query['value'] };
    return jsonQuery;
}

export function preProcessSearchQuery(tempFilter) {
    var formValues = {}
    var value = null
    var value1 = null
    var value2 = null
    value = updateMeasureTotal(tempFilter)
    if (value) {
        value1 = value.from && value.from != '' ? parseInt(value.from) : "*"
        value2 = value.to && value.to !== '' ? parseInt(value.to) : '*'
        if (value2 !== '*' && value1 !== '*') {
            formValues['project_plan_area'] = { operator: '..', value1: value1, value2: value2 }
        } else if (value1 === '*') {
            formValues['project_plan_area'] = { operator: '<=', value: value2 }
        } else {
            formValues['project_plan_area'] = { operator: '>=', value: value1 }
        }
    } else {
        delete formValues['project_plan_area']
    }

    value = updateMeasureGroundFloor(tempFilter)
    if (value) {
        value1 = value.from && value.from != '' ? parseInt(value.from) : "*"
        value2 = value.to && value.to !== '' ? parseInt(value.to) : '*'
        if (value2 !== '*' && value1 !== '*') {
            formValues['project_floor_area'] = { operator: '..', value1: value1, value2: value2 }
        } else if (value1 === '*') {
            formValues['project_floor_area'] = { operator: '<=', value: value2 }
        } else {
            formValues['project_floor_area'] = { operator: '>=', value: value1 }
        }
    } else {
        delete formValues['project_floor_area']
    }

    value = updateMeasureSite(tempFilter)
    if (value) {
        value1 = value.from && value.from != '' ? parseInt(value.from) : "*"
        value2 = value.to && value.to !== '' ? parseInt(value.to) : '*'
        if (value2 !== '*' && value1 !== '*') {
            formValues['project_site_area'] = { operator: '..', value1: value1, value2: value2 }
        } else if (value1 === '*') {
            formValues['project_site_area'] = { operator: '<=', value: value2 }
        } else {
            formValues['project_site_area'] = { operator: '>=', value: value1 }
        }
    } else {
        delete formValues['project_site_area']
    }

    value = updateMeasureAboveGround(tempFilter)
    if (value) {
        value1 = value.from && value.from != '' ? parseInt(value.from) : "*"
        value2 = value.to && value.to !== '' ? parseInt(value.to) : '*'
        if (value2 !== '*' && value1 !== '*') {
            formValues['project_storeys_above'] = { operator: '..', value1: value1, value2: value2 }
        } else if (value1 === '*') {
            formValues['project_storeys_above'] = { operator: '<=', value: value2 }
        } else {
            formValues['project_storeys_above'] = { operator: '>=', value: value1 }
        }
    } else {
        delete formValues['project_storeys_above']
    }

    value = updateMeasureBelowGround(tempFilter)
    if (value) {
        value1 = value.from && value.from != '' ? parseInt(value.from) : "*"
        value2 = value.to && value.to !== '' ? parseInt(value.to) : '*'
        if (value2 !== '*' && value1 !== '*') {
            formValues['project_storeys_below'] = { operator: '..', value1: value1, value2: value2 }
        } else if (value1 === '*') {
            formValues['project_storeys_below'] = { operator: '<=', value: value2 }
        } else {
            formValues['project_storeys_below'] = { operator: '>=', value: value1 }
        }
    } else {
        delete formValues['project_storeys_below']
    }

    value = updateMeasureUnits(tempFilter)
    if (value) {
        value1 = value.from && value.from != '' ? parseInt(value.from) : "*"
        value2 = value.to && value.to !== '' ? parseInt(value.to) : '*'
        if (value2 !== '*' && value1 !== '*') {
            formValues['project_units'] = { operator: '..', value1: value1, value2: value2 }
        } else if (value1 === '*') {
            formValues['project_units'] = { operator: '<=', value: value2 }
        } else {
            formValues['project_units'] = { operator: '>=', value: value1 }
        }
    } else {
        delete formValues['project_units']
    }
    value = updateMeasureParking(tempFilter)
    if (value) {
        value1 = value.from && value.from != '' ? parseInt(value.from) : "*"
        value2 = value.to && value.to !== '' ? parseInt(value.to) : '*'
        if (value2 !== '*' && value1 !== '*') {
            formValues['project_parking'] = { operator: '..', value1: value1, value2: value2 }
        } else if (value1 === '*') {
            formValues['project_parking'] = { operator: '<=', value: value2 }
        } else {
            formValues['project_parking'] = { operator: '>=', value: value1 }
        }
    } else {
        delete formValues['project_parking']
    }
    return formValues

}
function updateMeasureTotal(payload) {
    let value
    if (payload?.totalFloorArea?.from !== '' || payload?.totalFloorArea?.to !== "") {
        value = {
            from: payload?.totalFloorArea?.from,
            to: payload?.totalFloorArea?.to
        }
    }
    return value ? value : ''
}

function updateMeasureGroundFloor(payload) {
    let value
    if (payload?.groundFloorArea?.from !== '' || payload?.groundFloorArea?.to !== '') {
        value = {
            from: payload?.groundFloorArea?.from,
            to: payload?.groundFloorArea?.to
        }
    }
    return value ? value : ''
}

function updateMeasureSite(payload) {
    let value
    if (payload?.siteArea?.from !== '' || payload?.siteArea?.to !== '') {
        value = {
            from: payload?.siteArea?.from,
            to: payload?.siteArea.to
        }
    }
    return value ? value : ''
}

function updateMeasureAboveGround(payload) {
    let value
    if (payload?.aboveGroundStoreys.from !== '' || payload?.aboveGroundStoreys.to !== '') {
        value = {
            from: payload?.aboveGroundStoreys?.from,
            to: payload?.aboveGroundStoreys?.to
        }
    }
    return value ? value : ''
}



function updateMeasureBelowGround(payload) {
    let value
    if (payload?.belowGroundStoreys?.from !== '' || payload?.belowGroundStoreys?.to !== '') {
        value = {
            from: payload?.belowGroundStoreys?.from,
            to: payload?.belowGroundStoreys?.to
        }
    }
    return value ? value : ''
}

function updateMeasureUnits(payload) {
    let value
    if (payload?.noOfUnits?.from !== '' || payload?.noOfUnits?.to !== '') {
        value = {
            from: payload?.noOfUnits?.from,
            to: payload?.noOfUnits?.to
        }
    }
    return value ? value : ''
}
function updateMeasureParking(payload) {
    let value
    if (payload?.parkingSpaces?.from !== '' || payload?.parkingSpaces?.from !== '') {
        value = {
            from: payload?.parkingSpaces?.from,
            to: payload?.parkingSpaces?.to
        }
    }
    return value ? value : ''
}
export function createPayloadForProjectsForRoleId(role, companyId, isRemoveToggle, searchPersist) {
    let query1 = {}
    if (!isRemoveToggle) {
        query1 = {
            "company_id": { "operator": "=", "value": companyId },
            "role_code": { "operator": "=", "value": role }
        }
    }
    else {
        query1 = _.cloneDeep(searchPersist?.filterQuery?.query)
        query1['company_id'] = { "operator": "=", "value": companyId }
        query1['role_code'] = { "operator": "=", "value": role }
    }
    let query = query1
    return query
}


export function getBuildPhaseValue(buildPhase) {

    let activePhase = []

    if (buildPhase && buildPhase.options) {
        activePhase = buildPhase.options.filter(item => item.active).map(item => item.value)
    }

    let from = 2 // assigned from to the highest value of slider
    let end = 1 // assigned end to the lowest value of slider

    if (activePhase.includes("not_started")) {
        from = 0
        end = 1
    }
    if (activePhase.includes("enabling")) {
        from = from === 0 ? from : 1
        end = 2
    }
    if (activePhase.includes("on_site")) {
        from = from === 0 || from === 1 ? from : 2
        end = 3
    }
    return [from, end]
}

export const getUniqueSelectedList = (groupCompanies) => {
    let objectIds = []
    Object.keys(groupCompanies).map((role, index) => {
        groupCompanies[role].map((entity, index) => {
            if (entity.is_selected) {
                objectIds.push(entity.company_id)
            }
        })
    })
    objectIds = [...new Set(objectIds)]
    return objectIds
}

export const getUniqueSelectedListForGroupCompanyProjects = (groupCompanyProject) => {
    let objectIds = []
    groupCompanyProject.map((entity) => {
        if (entity.is_selected) {
            objectIds.push(entity.project_id)
        }
    })
    objectIds = [...new Set(objectIds)]
    return objectIds
}

export function getIncludeExcludeFilterList() {
    let checkboxListFilters = [
        {
            title: 'Roles',
            advanceFilterStateKey: 'rolesFilter',
            filterType: 'roles',
            subFilterType: null,
            isIncludeExclude: true,
            includeKey: 'includeRoles',
            excludeKey: 'excludeRoles',
            filterStatictext: 'Filter by selected roles occupied by people and companies on projects',
            componentId: 'new_filter_roles'
        },
        {
            title: 'Categories',
            advanceFilterStateKey: 'categoryType',
            filterType: 'categories',
            subFilterType: 'subCategories',
            isIncludeExclude: true,
            includeKey: 'includeCategories',
            excludeKey: 'excludeCategories',
            filterStatictext: 'Select categories required. Exclude any where you don’t want mixed use. Primary categories can be used where the category is a major part of the scheme (this will contain other minor categories)',
            componentId: 'new_filter_category'
        },
        {
            title: 'Development type',
            advanceFilterStateKey: 'developmentType',
            filterType: 'developmentTypes',
            subFilterType: null,
            elementId: 'DevelopmentTypeLocation',
            isIncludeExclude: true,
            includeKey: 'includeDevelopmentTypes',
            excludeKey: 'excludeDevelopmentTypes',
            filterStatictext: 'Include or exclude by project development type',
            componentId: 'new_filter_devtype'
        },
        {
            title: 'Catering type',
            advanceFilterStateKey: 'cateringType',
            filterType: 'cateringTypes',
            subFilterType: null,
            elementId: 'CateringTypeLocation',
            isIncludeExclude: true,
            includeKey: 'includeCateringTypes',
            excludeKey: 'excludeCateringTypes',
            filterStatictext: '',
            componentId: 'new_filter_cattype'
        }
    ]
    return checkboxListFilters
}

export function getConstructionDateValue(construction) {
    let sliderValueArr = {
        "PAST5Y": "-8",
        "PAST4Y": "-7",
        "PAST3Y": "-6",
        "PAST2Y": "-5",
        "PAST1Y": "-4",
        "P6M": "-3",
        "P3M": "-2",
        "P1M": "-1",
        "TODAY": "0",
        "1M": "1",
        "3M": "2",
        "6M": "3",
        "FUTURE1Y": "4",
        "FUTURE2Y": "5",
        "FUTURE3Y": "6",
        "FUTURE4Y": "7",
        "FUTURE5Y": "8"
    }
    let value = []
    if (construction && Array.isArray(construction.options)) {
        let activeTimings = construction.options.filter(item => item.active).map(item => item.name)
        if (activeTimings.length > 1) {
            activeTimings.map(item => value.push(sliderValueArr[item]))
        } else if (activeTimings.length == 1) {
            value = [sliderValueArr[activeTimings[0]], sliderValueArr[activeTimings[0]]]
        }
    }
    return value
}

export function returnTimeFrame(StateRestored) {
    let sliderValueArr = {
        "-8": {
            name: "PAST5Y",
            message: " 5 years"
        },
        "-7": {
            name: "PAST4Y",
            message: " 4 years"
        },
        "-6": {
            name: "PAST3Y",
            message: " 3 years"
        },
        "-5": {
            name: "PAST2Y",
            message: " 2 years"
        },
        "-4": {
            name: "PAST1Y",
            message: " 1 year"
        },
        "-3": {
            name: "P6M",
            message: " 6 months",
        },
        "-2": {
            name: "P3M",
            message: " 3 months"
        },
        "-1": {
            name: "P1M",
            message: " 1 month"
        },
        "0": {
            name: "TODAY",
            message: " today"
        },
        "1": {
            name: "1M",
            message: " 1 month"
        },
        "2": {
            name: "3M",
            message: " 3 months"
        },
        "3": {
            name: "6M",
            message: " 6 months"
        },
        "4": {
            name: "FUTURE1Y",
            message: " 1 year"
        },
        "5": {
            name: "FUTURE2Y",
            message: " 2 years"
        },
        "6": {
            name: "FUTURE3Y",
            message: " 3 years"
        },
        "7": {
            name: "FUTURE4Y",
            message: " 4 years"
        },
        "8": {
            name: "FUTURE5Y",
            message: " 5 years"
        }
    }
    return StateRestored || StateRestored === 0 ? sliderValueArr[StateRestored.toString()] : ""
}
export function getUpdateFilterValue(value) {
    let sliderValue = {
        "-11": "in the last 5 years",
        "-10": "in the last 4 years",
        "-9": "in the last 3 years",
        "-8": "in the last 2 years",
        "-7": "in the last year",
        "-6": "in the last 6 months",
        "-5": "in the last 3 months",
        "-4": "in the last 2 months",
        "-3": "in the last month",
        "-2": "in the last 2 weeks",
        "-1": "in the last week",
        "0": "in the last day"
    }
    return value || value === 0 ? sliderValue[value.toString()] : ""
}

export function convertDate(date) {
    let convertDate = moment.utc(moment(date).tz('UTC', true))
    convertDate = convertDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    convertDate = convertDate.format()
    return convertDate
}

export function getRoleAddedSlideValue(filter) {
    let options = []
    if (filter.roleAdded.active && filter.roleAdded.toggleActive) {
        options = filter.roleAdded.options
    }
    let sliderValue = {
        "365": "-7",
        "180": "-6",
        "90": "-5",
        "60": "-4",
        "30": "-3",
        "14": "-2",
        "7": "-1",
        "1": "0"
    }
    let index
    if (options.length > 0) {
        let roleAddedObj = options.filter(item => item.active)
        index = roleAddedObj && roleAddedObj.length > 0 ? sliderValue[roleAddedObj[0].value] : "-1"
    }
    return index
}

export function fetchAppliedFilters(appliedFilters, filters) {
    let orderedAppliedCriteria = {}
    let orderedTempCriteria = {}
    Object.keys(appliedFilters).sort().forEach(function (key) {
        orderedAppliedCriteria[key] = appliedFilters[key];
    })
    Object.keys(filters).sort().forEach(function (key) {
        orderedTempCriteria[key] = filters[key];
    })
    return JSON.stringify(orderedAppliedCriteria) == (JSON.stringify(orderedTempCriteria))
}

export function extractTownPostcode(tags, location) {
    if (tags.value.includes('and') && tags.value.split("and")?.length > 0) {
        _.map(tags.value.split("and"), tag => {
            if (tag.includes(',') && tag.split(',')) {
                _.map(tag.split(','), element => {
                    location.push({ label: element, value: element });
                });
            } else {
                location.push({ label: tag, value: tag });
            }
        });
    } else {
        location.push({ label: tags.value, value: tags.value });
    }
    return location
}

export function fetchSearchMode(filter, display) {
    let advanceCount = 0
    filter && Object.keys(filter).map(name => {
        if (filter[name] && filter[name].active) {
            switch (display) {
                case 'company':
                    if (!(["companyInformation", "companyLocation", "companyTown", "companyPostcode"].includes(name))) {
                        if (name !== "excludeStages") {
                            advanceCount = + 1
                        } else {
                            if (filter.excludeStages.toggleActive) {
                                advanceCount = + 1
                            }
                        }
                    }
                    break;
                case 'project':
                    if (!(["projectInformation", "location", "projectTown", "projectPostcode"].includes(name))) {
                        if (name !== "excludeStages") {
                            advanceCount = + 1
                        } else {
                            if (filter.excludeStages.toggleActive) {
                                advanceCount = + 1
                            }
                        }
                    }
                    break;
                case 'people':
                    if (!(["personInformation"].includes(name))) {
                        if (name !== "excludeStages") {
                            advanceCount = + 1
                        } else {
                            if (filter.excludeStages.toggleActive) {
                                advanceCount = + 1
                            }
                        }
                    }
                    break
            }
        }
        if (name === "projectUsage" &&
            filter[name].value &&
            Array.isArray(filter[name].value) &&
            filter[name].value.length > 0
        ) {
            advanceCount += 1
        }
        if (name === "projectLandType" &&
            filter[name].value &&
            Array.isArray(filter[name].value) &&
            filter[name].value.length > 0
        ) {
            advanceCount += 1
        }
    })
    return advanceCount == 0 ? true : false
}

export function getSortValueContextChange(newContext, sort, tempFilter) {
    let commonSort = ["-project_last_published", "-project_value", "-sum_project_value"];
    let updatedSort = "-project_last_published"
    let location = tempFilter.location ? tempFilter.location : {}, companyLocation = tempFilter.companyLocation ? tempFilter.companyLocation : {}
    if ((newContext === ENTITY_TYPE.PROJECT) && (location && location.active && location.location !== '')) {
        updatedSort = 'project_distance'
    } else if ((newContext === ENTITY_TYPE.COMPANY) && (companyLocation && companyLocation.active && companyLocation.location !== '')) {
        updatedSort = 'company_distance'
    } else if ((tempFilter.projectId?.active) ||
        (tempFilter.projectTitle?.active) ||
        (tempFilter.projectScheme?.active) ||
        (tempFilter.projectInformation?.active) ||
        (tempFilter.companyInformation?.active) ||
        (tempFilter.companyId?.active) ||
        (tempFilter.companyName?.active) ||
        (tempFilter.personJobTitle?.active) ||
        (tempFilter.personInformation?.active) ||
        (tempFilter.personName?.active) ||
        (tempFilter.brands?.active)
    ) {
        updatedSort = ""
    } else if (newContext === "company") {
        updatedSort = "company_name"
    } else if (commonSort.includes(sort)) {
        updatedSort = sort
    }
    return updatedSort
}

export function fetchHandleSubmitSort(activeDisplayValue, tempFilter, filterContext) {
    let location = tempFilter?.location ? tempFilter.location : {}, companyLocation = tempFilter?.companyLocation ? tempFilter.companyLocation : {}
    let sort = ((activeDisplayValue === ENTITY_TYPE.PROJECT) &&
        (location?.active && location?.location !== '')) ? 'project_distance' :
        ((activeDisplayValue === ENTITY_TYPE.COMPANY) &&
            (companyLocation?.active && companyLocation?.location !== '')) ? 'company_distance' :
            ((tempFilter?.projectId?.active) ||
                (tempFilter?.projectTitle?.active) ||
                (tempFilter?.projectScheme?.active) ||
                (tempFilter?.projectInformation?.active) ||
                (tempFilter?.companyInformation?.active) ||
                (tempFilter?.companyId?.active) ||
                (tempFilter?.companyName?.active) ||
                (tempFilter?.personJobTitle?.active) ||
                (tempFilter?.personInformation?.active) ||
                (tempFilter?.personName?.active) ||
                (tempFilter?.brands?.active)
            ) ? '' :
                activeDisplayValue === ENTITY_TYPE.PEOPLE ? 'person_first_name,person_last_name'
                    : activeDisplayValue === ENTITY_TYPE.COMPANY ? filterContext ? 'company_name'
                        : "-count_project_id" : "-project_last_published"
    return sort
}
export function checkIfFieldActive(criteria, type) {
    let active = false
    if (type && criteria) {
        if (criteria.setting &&
            criteria.setting.fields &&
            criteria.setting.fields.hasOwnProperty(type) &&
            criteria.setting.fields[type].length > 0) {
            active = true
        }
    }
    return active;
}

export function capitalizeWordsAfterSpace(str) {
    let words = str.split(' ');
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(' ');
}

export function encodeValues(query, validKeys) {
    let keys = Object.keys(query);
    let encodedQuery = { ...query };
    keys.forEach(key => {
        if (validKeys.includes(key) && query[key].operator === "=") {
            encodedQuery[key] = {
                operator: "=",
                value: Array.isArray(query[key].value)
                    ? query[key].value.map(val => encodeURIComponent(val))
                    : encodeURIComponent(query[key].value)
            };
        }
    });
    return encodedQuery;
}

export function updateSearchListForFavJA(entity, state, dispatch, listFav) {
    if (state.listOfSelectedObjectIds && state.listOfSelectedObjectIds.length) {
        let clonedObjForList = []
        _.map(cloneDeep(state.listOfSelectedObjectIds), (record) => {
            record.favourite = record.is_selected ? entity.favourite : record.favourite
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(jAskUpdatelistOfSelectedObjectIdsFav(clonedObjForList));
        }
    } else if (state?.groupCompanyProjects?.length) {
        let clonedObjForList = []
        _.map(cloneDeep(state.groupCompanyProjects), (record) => {
            record.favourite = record.is_selected ? entity.favourite : record.favourite
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(JaskupdateGroupCompanyProjectsFav(clonedObjForList));
        }
    }
    else {
        let clonedObjForList = []
        _.map(cloneDeep(state.searchList), (record) => {
            record.favourite = record.is_selected ? entity.favourite : record.favourite
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(jAskUpdateSearchListFav(clonedObjForList));
        }
    }
}

export function updateSearchListForTagJA(entity, state, dispatch, listFav) {
    var tag_count = entity.tag_id ? entity.tag_id.length : 0
    tag_count += entity.tag_name ? entity.tag_name.length : 0
    if (state.listOfSelectedObjectIds && state.listOfSelectedObjectIds.length) {
        let clonedObjForList = []
        _.map(cloneDeep(state.listOfSelectedObjectIds), (record) => {
            record.tags_count = record.is_selected ? record.tags_count + tag_count : record.tags_count
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(jAskUpdatelistOfSelectedObjectIdsFav(clonedObjForList));
        }
    } else if (state?.groupCompanyProjects?.length) {
        let clonedObjForList = []
        _.map(cloneDeep(state.groupCompanyProjects), (record) => {
            record.tags_count = record.is_selected ? record.tags_count + tag_count : record.tags_count
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(JaskupdateGroupCompanyProjectsFav(clonedObjForList));
        }
    }

    else {
        let clonedObjForList = []
        _.map(cloneDeep(state.searchList), (record) => {
            record.tags_count = record.is_selected ? record.tags_count + tag_count : record.tags_count
            clonedObjForList.push(record)
        })
        if (clonedObjForList.length) {
            dispatch(jAskUpdateSearchListFav(clonedObjForList));
        }
    }
}