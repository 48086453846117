import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chardin } from './chardin';
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../common/js/icon";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  rolesForProject,
  updateBackButtonCluster,
  updateClusterList,
  updateShowHierarchy,
  updateShowSelectedCompanies,
  updateShowSelectedProjects,
  updateIsShowProjectsOrCompanySelected,
} from "../search/redux/reducer";
import { updateSearchlist } from "../leaguetable/reducer";
import { updateMyLeadsShowHierarchy } from "../myleads/reducer";
import { TOURSAVEDSEARCH } from "../../utils/tour";
import { updateCurrentPage } from "../search/redux/searchPeristReducer";
import { updateIsSharedSaveSearchActive, updateSavedSearchOffsetLimitValue } from "../savedsearch/redux/reducer";
import { updateAdminUserActivitiesOffsetLimitValue } from "../useradmin/reducer";
import { updateClusterListJA, updateShowHierarchyForJustAsk, updateShowSelectedCompaniesJA, updateShowSelectedProjectsJA } from "../justask/search/redux/reducer";


const LeftPanel = () => {
  const exports = useSelector((state) => state.export)
  const userData = useSelector(state => state.user.user)
  const justAskPersist = useSelector((state) => state.justAskPersist)
  const navigate = useNavigate();
  const settings = useSelector((state) => state.settings);
  const location = useLocation();

  const [activeButtonType, setActiveButtonType] = useState("");
  const [helpVisible, setHelpVisible] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(false);
  const [hideQuickGuide, setHideQuickGuide] = useState(0);
  const [showDismiss, setShowDismiss] = useState(false);
  const node = useRef(null);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleHelpClick = () => {
    setHelpVisible(!helpVisible);
    window.Intercom("hide");
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (openSubmenu && node.current && !node.current.contains(e.target)) {
        setOpenSubmenu(false);
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [openSubmenu]);

  const handleOpenSubmenu = () => {
    setOpenSubmenu(!openSubmenu);
    const getTourAttribute = document.querySelectorAll("*[data-intro]").length;
    setHideQuickGuide(getTourAttribute);
  };

  const toggleProductOverlay = () => {
    let chardin = new Chardin(document.querySelector('body'));
    chardin.start();
    setShowDismiss(!showDismiss);
  };

  useEffect(() => {
    if (window.location.pathname === "/app/justask") {
      setActiveButtonType("justask");
    }
    if (window.location.pathname === "/app/list") {
      setActiveButtonType("search");
    }
    if (window.location.pathname === "/app/savedsearch") {
      setActiveButtonType("savedsearch");
    }
    if (window.location.pathname === "/app/leaguetable") {
      setActiveButtonType("leaguetable");
    }
    if (window.location.pathname === "/app/myleads#updates") {
      setActiveButtonType("myleads");
    }
    if (window.location.pathname === "/app/export/list") {
      setActiveButtonType("export");
    }
    if (window.location.pathname === "/app/teams") {
      setActiveButtonType("teams");
    }
    if (window.location.pathname === "/app/calendar") {
      setActiveButtonType("calendar");
    }
    if (window.location.pathname === "/app/useradmin") {
      setActiveButtonType("useradmin");
    }
  }, [window.location]);

  const handleCustomNavigate = (event, type) => {
    event.preventDefault();

    if (type === "justask") {
      navigate("/app/justask");
    }

    if (type === "search") {
      dispatch(updateShowSelectedCompanies(false));
      dispatch(updateShowSelectedProjects(false));
      dispatch(updateIsShowProjectsOrCompanySelected(false))
      dispatch(updateClusterList({ leadsFlags: [] }));
      dispatch(updateBackButtonCluster({ back: false }));
      dispatch(updateMyLeadsShowHierarchy({ showProjectHierarchy: false }));
      dispatch(rolesForProject([]))
      navigate("/app/list");
    }
    if (type === "savedsearch") {
      dispatch(updateSavedSearchOffsetLimitValue({ offset: 0, records: 21 }));
      dispatch(updateIsSharedSaveSearchActive(true))
      navigate("/app/savedsearch");
    }
    if (type === "leaguetable") {
      dispatch(updateSearchlist([]));
      navigate("/app/leaguetable");
    }
    if (type === "myleads") {
      dispatch(updateClusterList({ leadsFlags: [] }));
      dispatch(updateClusterListJA({ leadsFlags: [] }));
      dispatch(updateBackButtonCluster({ back: false }));
      dispatch(
        updateShowHierarchy({
          showProjectHierarchy: false,
          showCompanyHierarchy: false,
        })
      );
      dispatch(updateShowSelectedProjectsJA(false))
      dispatch(updateShowSelectedCompaniesJA(false))
      dispatch(updateShowHierarchyForJustAsk({
        showProjectHierarchy: false,
        showCompanyHierarchy: false,
      }))
      dispatch(rolesForProject([]))
      navigate("/app/myleads#updates");
    }
    if (type === 'export') {
      navigate('/app/export/list')
      dispatch(updateCurrentPage(0))
    }
    if (type === "teams") {
      navigate("/app/teams#teams");
    }
    if (type === "calendar") {
      navigate("/app/calendar");
    }
    if (type === "useradmin") {
      dispatch(updateAdminUserActivitiesOffsetLimitValue({ offset: 0, records: 50 }));
      navigate("/app/useradmin#users");
    }
  };

  const handleIntercomClick = () => {
    window.Intercom('show')
  }

  return (
    <>
      <div className="side-navigation" data-intro={location.pathname == "/app/savedsearch" ? TOURSAVEDSEARCH.toolbar.intro : null} data-position={TOURSAVEDSEARCH.toolbar.position} data-class={TOURSAVEDSEARCH.toolbar.class}>
        <div className="side-nav-button">
          {!user.isRedirectedUser && userData?.licenceFlag?.isInsideSalesLicence === true ? (
            <button
              className={`${activeButtonType === "search"
                ? "search-nav nav-active"
                : "search-nav"
                }`}
              data-tooltip-id="Search"
              data-tooltip-hidden={!settings.preferences.hint_text}
              onClick={(e) => handleCustomNavigate(e, "search")}
            >
              <Icon
                color="#ffffff"
                size={30}
                icon="icon-sb--search"
                className="me-1"
              />
              Search
            </button>
          ) : (
            ""
          )}
          {!user.isRedirectedUser && userData?.licenceFlag?.isJustAskLicence === true ? (
            <button
              className={`${activeButtonType === "justask"
                ? "justask-nav nav-active"
                : "justask-nav"
                }`}
              data-tooltip-id="justask"
              data-tooltip-hidden={!settings.preferences.hint_text}
              onClick={(e) => handleCustomNavigate(e, "justask")}
            >
              <Icon
                color="#ffffff"
                size={30}
                icon="icon-sb--search"
                className="me-1"
              />
              Just Ask
            </button>
          ) : (
            ""
          )}
          
          {!user.isRedirectedUser && userData?.licenceFlag?.isInsideSalesLicence === true ? (
            <button
              className={`${activeButtonType === "savedsearch"
                ? "saved-search-nav nav-active"
                : "saved-search-nav"
                }`}
              data-tooltip-id="SavedSearch"
              data-tooltip-hidden={!settings.preferences.hint_text}
              onClick={(e) => handleCustomNavigate(e, "savedsearch")}
            >
              <Icon
                color="#ffffff"
                size={30}
                icon="icon-sb--saved-search"
                className="me-1"
              />
              Saved search
            </button>
          ) : (
            ""
          )}
          {!user.isRedirectedUser && userData?.licenceFlag?.isInsideSalesLicence === true ? (
            <button
              className={`${activeButtonType === "leaguetable"
                ? "leagueTable-nav nav-active"
                : "leagueTable-nav"
                }`}
              data-tooltip-id="league"
              data-tooltip-hidden={!settings.preferences.hint_text}
              onClick={(e) => handleCustomNavigate(e, "leaguetable")}
            >
              <Icon
                color="#ffffff"
                size={30}
                icon="icon-sb-leaguetable"
                className="me-1"
              />
              League table
            </button>
          ) : (
            ""
          )}
          {!user.isRedirectedUser && userData?.licenceFlag?.isInsideSalesLicence === true || userData?.licenceFlag?.onlyHasJustAskLicense ? (
            <button
              className={`${activeButtonType === "myleads"
                ? "mylead-nav nav-active"
                : "mylead-nav"
                }`}
              data-tooltip-id="MyLeads"
              data-tooltip-hidden={!settings.preferences.hint_text}
              onClick={(e) => handleCustomNavigate(e, "myleads")}
            >
              <Icon
                color="#ffffff"
                size={30}
                icon="icon-sb-myleads"
                className="me-1"
              />
              My leads
            </button>
          ) : (
            ""
          )}
          {!user.isRedirectedUser && userData?.licenceFlag?.isCalendarLicence === true ? (
            <button
              className={`${activeButtonType === "calendar"
                ? "calendar-nav nav-active"
                : "calendar-nav"
                }`}
              data-tooltip-id="Calendar"
              data-tooltip-hidden={!settings.preferences.hint_text}
              onClick={(e) => handleCustomNavigate(e, "calendar")}
            >
              <Icon
                color="#ffffff"
                size={30}
                icon="icon-sb-events"
                className="me-1"
              />
              Calendar
            </button>
          ) : (
            ""
          )}
          {!user.isRedirectedUser && userData?.licenceFlag?.isExportLicence === true ? (
            <button
              className={`${activeButtonType === "export"
                ? "export-nav nav-active"
                : "export-nav"
                }`}
              data-tooltip-id="Export"
              data-tooltip-hidden={!settings.preferences.hint_text}
              onClick={(e) => handleCustomNavigate(e, "export")}
            >
              <Icon
                color="#ffffff"
                size={30}
                icon="icon-sb-export"
                className="me-1"
              />
              Export
            </button>
          ) : (
            ""
          )}
          {!user.isRedirectedUser && userData?.licenceFlag?.isInsideSalesLicence === true || userData?.licenceFlag?.onlyHasJustAskLicense ? (
            <button
              className={`${activeButtonType === "teams"
                ? "teams-nav nav-active"
                : "teams-nav"
                }`}
              data-tooltip-id="Teams"
              data-tooltip-hidden={!settings.preferences.hint_text}
              onClick={(e) => handleCustomNavigate(e, "teams")}
            >
              <Icon
                color="#ffffff"
                size={30}
                icon="icon-sb-teams"
                className="me-1"
              />
              Teams
            </button>
          ) : (
            ""
          )}
          {userData?.licenceFlag?.isAdminLicence === true ? (
            <button
              className={`${activeButtonType === "useradmin"
                ? "userAdmin-nav nav-active"
                : "userAdmin-nav"
                }`}
              data-tooltip-id="UserAdmin"
              data-tooltip-hidden={!settings.preferences.hint_text}
              onClick={(e) => handleCustomNavigate(e, "useradmin")}
            >
              <Icon
                color="#ffffff"
                size={30}
                icon="icon-sb-admin"
                className="me-1"
              />
              User admin
            </button>
          ) : ("")}

          <div className="side-bottom-navigation">
            <div className="sidebar_divider"></div>
            {!user.isRedirectedUser ? (
              <button
                className="intercom_nav"
                data-tooltip-id="Chatwithus"
                data-tooltip-hidden={!settings.preferences.hint_text}
                onClick={handleIntercomClick}
              >
                <Icon
                  color="#ffffff"
                  size={30}
                  icon="icon-sb-chat"
                  className="me-1"
                />
                Chat with us
              </button>
            ) : (
              ""
            )}
            {!user.isRedirectedUser ? (
              <button
                className="help-nav"
                data-tooltip-id="Help"
                data-tooltip-hidden={!settings.preferences.hint_text}
                onClick={handleOpenSubmenu}
                ref={node}
              >
                <Icon
                  color="#ffffff"
                  size={30}
                  icon="icon-sb-help"
                  className="me-1"
                />
                Help
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {openSubmenu && (
        <div className="sub-menu">
          <h5>Help</h5>
          <hr className="m-0" />
          <a
            className="help-nav"
            href="https://vimeo.com/showcase/10592285"
            target="_blank"
          >
            Overview Videos
          </a>
          {hideQuickGuide != 0 ? (
            <span className="cursor-pointer">
              <a onClick={toggleProductOverlay}>Quick guide</a>
            </span>
          ) : (
            ""
          )}
          <a
            className="help-nav"
            href="http://help.barbour-abi.com/barbour-abi"
            target="_blank"
          >
            Help centre
          </a>
        </div>
      )}

      <ReactTooltip id="Export" place="right" type="dark" effect="solid">
        <p className="tool-tip-sidebar">Export data</p>
      </ReactTooltip>
      <ReactTooltip id="Teams" place="right" type="dark" effect="solid">
        <p className="tool-tip-sidebar">Teams</p>
      </ReactTooltip>
      <ReactTooltip id="UserAdmin" place="right" type="dark" effect="solid">
        <p className="tool-tip-sidebar">User admin</p>
      </ReactTooltip>
      <ReactTooltip id="league" place="right" type="dark" effect="solid">
        <p className="tool-tip-sidebar">League table</p>
      </ReactTooltip>
      <ReactTooltip id="MyLeads" place="right" type="dark" effect="solid">
        <p className="tool-tip-sidebar">My leads</p>
      </ReactTooltip>
      <ReactTooltip id="SavedSearch" place="right" type="dark" effect="solid">
        <p className="tool-tip-sidebar">Saved search</p>
      </ReactTooltip>
      <ReactTooltip id="Settings" place="right" type="dark" effect="solid">
        <p className="tool-tip-sidebar">Settings</p>
      </ReactTooltip>
      <ReactTooltip id="Search" place="right" type="dark" effect="solid">
        <p className="tool-tip-sidebar">Search</p>
      </ReactTooltip>
      <ReactTooltip id="Help" place="right" type="dark" effect="solid">
        <p className="tool-tip-sidebar">Help</p>
      </ReactTooltip>
      <ReactTooltip id="Chatwithus" place="right" type="dark" effect="solid">
        <p className="tool-tip-sidebar">Chat with us</p>
      </ReactTooltip>
      <ReactTooltip id="Calendar" place="right" type="dark" effect="solid">
        <p className="tool-tip-sidebar">Calendar</p>
      </ReactTooltip>
      <ReactTooltip id="justask" place="right" type="dark" effect="solid">
        <p className="tool-tip-sidebar">Just Ask</p>
      </ReactTooltip>

      {helpVisible && (
        <div className="help-wrapper">
          {/* <Resizable className="help-content"
              defaultSize={{
                width:350,
                height:600
              }}
              handleComponent={{
                topRight: BottomRightHandle,
              }}
            > */}
          <h5 className="m-0 p-2">Help</h5>
          <a className="close-help" onClick={handleHelpClick}>
            Close
          </a>
          <div className="iframe-container">
            <iframe
              src="http://help.barbour-abi.com/barbour-abi"
              frameBorder="0"
            ></iframe>
          </div>
          {/* </Resizable> */}
          <div className="overlay" onClick={handleHelpClick}></div>
        </div>
      )}
      {/* {showDismiss && (
          <button onClick={hideTour} className="dismiss-tour btn btn-primary">Dismiss</button>
        )} */}
    </>
  );
};

export default LeftPanel;
